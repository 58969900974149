import axios from 'axios';
import {BASE_API_URL} from '../service';

const axios_instance = axios.create({
    headers: {
        // 'Content-Type': 'application/json;',
        'Content-Type': 'text/plain;charset=utf-8',
        // 'Access-Control-Allow-Origin': '*'
    },
    withCredentials: false
});

const axios_instance_get = axios.create({
    headers: {
        'Content-Type': 'application/xml',
        // 'Access-Control-Allow-Origin': '*'
    },
    withCredentials: false
});
class MessageService {

    getAllMessageList = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/message/getmessagelist`, data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    newMessage = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/message/newmessage`, data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    removeMessage = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/message/removemessage`, data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    FileUpload = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/message/fileupload`, data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };




}

const instance = new MessageService();
export default instance;
