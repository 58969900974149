import React, {Component} from 'react';
import {
    Select,
    Grid,
    Checkbox,
    FormControlLabel,
    TextField,
    Button,  CircularProgress,

} from '@material-ui/core';

import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import {withRouter} from 'react-router-dom';
// for store
import connect from "react-redux/es/connect/connect";
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Draggable from 'react-draggable';
import * as Actions from '../../../../store/actions';
import Images from '../../../Images';
import Colors from '../../../Colors';
import Slider from "react-slick";
import Menu from '@material-ui/core/Menu';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import NotificationsIcon from '@material-ui/icons/Notifications';
import axios from "axios/index";
import LockIcon from '@material-ui/icons/Lock';
import Badge from '@material-ui/core/Badge';
import * as Service from '../../../../store/service';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PDFReader } from 'reactjs-pdf-reader';

const axios_instance = axios.create({
    headers: {'Content-Type': 'multipart/form-data'}});

const styles = theme => ({
    title: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    acountMenu:{
        marginTop: 30
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: '#0000006b',
        zIndex: 1000,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    progress: {
        margin: 1,
    },
    getadpaperworkbtn:{
        textDecoration:"none",
        border: 'solid 1px #7a8ce2',
        padding: 8,
        marginRight:30,
    },
    headeritem:{
        marginLeft: 100,
        marginTop: 25,
    },
    submitbuttons:{
        marginTop: 25,
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
    },
    messagesendbtn:{
        height: 30
    },
    fileinput:{
        display:"none"
    },
    filenamespan:{
        fontSize: 12,
        fontWeight: 700
    },
    pdfviewcontainer:{
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
    },
    pdfviewiframe:{
        width:"80%",
        height:"80vh"
    },
    topheader:{
        display:"flex",
        flexDirection:"column",
        width:350,
        marginLeft:100,
        marginTop: 10,
    },
    topHeaderTxt:{
        fontSize: 18,
        fontWeight: 900,

    },
});


class Christmore extends Component {
    constructor(props){
        super(props);
        this.state={
            selectedFile:null,
            filename:"",
            papertype:"Christmore",
        }
        this.inputOpenFileRef = React.createRef();
    }

    componentDidMount(){
        this.getPaperWorkItem();
    }
    getPaperWorkItem=()=>{
        const {loginData} = this.props;
        if(loginData !== null ){
            var userid = loginData.id;
            var papertype= this.state.papertype;
            var data ={userid, papertype};
            if(userid && userid !=="" && userid !== null){
                this.props.GetPaperWork(data);
            }
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevProps.paperWorkStatus !== this.props.paperWorkStatus && this.props.paperWorkStatus >= 200){
            this.showmessage(this.props.paperWorkStatus,this.props.auth.message);
        }
    }
    componentWilUnmount() {

    }
    sendMessageBtn=async()=>{
        let formData = new FormData();
        if(this.state.selectedFile && this.state.selectedFile !== null ){
            formData.append('file',this.state.selectedFile);
            await axios_instance.post(Service.BASE_API_URL+'/message/fileupload', formData)
                .then(response=>{
                    if(response.status===200){
                        var data = response.data;
                        var link = data.data;
                        var IsSuccess = data.IsSuccess;
                        if(IsSuccess && link && link !== null){
                            this.submitAdpaperwork(link);
                        }
                    }
                })
        }
    }
    submitAdpaperwork=(link)=>{
        if(link && link !=="" && link !== null){
            const {loginData} = this.props;
            console.log(loginData);
            var userid  = loginData && loginData !== null && loginData.id ? loginData.id :"";
            var papertype = "Christmore";
            var data ={
                userid: userid,
                data: link,
                papertype: papertype
            };
            if(userid && userid !=="" && userid !== null){
                this.setState({selectedFile:null,filename :""});
                this.props.SubmitPaperWork(data);
                //console.log(data);
            }
        }
    }
    showmessage=(status,msg)=>{
        if(msg ==="") return;
        if(status >200){

            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else if(status ===200){
            toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    onFileChange=(event)=>{
        this.setState({ selectedFile: event.target.files[0] });
        var filename = event.target.files[0].name;
        this.setState({filename:filename});
    }
    selectFile=()=>{
        this.inputOpenFileRef.current.click()
    }
    onDocumentLoadSuccess=(number)=>{

    }
    viewMessage=()=>{
        this.props.viewMessageBox();
    }
    render(){
        const {classes} = this.props;
        const { filename } = this.state;

        return(
            <div >
                { this.props.getPaperWorkStatus === 100 && (
                    <div className={classNames(classes.overlay, 'flex items-center w-full')}>
                        <CircularProgress className={classes.progress} color="secondary"  />
                    </div>
                )}
                <ToastContainer/>
                <div className={classes.topheader}>
                    <span className={classes.topHeaderTxt}>Christmore Darrin Paperwork </span>
                    <div style={{display:"flex",marginTop:15,marginLeft:0}}>
                        <span style={{color:"red",fontWeight:600}}>{this.props.getPaperWork !== null && this.props.getPaperWork && this.props.getPaperWork.readstatus ==="100"?"Under Review":""}</span>
                        <span style={{color:"red",fontWeight:600}}>{this.props.getPaperWork !== null && this.props.getPaperWork && this.props.getPaperWork.readstatus ==="200" && (this.props.getPaperWork.active ==="no" || this.props.getPaperWork.active ==="")?"Please Check and Submit again":""}</span>
                        <span style={{color:"green",fontWeight: 800}}>{this.props.getPaperWork && this.props.getPaperWork.active ==="yes" ? "Active" :""}</span>
                        <span style={{color:"green",fontWeight: 800}}>{this.props.getPaperWork && this.props.getPaperWork.active ==="yes" ? <LockIcon/>:""}</span>
                    </div>
                    <div>
                        <Button variant="contained" color="primary" onClick={this.viewMessage} disabled={this.props.getPaperWork && this.props.getPaperWork !== null && this.props.getPaperWork.id && this.props.getPaperWork.id !==null ? false : true}>
                            Message
                        </Button>
                    </div>
                </div>

                <div className={classes.headeritem}>
                    <a className={classes.getadpaperworkbtn} href={Service.BASE_API_URL.replace("index.php","") + "/uploads/paperwork/Christmore,%20Darrin.pdf"} target="_blank">Get Christmore Darrin</a>
                </div>
                {this.props.getPaperWork && this.props.getPaperWork !== null && this.props.getPaperWork.userid && this.props.getPaperWork.userid !=="" && this.props.getPaperWork.userid === this.props.loginData.id &&
                this.props.getPaperWork.papertype && this.props.getPaperWork.papertype !==null && this.props.getPaperWork.papertype === "AD Paperwork"
                    ?(
                        <div className={classes.pdfviewcontainer}>
                            <iframe src={this.props.getPaperWork.data} frameBorder="0" className={classes.pdfviewiframe}></iframe>
                        </div>
                    ):(<div/>)}


                <div className={classes.submitbuttons}>
                    <span className={classes.filenamespan}>{filename}</span>
                    <input
                        accept=".pdf"
                        className={classes.fileinput}
                        id="contained-button-file"
                        name={"file"}
                        type="file"
                        ref={this.inputOpenFileRef}
                        onChange={this.onFileChange}
                    />
                    <IconButton onClick={this.selectFile}><CloudUploadIcon/></IconButton>
                    <Button variant="contained" color="primary" className={classes.messagesendbtn} onClick={this.sendMessageBtn}>
                        Submit
                    </Button>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        SubmitPaperWork:Actions.SubmitPaperWork,
        GetPaperWork:Actions.GetPaperWork,
    }, dispatch);
}

function mapStateToProps({auth,}) {
    return {
        auth: auth,
        loginData: auth.loginData,
        userData: auth.userData,
        loading: auth.loading,
        getPaperWork: auth.getPaperWork,
        paperWorkStatus: auth.paperWorkStatus,
        getPaperWorkStatus: auth.getPaperWorkStatus,

    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(Christmore));