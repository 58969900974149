import React from 'react';
import ReactDOM from 'react-dom';

//
import {Provider} from 'react-redux';
import {Router ,Route} from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import {persistor, store} from "./store";
import JssProvider from 'react-jss/lib/JssProvider';
import {create} from 'jss';
import {createGenerateClassName, jssPreset} from '@material-ui/core/styles';
import jssExtend from 'jss-extend';
import history from './history';
import './index.css';
import './fonts/impactreg.woff2';
import './fonts/Oxanium-Bold.woff';
import './fonts/Oxanium-Regular.woff';
import './fonts/teko-bold-webfont.woff2';
import './fonts/teko-medium-webfont.woff2';
import App from './App';

import Dashboard from './Components/Screens/Dashboard';
import EmployeeDashboard from './Components/Screens/Dashboard/EmployeeDashboard';
import CheckLogin from './Components/Screens/Auth/CheckLogin';
import SignUpScreen from './Components/Screens/Auth/SignUpScreen';
import ClientDashboard from './Components/Screens/Dashboard/ClientDashboard';
import AdminDashboard from './Components/Screens/Dashboard/Admin/AdminDashboard';

import * as serviceWorker from './serviceWorker';


const jss = create({
    ...jssPreset(),
    plugins: [...jssPreset().plugins, jssExtend()]
});

jss.options.insertionPoint = document.getElementById('jss-insertion-point');
const generateClassName = createGenerateClassName();


ReactDOM.render(
    <JssProvider jss={jss} generateClassName={generateClassName}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router  history={history} basename={'/'}>
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/" component={CheckLogin} />
                    <Route path="/employee" component={EmployeeDashboard} />
                    <Route path="/client" component={ClientDashboard} />
                    <Route path="/admin" component={AdminDashboard} />
                    <Route path="/signup" component={SignUpScreen} />

                </Router>
            </PersistGate>
        </Provider>
    </JssProvider>
        , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
