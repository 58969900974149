import React, {Component} from 'react';
import {
    Select,
    Grid,
    Checkbox,
    FormControlLabel,
    TextField,
    Button,  CircularProgress,

} from '@material-ui/core';

import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import {withRouter} from 'react-router-dom';
// for store
import connect from "react-redux/es/connect/connect";
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Draggable from 'react-draggable';
import * as Actions from '../../../../store/actions';
import Images from '../../../Images';
import Colors from '../../../Colors';
import Slider from "react-slick";
import Menu from '@material-ui/core/Menu';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import NotificationsIcon from '@material-ui/icons/Notifications';
import axios from "axios/index";
import LockIcon from '@material-ui/icons/Lock';
import Badge from '@material-ui/core/Badge';
import * as Service from '../../../../store/service';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PDFReader } from 'reactjs-pdf-reader';
import ReactTable from "react-table";
import "react-table/react-table.css";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';


const axios_instance = axios.create({
    headers: {'Content-Type': 'multipart/form-data'}});
const hexToRgb = (hex) =>{
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};
const styles = theme => ({
    tableTheadRow:{
        // backgroundColor: 'rgba(' + hexToRgb(theme.palette.primary.main).r + ',' + hexToRgb(theme.palette.primary.main).g + ',' + hexToRgb(theme.palette.primary.main).b +', .2)'
        backgroundColor: theme.palette.primary.main
    },
    tableThEven:{
        backgroundColor: 'rgba(' + hexToRgb(theme.palette.secondary.main).r + ',' + hexToRgb(theme.palette.secondary.main).g + ',' + hexToRgb(theme.palette.secondary.main).b +', .3)'
    },
    tableTdEven:{
        // backgroundColor: 'rgba(' + hexToRgb(theme.palette.secondary.main).r + ',' + hexToRgb(theme.palette.secondary.main).g + ',' + hexToRgb(theme.palette.secondary.main).b +', .1)'
    },
    DialogTitle:{
        backgroundColor: '#3f51b5',
        color: 'white',
        fontWeight: 800,
    },
    closeBtn :{
        position: 'absolute',
        top: 9,
        right: 36,
    },
    pdfviewiframe:{
        width:"80%",
        height:"60vh"
    },
    pdfviewcontainer:{
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
    },
    questiondiv :{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginTop: 15,
        border: 'solid 1px gray',
        padding: 5,
        alignItems:"center",
    },
    questionspan:{
        fontSize: 24,
        fontWeight: 600,
        fontFamily: 'initial',
    },
    formTypeSelect:{
        width:"40%",
        marginLeft: 15
    },
});


class CraigGants extends Component {
    constructor(props){
        super(props);
        this.state={
            openDialog : false,
            formData : null,
            pdfBytesURL: null,
        }
        this.inputOpenFileRef = React.createRef();
    }

    componentDidMount(){
        this.GetAllCraiGatForms();
    }
    GetAllCraiGatForms=()=>{
        const { loginData } = this.props;
        let id = loginData && loginData.id ? loginData.id :"";
        let data = {
            type:"Employer",
            id : id,
        };
        if(id && id !== "" ){
            this.props.GetAllCraiGantFormList(data);
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevProps.updateFormDetailStatus === 100 && this.props.updateFormDetailStatus >= 200 ){
            this.GetAllCraiGatForms();
        }//getcraiGantFormListStatus
        if(prevProps.getcraiGantFormListStatus === 100 && this.props.getcraiGantFormListStatus >= 200 ){
            if(this.state.formData !== null){
                this.upDateSelectedFormData();
            }
        }
    }
    upDateSelectedFormData=()=>{
        const {craiGantFormList} = this.props;
        const {formData} = this.state;
        if(formData && formData !== null){
            let formid = formData.id;
            let getFormData = _.filter(craiGantFormList,item=>item.id === formid);
            if(getFormData && getFormData.length > 0){
                this.setState({formData: getFormData[0]});
                this.makepdfile(getFormData[0]);
            }
        }
    }
    componentWilUnmount() {

    }
    updatePDFfile=async(file)=>{
        const bytes = await this.readFileToArrayBuffer(file);
        const pdfDoc = await PDFDocument.load(bytes);
        const form = pdfDoc.getForm();
        const fields = form.getFields();

        fields.forEach(field => {
            const type = field.constructor.name;
            const name = field.getName();
            if(name && name !== null && name !==""){
                let midvalue = form.getTextField(name);
                if(midvalue && midvalue !== null ){
                    midvalue.setText("This is setted value");
                    // console.log("Changed")
                }
                let mymidvalue = midvalue.getText();
                // console.log(mymidvalue);
            }
            // console.log(field);
            // console.log(`${type}: ${name}`);

        });
        const pdfBytes = await pdfDoc.save();
        var pdffile = new Blob([pdfBytes], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(pdffile);
        this.setState({pdfBytesURL: fileURL});
    }


    ab2str=(buf)=>{
        return String.fromCharCode.apply(null, new Uint8Array(buf));
    }
    str2ab=(str)=>{
        var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
        var bufView = new Uint8Array(buf);
        for (var i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return buf;
    }
    readFileToArrayBuffer = async (fileData) => {
        return new Promise( (resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(fileData);
            reader.onload = () => {
                const result = reader.result;
                const bytes = new Uint8Array(result);
                resolve(bytes);
            };
        });
    }

    showmessage=(status,msg)=>{
        if(msg ==="") return;
        if(status >200){

            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else if(status ===200){
            toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    handleDialogClose = ()=>{
        this.setState({openDialog : false});
        this.setState({formData : null});
        this.setState({pdfBytesURL : ""});
    }
    SubmitForm=async(e,row)=>{
        const {loginData,userData} = this.props;
        this.setState({openDialog : true});
        this.setState({formData : row});
        //console.log(row);
        let meta = row.meta;
        let detail = row.detail;
        let formid = row.id;
        let userid = loginData.id;
        if(meta && meta.length > 0){
            for(let i = 0; i < meta.length;i ++){
                let fieldid = meta[i].id;
                let fieldname = meta[i].name;
                let midproperty="";
                if(fieldname && fieldname !== null && fieldname !==""){
                    midproperty = userData[fieldname];
                }
                let mvalue = "";
                let mfield = _.filter(detail,item=>item.fieldid ===fieldid && item.formid === formid && item.userid === userid);
                if(mfield && mfield.length > 0){
                    mvalue =mfield[0].value;
                    this.setState({["metavalue_"+fieldid]:mvalue});
                }
                else {
                    this.setState({["metavalue_"+fieldid]:midproperty});
                }

            }
        }


        if(row && row.pdfdoc && row.pdfdoc !== null){
            this.makepdfile(row);
        }
    }
    makepdfile=async(row)=>{
        let bytes = row.pdfdoc;
        let detail = row.detail;
        if(bytes && bytes !=="" && bytes !== null){
            let mybytes = bytes.split(",");
            let resbytes = new Uint8Array(mybytes);
            //console.log(resbytes);
            const pdfDoc = await PDFDocument.load(resbytes);
            const form = pdfDoc.getForm();
            const fields = form.getFields();
            fields.forEach(field => {
                const type = field.constructor.name;
                const name = field.getName();
                if(name && name !== null && name !==""){
                    let midvalue = form.getTextField(name);
                    if(midvalue && midvalue !== null ){
                        let midmetaData = _.filter(detail,item=>item.fieldname===name);
                        let my_val ="";
                        if(midmetaData && midmetaData.length > 0){
                            my_val = midmetaData[0].value;
                        }
                        midvalue.setText(my_val);
                    }
                }
            });

            const pdfBytes = await pdfDoc.save();
            var pdffile = new Blob([pdfBytes], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(pdffile);
            //console.log(fileURL+"===");
            this.setState({pdfBytesURL:fileURL});
        }
    }
    handleChangeFormType=(e,name)=>{
        if(name !==""){
            this.setState({[name]:e.target.value});
        }
    }
    handleSave=()=>{
        const{formData} = this.state;
        const {loginData} = this.props;
        if(loginData === null || !loginData){
            return ;
        }
        let meta = formData.meta;
        let resval = "";
        let userid = loginData.id;
        if(meta && meta.length > 0){
            for(let i =0; i < meta.length; i ++){
                let metaid = meta[i].id;
                let fieldname = meta[i].fieldname;
                let formid = meta[i].formid;
                let value = this.state["metavalue_"+metaid];
                resval+=userid+"###"+metaid+"###"+fieldname+"###"+formid+"###"+value+"@@@";
            }
        }
        this.props.UpdateFormDetailInfos({data:resval});

    }
    render(){
        const {classes ,craiGantFormList} = this.props;
        const { filename ,openDialog,formData,pdfBytesURL} = this.state;
        return(
            <div >
                { this.props.getPaperWorkStatus === 100 && (
                    <div className={classNames(classes.overlay, 'flex items-center w-full')}>
                        <CircularProgress className={classes.progress} color="secondary"  />
                    </div>
                )}
                <ToastContainer/>
                <ReactTable
                    data ={craiGantFormList && craiGantFormList.length > 0 ?craiGantFormList :[]}
                    minRows = {0}
                    getTheadGroupProps={(state, rowInfo, column, instance) => {
                        return {
                            style: {
                                // padding: "10px 10px",
                                fontSize: 16,
                                fontWeight: 700
                            },
                        }
                    }}
                    getTheadGroupThProps={(state, rowInfo, column, instance) => {
                        return {
                            style: {
                                // padding: "10px 10px",
                                fontSize: 18,
                                fontWeight: 700,
                            },
                            className: classNames("flex items-center justify-start")
                        }
                    }}
                    getTheadThProps={(state, rowInfo, column, instance) => {
                        let border = '1px solid rgba(255,255,255,.6)';
                        if (column.Header === 'Actions') border = 'none';
                        return {
                            style: {
                                fontSize: '18px',
                                height: 50,
                                // fontFamily: 'Muli,Roboto,"Helvetica",Arial,sans-serif',
                                fontWeight: 400,
                                lineHeight: 1.75,
                                color: 'white',
                                borderRight: border
                            },
                        }
                    }}
                    getTheadProps={(state, rowInfo, column, instance) => {
                        return {
                            style: {
                                fontSize: 13,
                            },
                            className: classes.tableTheadRow
                        }
                    }}
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            style: {
                                textAlign: 'center',
                                flexDirection: 'row',
                                fontSize: 15,
                                padding: "0",
                                height: 50,
                                paddingTop: 12,
                            },
                        }
                    }}

                    columns={[
                        {
                            columns: [
                                {
                                    Header: "No",
                                    accessor: "name",
                                    filterAll: true,
                                    width: '5%',
                                    className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                    Cell: row =>{
                                        return(<div>{row.index +1}</div>)
                                    }
                                },

                                {
                                    Header: "Form Name",
                                    accessor: "name",
                                    filterAll: true,
                                    width: '10%',
                                    className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                },
                                {
                                    Header: "View Template Form",
                                    accessor: "url",
                                    filterAll: true,
                                    width: '10%',
                                    className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                    Cell: row=>{
                                        return (
                                            <div>
                                                <a href={row.original.url} target="_blank">View</a>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    Header: "My Form",
                                    accessor: "created",
                                    width: '10%',
                                    className: classNames("flex items-center  justify-start p-12-impor"),
                                    Cell: row =>{
                                        return(<div><Button variant="contained" onClick ={(e)=>this.SubmitForm(e,row.original)}>My Form</Button></div>)
                                    },
                                },

                                {
                                    Header: "Status",
                                    accessor: "created",
                                    width: '10%',
                                    className: classNames("flex items-center  justify-start p-12-impor"),
                                    Cell: row =>{
                                        return(<div>{row.original.formstatus && row.original.formstatus !== null && row.original.formstatus.status ==="100" ?
                                            (<span style={{color:"#ea6e6e",fontWeight:700}}>Waiting</span>):row.original.formstatus && row.original.formstatus !== null && row.original.formstatus.status ==="200"?
                                                (<span style={{color:"green",fontWeight:700}}>Complated</span>) :row.original.formstatus && row.original.formstatus !== null && row.original.formstatus.status ==="400"?
                                                    (<span style={{color:"#dfd90b",fontWeight:700}}>Declined</span>) :(<span style={{color:"#ea6e6e",fontWeight:700}}>Need To Do</span>)
                                        }</div>)
                                    },
                                },

                            ]
                        },
                    ]}
                    defaultPageSize={10}
                    className={classNames( "-striped -highlight")}
                    totalRecords = {craiGantFormList && craiGantFormList !== null ? craiGantFormList.length:0}
                    style={{
                        height: '100%',
                    }}
                />
                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={openDialog}
                    fullScreen={false}
                    onClose={this.handleDialogClose}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title" className={classes.DialogTitle}>
                        My Form <span style={{color: '#1bff4c', fontWeight: 900}}>({formData && formData !== null && formData.name ? formData.name :""})</span>
                        <IconButton edge="start" color="inherit" aria-label="close" className={classes.closeBtn} onClick ={this.handleDialogClose}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent >
                        {pdfBytesURL && pdfBytesURL !=="" ? (
                            <div className={classes.pdfviewcontainer}>
                                <iframe src={pdfBytesURL} frameBorder="0" className={classes.pdfviewiframe}></iframe>
                            </div>
                        ):(<div/>)}
                        <div>
                            {formData && formData.meta && formData.meta.length > 0 && formData.meta.map((item,index)=>(
                                <div key={index} className={classes.questiondiv}>
                                    <span className={classes.questionspan}>{item.question}</span>
                                    <TextField
                                        disabled={formData && formData !== null && formData.formstatus !== null && formData.formstatus.status && formData.formstatus.status ==="200" ? true: false}
                                        label=""
                                        value={this.state["metavalue_"+item.id]}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.formTypeSelect}
                                        onChange={(e)=>this.handleChangeFormType(e,"metavalue_"+item.id)}
                                    />
                                </div>
                            ))}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSave} disabled={formData && formData !== null && formData.formstatus !== null && formData.formstatus.status && formData.formstatus.status ==="200" ? true: false} variant="outlined" color="primary">
                            Save
                        </Button>
                        <Button onClick={this.handleDialogClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        SubmitPaperWork:Actions.SubmitPaperWork,
        GetPaperWork:Actions.GetPaperWork,
        GetAllCraiGantFormList:Actions.GetAllCraiGantFormList,
        UpdateFormDetailInfos:Actions.UpdateFormDetailInfos,
    }, dispatch);
}

function mapStateToProps({auth,}) {
    return {
        auth: auth,
        loginData: auth.loginData,
        userData: auth.userData,
        loading: auth.loading,
        getPaperWork: auth.getPaperWork,
        paperWorkStatus: auth.paperWorkStatus,
        getPaperWorkStatus: auth.getPaperWorkStatus,
        getcraiGantFormListStatus: auth.getcraiGantFormListStatus,
        craiGantFormList: auth.craiGantFormList,
        updateFormDetailStatus: auth.updateFormDetailStatus,

    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(CraigGants));