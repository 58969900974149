import axios from 'axios';
import {BASE_API_URL} from '../service';

const axios_instance = axios.create({
    headers: {
        // 'Content-Type': 'application/json;',
        'Content-Type': 'text/plain;charset=utf-8',
        // 'Access-Control-Allow-Origin': '*'
    },
    withCredentials: false
});

const axios_instance_get = axios.create({
    headers: {
        'Content-Type': 'application/xml',
        // 'Access-Control-Allow-Origin': '*'
    },
    withCredentials: false
});
class AdminService {

    getAllCurrentClient = () => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/user/getallcurrentclient`)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };

    getAllCurrentEmployee = () => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/user/getallcurrentemployee`)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    getPaperWorks = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/user/getadminemployee`, data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    getUncheckedPaperWorks = () => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/user/getalluncheckedpaperwork`)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    getAllUsersList = () => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/user/getalluserslist`)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    removeUser = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/user/removeuser`,data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    updateUserAccountRole = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/user/updateuserrole`,data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    adminaddnewformpdffile = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/file/adminaddnewpdfform`,data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    admingetallformlist = () => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/file/getallformlist`)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    adminremoveform = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/file/removeform`,data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    adminremoveformmetadataitem = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/file/removeformmetadata`,data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    adminupdateformmetadataitem = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/file/updateformmetadata`,data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    adminaddnewDataSetItem = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/file/addnewfield`,data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };

    adminUpdateformmetadatafieldsvalue = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/file/updateformmetadatafields`,data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };

    adminupdateItemsValName = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/file/updatedbwithvalname`,data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };

    admingetsubmittedformlist = () => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/file/admingetallsubmitedformslist`)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };







}

const instance = new AdminService();
export default instance;
