import React, {Component} from 'react';
import {
    Select,
    Grid,
    Checkbox,
    FormControlLabel,
    TextField,
    Button,  CircularProgress,

} from '@material-ui/core';

import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import {withRouter} from 'react-router-dom';
// for store
import connect from "react-redux/es/connect/connect";
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Draggable from 'react-draggable';
import * as Actions from '../../../../store/actions';
import Images from '../../../Images';
import Colors from '../../../Colors';
import Slider from "react-slick";
import Menu from '@material-ui/core/Menu';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';

import _ from 'lodash';
import ReactTable from "react-table";
import "react-table/react-table.css";

const hexToRgb = (hex) =>{
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};
const styles = theme => ({
    title: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    acountMenu:{
        marginTop: 30
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: '#0000006b',
        zIndex: 1000,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    progress: {
        margin: 1,
    },
    tableTheadRow:{
        // backgroundColor: 'rgba(' + hexToRgb(theme.palette.primary.main).r + ',' + hexToRgb(theme.palette.primary.main).g + ',' + hexToRgb(theme.palette.primary.main).b +', .2)'
        backgroundColor: theme.palette.primary.main
    },
    tableThEven:{
        backgroundColor: 'rgba(' + hexToRgb(theme.palette.secondary.main).r + ',' + hexToRgb(theme.palette.secondary.main).g + ',' + hexToRgb(theme.palette.secondary.main).b +', .3)'
    },
    tableTdEven:{
        // backgroundColor: 'rgba(' + hexToRgb(theme.palette.secondary.main).r + ',' + hexToRgb(theme.palette.secondary.main).g + ',' + hexToRgb(theme.palette.secondary.main).b +', .1)'
    },
    displaynone:{
        display:"none",
    },
    DialogTitle:{
        backgroundColor: '#3f51b5',
        color: 'white',
        fontWeight: 800,
    },
    closeBtn :{
        position: 'absolute',
        top: 9,
        right: 36,
    },
    mainmsgboard:{
        width:"100%",
        height:'calc(100vh - 192px)',
        backgroundColor:"black",
        display:"flex",
        flexDirection:"row",
    },
    mainmsguserList:{
        width:350,
        height:'calc(100vh - 192px)',
        backgroundColor:"blue",
        overflowY:"scroll",
    },
    mainusermsglist:{
        width: 'calc(100% - 350px)',
        height:'calc(100vh - 192px)',
        backgroundColor:"green"
    },
    msgboard:{
        width:"100%",
        display:"flex",
        flexDirection:"column"
    },
    userList:{
        // overflowY:"scroll",
    },
    userItems:{
        height:80,
        border:"solid 1px gray",
        backgroundColor:"yellow",

    },
});


class UserFiler extends Component {
    constructor(props){
        super(props);
        this.state={
            loginStatus : 100,
            adminLeavel : "",
        }
    }

    componentDidMount(){

    }
    CheckAdmin=()=>{
        const { loginData } = this.props;
        if(loginData !== null && loginData.admin !==""){
            this.setState({adminLeavel: parseInt(loginData.admin)});
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot){

    }
    componentWilUnmount() {

    }


    render(){
        const {classes,messageList} = this.props;
        const { adminLeavel } = this.state;

        return(
            <div className={classes.mainmsguserList}>
                <div className={classes.msgboard}>
                    {Array.from({length: 30}).map((_index)=>(
                        <div key={_index} className={classes.userItems}>

                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        GetAllUserMessage : Actions.GetAllUserMessage,
        AddNewMessage : Actions.AddNewMessage,
        RemoveUserMessage : Actions.RemoveUserMessage,
    }, dispatch);
}

function mapStateToProps({auth,}) {
    return {
        auth: auth,
        loginData: auth.loginData,
        userData: auth.userData,
        loading: auth.loading,

        //message part
        messageStatus: auth.messageStatus,
        messageList: auth.messageList,
        messageMsg: auth.messageMsg,
        messageItem: auth.messageItem,
        messageRemoveStatus: auth.messageRemoveStatus,
        messageAddStatus: auth.messageAddStatus,
        messageGetAllListStatus: auth.messageGetAllListStatus,
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(UserFiler));