import _AuthService from './auth.service';
import _AdminService from './admin.service';
import _MessageService from './message.service';

// export const BASE_API_URL = "http://localhost/portal_project/index.php";
export const BASE_API_URL = "http://api.trusthomecare.com/index.php";

//https://www.footstar.org/get_data_nviewer.asp?jogo_id=
// export const BASE_API_URL = "http://10.0.0.19:8080/cdr-api/index.php";
// export const BASE_API_URL = "http://localhost/real-estate-api/index.php";

export const AuthService    = _AuthService;
export const AdminService    = _AdminService;
export const MessageService    = _MessageService;
