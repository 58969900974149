import axios from 'axios';
import {BASE_API_URL} from '../service';

const axios_instance = axios.create({
    headers: {
        // 'Content-Type': 'application/json;',
        'Content-Type': 'text/plain;charset=utf-8',
        // 'Access-Control-Allow-Origin': '*'
    },
    withCredentials: false
});

const axios_instance_get = axios.create({
    headers: {
        'Content-Type': 'application/xml',
        // 'Access-Control-Allow-Origin': '*'
    },
    withCredentials: false
});
class AuthService {
    getlogindata = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/user/getlogindata`, data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    createNewUser = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/user/createuser`, data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    loginUser = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/user/loginuser`, data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    submitPaperwork = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/user/submitpaperwork`, data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    getPaperwork = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/user/getpaperwork`, data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    upDatePaperwork = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/user/updatepaperwork`, data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    RemovePaperwork = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/user/paperworkremove`, data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    UpdateEmployerDetail = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/user/updateemployerdetail`, data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    UpdateUserDetail = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/user/updateaccountdetail`, data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };
    updateClientDetail = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/user/updateclientdetail`,data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };

    getAllUserCraIGantForms = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/file/getalluserscraiforms`,data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };

    upDateFormDetails = (data) => {
        return new Promise((resolve, reject) => {
            axios_instance.post(`${BASE_API_URL}/file/updateformdetail`,data)
                .then(res => {
                    if (res.status === 200) {
                        resolve(res.data);
                    }
                    else if (res.status !== 200) {
                        reject(res.data);
                    }
                })
                .catch(error => {
                    resolve(error);
                })
        });
    };



}

const instance = new AuthService();
export default instance;
