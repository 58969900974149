import React, {Component} from 'react';
import {
    Select,
    Grid,
    Checkbox,
    FormControlLabel,
    TextField,
    Button,  CircularProgress,

} from '@material-ui/core';

import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import {withRouter} from 'react-router-dom';
// for store
import connect from "react-redux/es/connect/connect";
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Draggable from 'react-draggable';
import * as Actions from '../../../store/actions';
import Images from '../../Images';
import Colors from '../../Colors';
import Slider from "react-slick";
import HoverImage from "react-hover-image";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import MaskedInput from 'react-text-mask';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import _ from 'lodash';
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const utf8 = require('utf8');
const styles = theme => ({
    root:{
        backgroundColor:"#4a96e4",
        width: '100%',
        height:'100vh',
        display:"flex",
        alignItems:"center",
        justifyContent:"center"

    },
    signupContent:{
        width:350,
        height: 650,
        backgroundColor:"white",
        borderRadius:15,
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        // justifyContent:"center",
        // boxShadow: '0px 0px 15px #e9e5e5',
    },
    authformtitle:{
        fontSize: 35,
        fontFamily: 'cursive',
        marginTop: 30,
        fontWeight:900,
        userSelect: 'none',
    },
    inputformTypeSelect:{
        fontSize: 12,
        borderRadius: 20
    },
    menuitemformTypeSelect:{
        fontSize: 12,
    },
    formTypeSelect:{
        marginTop:25,
        minWidth: 300,
        maxWidth: 300
    },
    maskedInputPhone:{
        marginTop:25,
        minWidth: 279,
        maxWidth: 279,
        padding: 10.5,
        borderRadius: 3,
        border: '1px solid gray',
    },
    formBtn:{
        marginTop: 15,
        minWidth: 300,
        maxWidth: 300,
        backgroundColor:"#1b9af5",
        "&:hover": {
            backgroundColor:"#006fe0"
        }
    },
    formLogintxt:{
        marginTop: 10,
        fontSize: 13,
        fontWeight:900,
        userSelect: 'none',
    },
    logintxtBtn:{
        color:"#2d9eef",
        marginLeft: 10,
        fontWeight: 700,
        textDecoration: 'underline',
        cursor: 'pointer',
        "&:hover": {
            color:"#042ae6"
        },
        userSelect: 'none',
    },
    formforgottxt:{
        width:300,
        textAlign:"right",
    },
    formforgotbtn:{
        cursor:"pointer",
        fontSize: 12,
        color:"#2d9eef",
        "&:hover": {
            color:"#042ae6"
        },
        userSelect: 'none',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: '#0000006b',
        zIndex: 1000,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    progress: {
        margin: 1,
    },

});


class SignUpScreen extends Component {
    constructor(props){
        super(props);
        this.state={
            formType : 0,
            formTypeStr:["Please Select User Type","Are you signing up to potentially be a new employee?","Are you signing up to potentially be a new client?","Are you a current employee?","Are you a current client?"],
            userFirstName:"",
            userLastName:"",
            userMiddleName:"",
            userEmail:"",
            userPassword:"",
            userRepassword:"",
            userEmpId:"",
            userPhone:"",
            userBirth:moment().format("YYYY-MM-DD"),

            formAuthType: 1,//0 sign up 1=login
            createFailedCount: 0,
            checkLoginAtmp: false,
        }
    }

    componentDidMount(){
        this.LogOut();
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevProps.createuserStatus !== this.props.createuserStatus && this.props.createuserStatus >= 200){

            if(this.props.createuserStatus === 400 ){
                if(this.state.formType >= 3){
                    if(this.state.createFailedCount < 3){
                        this.setState({createFailedCount: this.state.createFailedCount + 1});
                        this.showmessage(this.props.createuserStatus,this.props.auth.message);
                    }
                    else if(this.state.createFailedCount === 3){
                        this.goToCallOffice();
                    }
                }
                else {
                    this.showmessage(this.props.createuserStatus,this.props.auth.message);
                }
            }
            else if(this.props.createuserStatus === 200){
                this.showmessage(this.props.createuserStatus,this.props.auth.message);
                this.goToOtherPage();
            }
        }
        if(prevProps.loginStatus !== this.props.loginStatus && this.props.loginStatus >= 200){
            this.showmessage(this.props.loginStatus,this.props.auth.message);
            if(this.props.loginStatus === 200){
                this.goToOtherPage();
            }
        }
    }
    componentWilUnmount() {

    }
    LogOut=()=>{
        this.props.LogOut();
    }
    goToCallOffice=()=>{
        this.setState({checkLoginAtmp: true});
        this.goToCallOfficeMsg();
        this.props.ChangeOfficeCallStatus(400);
        setTimeout(() => this.ResetCallOffice(), 300000);
    }
    ResetCallOffice=()=>{
        this.setState({createFailedCount:0});
        this.props.ChangeOfficeCallStatus(100);

    }
    goToCallOfficeMsg=()=>{
        this.showmessage(400,"Please call to office to fix this problem or wait 5min.");
    }
    goToOtherPage=()=>{
        const { formType }= this.state;
        const { loginData } = this.props;
        this.initFormValue();
        if(loginData !==null && loginData.type !==""){
            if(loginData.type === "admin" || loginData.admin && loginData.admin !== null && loginData.admin !==""){
                this.goToAdminDashboard();
            }
            else if(loginData.type === "employee"){
                this.goToEmployeeDashboard();
            }
            else if(loginData.type === "client"){
                this.goToClientDashboard();
            }

        }


    }
    goToAdminDashboard=()=>{
        this.props.history.push('/admin');
    }
    goToClientDashboard=()=>{
        this.props.history.push('/client');
    }
    goToEmployeeDashboard=()=>{
        this.props.history.push('/employee');
    }
    showmessage=(status,msg)=>{
        if(msg ==="") return;
        if(status >200){

            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else if(status ===200){
            toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    handleChangeFormType=(e,name)=>{
        if(name !==""){
            this.setState({[name]:e.target.value});
        }
    }

    initFormValue=()=>{
        this.setState({
            userFirstName:"",
            userLastName:"",
            userMiddleName:"",
            userEmail:"",
            userPassword:"",
            userRepassword:"",
            userEmpId:"",
            userPhone:"",
            userBirth:moment().format("YYYY-MM-DD"),
        });
    }

    signUp=()=>{
        const { formType } =this.state;
        var CheckFlag = true;
        if( formType === 1 || formType === 2 ){
            var a = this.validateFormValue("First Name","userFirstName");
            var a1 = this.validateFormValue("Middle initial","userMiddleName");
            var a2 = this.validateFormValue("Last Name","userLastName");
            if(!a || !a1 || !a2){
                CheckFlag = false;
            }
        }
        if(formType === 1 || formType === 2 || formType === 3 || formType === 4){
            var a3 = this.validateFormValue("Email","userEmail");
            var a4 = this.validateFormValue("Password","userPassword");
            if(!a3 || !a4){
                CheckFlag = false;
            }
        }
        if(formType === 3){
            var a = this.validateFormValue("Worker ID","userEmpId");
            if(!a){
                CheckFlag = false;
            }
        }
        if(formType === 4){
            var a = this.validateFormValue("Phone Number","userPhone");
            if(!a){
                CheckFlag = false;
            }
        }

        if(CheckFlag){
            const {formType,userFirstName,userLastName,userMiddleName,userEmail,userPassword,userRepassword,userEmpId,userPhone,userBirth,formAuthType} = this.state;
            let data = {
                formType:formType.toString(),
                userFirstName:userFirstName,
                userLastName:userLastName,
                userMiddleName:userMiddleName,
                userEmail:userEmail,
                userPassword:userPassword,
                userEmpId:userEmpId,
                userPhone:userPhone,
                userBirth:userBirth
            };
            if( formType > 0  && !this.state.checkLoginAtmp){
                if(this.props.auth !== null && this.props.auth.officeCall === 400){
                    this.showmessage(400,"Please call to office to fix this problem or wait 5 min");
                }
                else {
                    this.props.CreateNewUserAccount(data);
                }

            }
            if(this.state.checkLoginAtmp){
                this.goToCallOfficeMsg();
            }

        }
        else {
            console.log("No");
        }

    }
    validateFormValue=( dec, name )=>{
        if(name !=="" && dec !==""){
            var stateValue = this.state[name];
            if(stateValue === ""){
                var msg = "";
                if(name === "userPassword"){
                    var repwd = this.state.userRepassword;
                    if(stateValue === ""){
                        msg = "Please Input Password";
                    }
                }
                else {
                    msg = "Please Input "+dec;
                }

                if(msg !==""){
                    this.showmessage(400,msg);
                    return false;
                }
                else {
                    return false;
                }
            }
            else {
                if(name === "userPassword" && this.state.formAuthType === 0){
                    var repwd = this.state.userRepassword;
                    if(repwd === ""){
                        msg = "Please Input Repassword";
                        this.showmessage(400,msg);
                        return false;
                    }
                    else if(stateValue.length <=5){
                        msg = "Password Length is too short(at least 6)";
                        this.showmessage(400,msg);
                        return false;
                    }
                    else if(repwd !== stateValue){
                        msg = "Please Input Correct Repassword";
                        this.showmessage(400,msg);
                        return false;
                    }
                    else {
                        return true;
                    }

                }
                else if(name === "userEmail"){
                    if(stateValue.indexOf("@") ===-1 || stateValue.indexOf(".") === -1){
                        msg = "Please Input Correct Email";
                        this.showmessage(400,msg);
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return true;
                }
            }
        }
        else {
            return false;
        }
    }

    login=()=>{
        const { userEmail , userPassword} = this.state;
        var CheckFlag = true;
        var a3 = this.validateFormValue("Email","userEmail");
        var a4 = this.validateFormValue("Password","userPassword");
        if(!a3 || !a4){
            CheckFlag = false;
        }
        if(!CheckFlag){return ;}
        if(CheckFlag){
            var data ={
                userEmail : userEmail,
                userPassword: userPassword,
            };
            this.props.LoginAccount(data);
        }
    }

    goToLogin=()=>{
        this.initFormValue();
        this.setState({formAuthType: 1});
    }
    goToSignUp=()=>{
        this.initFormValue();
        this.setState({formAuthType: 0});
    }
    goToForgotPwd=()=>{

    }
    keyPress=(e,name)=>{
        if(e.keyCode === 13 && name !==""){
            this.login();
        }
    }
    render(){
        const {classes} = this.props;
        const {formType,formTypeStr,userFirstName,userLastName,userMiddleName,userEmail,userPassword,userRepassword,userEmpId,userPhone,userBirth,formAuthType} = this.state;

        return(
            <div className={classes.root}>
                { this.props.loading === 100 && (
                    <div className={classNames(classes.overlay, 'flex items-center w-full')}>
                        <CircularProgress className={classes.progress} color="secondary"  />
                    </div>
                )}
                <ToastContainer />
                <div className={classes.signupContent}>
                    {formAuthType === 0 ? (
                        <>
                            <div className={classes.authformtitle}>Sign Up</div>
                            <TextField
                                id="standard-select-currency"
                                select
                                label="Please Select Type"
                                value={formType}
                                onChange={(e)=>this.handleChangeFormType(e,"formType")}
                                variant="outlined"
                                margin="dense"
                                InputProps={{
                                    classes: {
                                        input: classes.inputformTypeSelect,
                                    },
                                }}
                                className={classes.formTypeSelect}
                            >
                                {formTypeStr.map((option,index) => (
                                    <MenuItem key={index} value={index} className={classes.menuitemformTypeSelect}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {formType ===1 || formType === 2 ? (
                                <TextField
                                    label="First Name"
                                    value={userFirstName}
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"userFirstName")}
                                />
                            ):(<div/>)}
                            {formType ===1 || formType === 2 ? (
                                <TextField
                                    label="Middle initial"
                                    value={userMiddleName}
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"userMiddleName")}
                                />
                            ):(<div/>)}
                            {formType ===1 || formType === 2 ? (
                                <TextField
                                    label="Last Name"
                                    value={userLastName}
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"userLastName")}
                                />
                            ):(<div/>)}
                            {formType ===1 || formType === 2 || formType === 3 || formType === 4 ? (
                                <TextField
                                    label="Email"
                                    value={userEmail}
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"userEmail")}
                                />
                            ):(<div/>)}
                            {formType ===1 || formType === 2 || formType === 3 || formType === 4 ? (
                                <TextField
                                    label="Password"
                                    value={userPassword}
                                    variant="outlined"
                                    margin="dense"
                                    type="password"
                                    className={classes.formTypeSelect}

                                    onChange={(e)=>this.handleChangeFormType(e,"userPassword")}
                                />
                            ):(<div/>)}
                            {formType ===1 || formType === 2 || formType === 3 || formType === 4 ? (
                                <TextField
                                    label="Re-Password"
                                    value={userRepassword}
                                    variant="outlined"
                                    margin="dense"
                                    type="password"
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"userRepassword")}
                                />
                            ):(<div/>)}
                            {formType === 3 ? (
                                <TextField
                                    label="AuthentiCare Worker ID"
                                    value={userEmpId}
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"userEmpId")}
                                />
                            ):(<div/>)}

                            {formType === 4 ?(
                                <MaskedInput
                                    mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                    className={classes.maskedInputPhone}
                                    placeholder="Enter a phone number"
                                    guide={false}
                                    id="phone"
                                    onBlur={() => {}}
                                    value={userPhone}
                                    onChange={(e)=>this.handleChangeFormType(e,"userPhone")}
                                />
                            ):(<div/>)}

                            {formType === 4 ? (
                                <TextField
                                    id="date"
                                    label="Birthday"
                                    type="date"
                                    variant="outlined"
                                    margin="dense"
                                    value={userBirth}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"userBirth")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            ):(<div/>)}

                            {formType > 0 ? (
                                <Button variant="contained" color="primary" className = {classes.formBtn} onClick = {this.signUp}>
                                    Sign Up
                                </Button>
                            ):(<div/>)}
                            {formType >= 0 ? (
                                <div className={classes.formLogintxt}>
                                    <span>You already have an account?</span>
                                    <span className={classes.logintxtBtn} onClick={this.goToLogin}>Login</span>
                                </div>
                            ):(<div/>)}
                        </>
                    ):(<div/>)}

                    {formAuthType === 1 ? (
                        <>
                            <div className={classes.authformtitle}>Login</div>

                            <TextField
                                label="Email"
                                value={userEmail}
                                variant="outlined"
                                margin="dense"
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"userEmail")}
                            />

                            <TextField
                                label="Password"
                                value={userPassword}
                                variant="outlined"
                                margin="dense"
                                type="password"
                                className={classes.formTypeSelect}
                                onKeyDown={(e)=>this.keyPress(e,"userPassword")}
                                onChange={(e)=>this.handleChangeFormType(e,"userPassword")}
                            />


                            <div className={classes.formforgottxt}>
                                <span className={classes.formforgotbtn} onClick={this.goToForgotPwd}>Forgot Password?</span>
                            </div>

                            <Button variant="contained" color="primary" className = {classes.formBtn} onClick = {this.login}>
                                Login
                            </Button>

                            <div className={classes.formLogintxt}>
                                <span>Don't have an account?</span>
                                <span className={classes.logintxtBtn} onClick={this.goToSignUp}>Sign Up</span>
                            </div>
                        </>
                    ):(<div/>)}


                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        CreateNewUserAccount : Actions.CreateNewUserAccount,
        ChangeOfficeCallStatus : Actions.ChangeOfficeCallStatus,
        LoginAccount : Actions.LoginAccount,
        LogOut : Actions.LogOut,
    }, dispatch);
}

function mapStateToProps({auth,}) {
    return {
        auth: auth,
        loading: auth.loading,
        createuserStatus: auth.createuserStatus,
        loginStatus: auth.loginStatus,
        loginData: auth.loginData,
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(SignUpScreen));