import {AdminService, AuthService} from "../service";

export const ADMIN_GET_ALL_PAPER_WORK_START       = "[ADMIN PAPERWORK] GET ALL START";
export const ADMIN_GET_ALL_PAPER_WORK_SUCCESS     = "[ADMIN PAPERWORK] GET ALL SUCCESS";
export const ADMIN_GET_ALL_PAPER_WORK_FAILD       = "[ADMIN PAPERWORK] GET ALL FAILD";


export const ADMIN_GET_ALL_UNCHECKED_PAPER_WORK_START       = "[ADMIN PAPERWORK] GET ALL UNCHECKED PAPER WORK START";
export const ADMIN_GET_ALL_UNCHECKED_PAPER_WORK_SUCCESS     = "[ADMIN PAPERWORK] GET ALL UNCHECKED PAPER WORK SUCCESS";
export const ADMIN_GET_ALL_UNCHECKED_PAPER_WORK_FAILD       = "[ADMIN PAPERWORK] GET ALL UNCHECKED PAPER WORK FAILD";

export const ADMIN_GET_ALL_CURRENT_EMPLOYER_LIST_START       = "[ADMIN CURRENT EMPLOYER] GET ALL CURRENT EMPLOYER START";
export const ADMIN_GET_ALL_CURRENT_EMPLOYER_LIST_SUCCESS     = "[ADMIN CURRENT EMPLOYER] GET ALL CURRENT EMPLOYER SUCCESS";
export const ADMIN_GET_ALL_CURRENT_EMPLOYER_LIST_FAILD       = "[ADMIN CURRENT EMPLOYER] GET ALL CURRENT EMPLOYER FAILD";



export const GET_ALL_CURRENT_CLIENT_LIST_START       = "[ADMIN CURRENT CLIENT] GET ALL CURRENT CLIENT START";
export const GET_ALL_CURRENT_CLIENT_LIST_SUCCESS     = "[ADMIN CURRENT CLIENT] GET ALL CURRENT CLIENT SUCCESS";
export const GET_ALL_CURRENT_CLIENT_LIST_FAILD       = "[ADMIN CURRENT CLIENT] GET ALL CURRENT CLIENT FAILD";


export const GET_ALL_USERS_LIST_START       = "[ADMIN USERS LIST] GET ALL USERS LIST START";
export const GET_ALL_USERS_LIST_SUCCESS     = "[ADMIN USERS LIST] GET ALL USERS LIST SUCCESS";
export const GET_ALL_USERS_LIST_FAILD       = "[ADMIN USERS LIST] GET ALL USERS LIST FAILD";

export const REMOVE_USER_ACCOUNT_START       = "[ADMIN REMOVE USER ACCOUNT] REMOVE USER ACCOUNT START";
export const REMOVE_USER_ACCOUNT_SUCCESS     = "[ADMIN REMOVE USER ACCOUNT] REMOVE USER ACCOUNT SUCCESS";
export const REMOVE_USER_ACCOUNT_FAILD       = "[ADMIN REMOVE USER ACCOUNT] REMOVE USER ACCOUNT FAILD";


export const UPDATE_USER_ACCOUNT_ROLE_START       = "[ADMIN UPDATE USER ACCOUNT] UPDATE USER ACCOUNT ROLE START";
export const UPDATE_USER_ACCOUNT_ROLE_SUCCESS     = "[ADMIN UPDATE USER ACCOUNT] UPDATE USER ACCOUNT ROLE SUCCESS";
export const UPDATE_USER_ACCOUNT_ROLE_FAILD       = "[ADMIN UPDATE USER ACCOUNT] UPDATE USER ACCOUNT ROLE FAILD";



export const ADMIN_ADD_NEW_FORM_START       = "[ADMIN ADD NEW FORM] ADD NEW FORM START";
export const ADMIN_ADD_NEW_FORM_SUCCESS     = "[ADMIN ADD NEW FORM] ADD NEW FORM SUCCESS";
export const ADMIN_ADD_NEW_FORM_FAILD       = "[ADMIN ADD NEW FORM] ADD NEW FORM FAILD";


export const ADMIN_GET_ALL_FORM_LIST_START       = "[ADMIN GET ALL FORM] GET ALL FORM LIST START";
export const ADMIN_GET_ALL_FORM_LIST_SUCCESS     = "[ADMIN GET ALL FORM] GET ALL FORM LIST SUCCESS";
export const ADMIN_GET_ALL_FORM_LIST_FAILD       = "[ADMIN GET ALL FORM] GET ALL FORM LIST FAILD";


export const ADMIN_REMOVE_FORM_ITEM_START       = "[ADMIN REMOVE FORM] REMOVE FORM ITEM START";
export const ADMIN_REMOVE_FORM_ITEM_SUCCESS     = "[ADMIN REMOVE FORM] REMOVE FORM ITEM SUCCESS";
export const ADMIN_REMOVE_FORM_ITEM_FAILD       = "[ADMIN REMOVE FORM] REMOVE FORM ITEM FAILD";


export const ADMIN_REMOVE_FORM_META_DATA_ITEM_START       = "[ADMIN REMOVE FORM META DATA ITEM] REMOVE FORM META DATA ITEM START";
export const ADMIN_REMOVE_FORM_META_DATA_ITEM_SUCCESS     = "[ADMIN REMOVE FORM META DATA ITEM] REMOVE FORM META DATA ITEM SUCCESS";
export const ADMIN_REMOVE_FORM_META_DATA_ITEM_FAILD       = "[ADMIN REMOVE FORM META DATA ITEM] REMOVE FORM META DATA ITEM FAILD";

export const ADMIN_UPDATE_FORM_META_DATA_ITEM_START       = "[ADMIN UPDATE FORM META DATA] UPDATE FORM META DATA ITEM START";
export const ADMIN_UPDATE_FORM_META_DATA_ITEM_SUCCESS     = "[ADMIN UPDATE FORM META DATA] UPDATE FORM META DATA ITEM SUCCESS";
export const ADMIN_UPDATE_FORM_META_DATA_ITEM_FAILD       = "[ADMIN UPDATE FORM META DATA] UPDATE FORM META DATA ITEM FAILD";


export const ADMIN_ADD_NEW_DATA_SET_ITEM_START       = "[ADMIN ADD NEW DATA SET ITEM] ADD NEW DATA SET ITEM START";
export const ADMIN_ADD_NEW_DATA_SET_ITEM_SUCCESS     = "[ADMIN ADD NEW DATA SET ITEM] ADD NEW DATA SET ITEM SUCCESS";
export const ADMIN_ADD_NEW_DATA_SET_ITEM_FAILD       = "[ADMIN ADD NEW DATA SET ITEM] ADD NEW DATA SET ITEM FAILD";


export const ADMIN_UPDATE_FORM_META_DATA_ITEM_FIELDS_START       = "[ADMIN UPDATE META DATA ITEM FIELDS] UPDATE META DATA ITEM START";
export const ADMIN_UPDATE_FORM_META_DATA_ITEM_FIELDS_SUCCESS     = "[ADMIN UPDATE META DATA ITEM FIELDS] UPDATE META DATA ITEM SUCCESS";
export const ADMIN_UPDATE_FORM_META_DATA_ITEM_FIELDS_FAILD       = "[ADMIN UPDATE META DATA ITEM FIELDS] UPDATE META DATA ITEM FAILD";


export const ADMIN_UPDATE_ITEM_DATA_WITH_NAME_VALUE_START       = "[ADMIN UPDATE ITEM DATA WITH NAME VALUE FIELDS] UPDATE ITEM DATA WITH NAME VALUE START";
export const ADMIN_UPDATE_ITEM_DATA_WITH_NAME_VALUE_SUCCESS     = "[ADMIN UPDATE ITEM DATA WITH NAME VALUE FIELDS] UPDATE ITEM DATA WITH NAME VALUE SUCCESS";
export const ADMIN_UPDATE_ITEM_DATA_WITH_NAME_VALUE_FAILD       = "[ADMIN UPDATE ITEM DATA WITH NAME VALUE FIELDS] UPDATE ITEM DATA WITH NAME VALUE FAILD";


export const ADMIN_GET_ALL_SUBMITED_FORM_ITEM_DATA_START       = "[ADMIN GET ALL SUBMITED FORM LIST ] ADMIN GET ALL SUBMIITED FORM LIST DATA START";
export const ADMIN_GET_ALL_SUBMITED_FORM_ITEM_DATA_SUCCESS     = "[ADMIN GET ALL SUBMITED FORM LIST ] ADMIN GET ALL SUBMIITED FORM LIST DATA SUCCESS";
export const ADMIN_GET_ALL_SUBMITED_FORM_ITEM_DATA_FAILD       = "[ADMIN GET ALL SUBMITED FORM LIST ] ADMIN GET ALL SUBMIITED FORM LIST DATA FAILD";



export function AdminGetAllSubmitFormList() {
    return (dispatch) => {
        dispatch({
            type: ADMIN_GET_ALL_SUBMITED_FORM_ITEM_DATA_START,
            payload: true
        });
        (async () => {
            let res = await AdminService.admingetsubmittedformlist();
            if (res.IsSuccess) {
                dispatch({
                    type: ADMIN_GET_ALL_SUBMITED_FORM_ITEM_DATA_SUCCESS,
                    payload: res.data,
                    msg : res.message,
                });

            } else {
                dispatch({
                    type: ADMIN_GET_ALL_SUBMITED_FORM_ITEM_DATA_FAILD,
                    payload: res.data,
                    msg : res.message,
                });
            }
        })();
    };
}

export function AdminUpdateItemDatawithNameValue(data) {
    return (dispatch) => {
        dispatch({
            type: ADMIN_UPDATE_ITEM_DATA_WITH_NAME_VALUE_START,
            payload: true
        });
        (async () => {
            let res = await AdminService.adminupdateItemsValName(data);
            if (res.IsSuccess) {
                dispatch({
                    type: ADMIN_UPDATE_ITEM_DATA_WITH_NAME_VALUE_SUCCESS,
                    payload: res.data,
                    msg : res.message,
                });

            } else {
                dispatch({
                    type: ADMIN_UPDATE_ITEM_DATA_WITH_NAME_VALUE_FAILD,
                    payload: res.data,
                    msg : res.message,
                });
            }
        })();
    };
}

export function AdminUpdateFormMetaDataItemsField(data) {
    return (dispatch) => {
        dispatch({
            type: ADMIN_UPDATE_FORM_META_DATA_ITEM_FIELDS_START,
            payload: true
        });
        (async () => {
            let res = await AdminService.adminUpdateformmetadatafieldsvalue(data);
            if (res.IsSuccess) {
                dispatch({
                    type: ADMIN_UPDATE_FORM_META_DATA_ITEM_FIELDS_SUCCESS,
                    payload: res.data,
                    msg : res.message,
                });

            } else {
                dispatch({
                    type: ADMIN_UPDATE_FORM_META_DATA_ITEM_FIELDS_FAILD,
                    payload: res.data,
                    msg : res.message,
                });
            }
        })();
    };
}

export function AdminAddNewDataSetItem(data) {
    return (dispatch) => {
        dispatch({
            type: ADMIN_ADD_NEW_DATA_SET_ITEM_START,
            payload: true
        });
        (async () => {
            let res = await AdminService.adminaddnewDataSetItem(data);
            if (res.IsSuccess) {
                dispatch({
                    type: ADMIN_ADD_NEW_DATA_SET_ITEM_SUCCESS,
                    payload: res.data,
                    msg : res.message,
                });

            } else {
                dispatch({
                    type: ADMIN_ADD_NEW_DATA_SET_ITEM_FAILD,
                    payload: res.data,
                    msg : res.message,
                });
            }
        })();
    };
}

export function AdminUpdateFormMetadataitem(data) {
    return (dispatch) => {
        dispatch({
            type: ADMIN_UPDATE_FORM_META_DATA_ITEM_START,
            payload: true
        });
        (async () => {
            let res = await AdminService.adminupdateformmetadataitem(data);
            if (res.IsSuccess) {
                dispatch({
                    type: ADMIN_UPDATE_FORM_META_DATA_ITEM_SUCCESS,
                    payload: res.data,
                });

            } else {
                dispatch({
                    type: ADMIN_UPDATE_FORM_META_DATA_ITEM_FAILD,
                    payload: res.data,

                });
            }
        })();
    };
}


export function AdminRemoveFormMetaDataItem(data) {
    return (dispatch) => {
        dispatch({
            type: ADMIN_REMOVE_FORM_META_DATA_ITEM_START,
            payload: true
        });
        (async () => {
            let res = await AdminService.adminremoveformmetadataitem(data);
            if (res.IsSuccess) {
                dispatch({
                    type: ADMIN_REMOVE_FORM_META_DATA_ITEM_SUCCESS,
                    payload: res.data,
                });

            } else {
                dispatch({
                    type: ADMIN_REMOVE_FORM_META_DATA_ITEM_FAILD,
                    payload: res.data,

                });
            }
        })();
    };
}

export function AdminRemoveFormItem(data) {
    return (dispatch) => {
        dispatch({
            type: ADMIN_REMOVE_FORM_ITEM_START,
            payload: true
        });
        (async () => {
            let res = await AdminService.adminremoveform(data);
            if (res.IsSuccess) {
                dispatch({
                    type: ADMIN_REMOVE_FORM_ITEM_SUCCESS,
                    payload: res.data,
                });

            } else {
                dispatch({
                    type: ADMIN_REMOVE_FORM_ITEM_FAILD,
                    payload: res.data,

                });
            }
        })();
    };
}


export function AdminGetAllFormList() {
    return (dispatch) => {
        dispatch({
            type: ADMIN_GET_ALL_FORM_LIST_START,
            payload: true
        });
        (async () => {
            let res = await AdminService.admingetallformlist();
            if (res.IsSuccess) {
                dispatch({
                    type: ADMIN_GET_ALL_FORM_LIST_SUCCESS,
                    payload: res.data,
                });

            } else {
                dispatch({
                    type: ADMIN_GET_ALL_FORM_LIST_FAILD,
                    payload: res.data,

                });
            }
        })();
    };
}


export function AddNewAdminForm(data) {
    return (dispatch) => {
        dispatch({
            type: ADMIN_ADD_NEW_FORM_START,
            payload: true
        });
        (async () => {
            let res = await AdminService.adminaddnewformpdffile(data);
            if (res.IsSuccess) {
                dispatch({
                    type: ADMIN_ADD_NEW_FORM_SUCCESS,
                    payload: res.data,
                    msg: res.message,
                });

            } else {
                dispatch({
                    type: ADMIN_ADD_NEW_FORM_FAILD,
                    payload: res.data,
                    msg: res.message,
                });
            }
        })();
    };
}


export function UpdateUserAccountRole(data) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_USER_ACCOUNT_ROLE_START,
            payload: true
        });
        (async () => {
            let res = await AdminService.updateUserAccountRole(data);
            if (res.IsSuccess) {
                dispatch({
                    type: UPDATE_USER_ACCOUNT_ROLE_SUCCESS,
                    payload: res.data,
                });

            } else {
                dispatch({
                    type: UPDATE_USER_ACCOUNT_ROLE_FAILD,
                    payload: res.data,

                });
            }
        })();
    };
}

export function RemoveUserAccount(data) {
    return (dispatch) => {
        dispatch({
            type: REMOVE_USER_ACCOUNT_START,
            payload: true
        });
        (async () => {
            let res = await AdminService.removeUser(data);
            if (res.IsSuccess) {
                dispatch({
                    type: REMOVE_USER_ACCOUNT_SUCCESS,
                    payload: res.data,
                });

            } else {
                dispatch({
                    type: REMOVE_USER_ACCOUNT_FAILD,
                    payload: res.data,

                });
            }
        })();
    };
}

export function GetAllUsersList() {
    return (dispatch) => {
        dispatch({
            type: GET_ALL_USERS_LIST_START,
            payload: true
        });
        (async () => {
            let res = await AdminService.getAllUsersList();
            if (res.IsSuccess) {
                dispatch({
                    type: GET_ALL_USERS_LIST_SUCCESS,
                    payload: res.data,
                });

            } else {
                dispatch({
                    type: GET_ALL_USERS_LIST_FAILD,
                    payload: res.data,

                });
            }
        })();
    };
}

export function GetAllCurrentClientList() {
    return (dispatch) => {
        dispatch({
            type: GET_ALL_CURRENT_CLIENT_LIST_START,
            payload: true
        });
        (async () => {
            let res = await AdminService.getAllCurrentClient();
            if (res.IsSuccess) {
                dispatch({
                    type: GET_ALL_CURRENT_CLIENT_LIST_SUCCESS,
                    payload: res.data,
                });

            } else {
                dispatch({
                    type: GET_ALL_CURRENT_CLIENT_LIST_FAILD,
                    payload: res.data,

                });
            }
        })();
    };
}

export function GetAllCurrentEmployer() {
    return (dispatch) => {
        dispatch({
            type: ADMIN_GET_ALL_CURRENT_EMPLOYER_LIST_START,
            payload: true
        });
        (async () => {
            let res = await AdminService.getAllCurrentEmployee();
            if (res.IsSuccess) {
                dispatch({
                    type: ADMIN_GET_ALL_CURRENT_EMPLOYER_LIST_SUCCESS,
                    payload: res.data,
                });

            } else {
                dispatch({
                    type: ADMIN_GET_ALL_CURRENT_EMPLOYER_LIST_FAILD,
                    payload: res.data,

                });
            }
        })();
    };
}

export function GetAllUnCheckedPaperwork() {
    return (dispatch) => {
        dispatch({
            type: ADMIN_GET_ALL_UNCHECKED_PAPER_WORK_START,
            payload: true
        });
        (async () => {
            let res = await AdminService.getUncheckedPaperWorks();
            if (res.IsSuccess) {
                dispatch({
                    type: ADMIN_GET_ALL_UNCHECKED_PAPER_WORK_SUCCESS,
                    payload: res.data,
                });

            } else {
                dispatch({
                    type: ADMIN_GET_ALL_UNCHECKED_PAPER_WORK_FAILD,
                    payload: res.data,

                });
            }
        })();
    };
}

export function GetPaperworksAll(data) {
    return (dispatch) => {
        dispatch({
            type: ADMIN_GET_ALL_PAPER_WORK_START,
            payload: true
        });
        (async () => {
            let res = await AdminService.getPaperWorks(data);
            if (res.IsSuccess) {
                dispatch({
                    type: ADMIN_GET_ALL_PAPER_WORK_SUCCESS,
                    payload: res.all,
                    emp: res.emp,
                    clt: res.clt,
                    msg: res.message,
                });

            } else {
                dispatch({
                    type: ADMIN_GET_ALL_PAPER_WORK_FAILD,
                    payload: res.data,
                    msg: res.message,
                });
            }
        })();
    };
}