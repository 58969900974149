import React, {Component} from 'react';
import {
    Select,
    Grid,
    Checkbox,
    FormControlLabel,
    TextField,
    Button,  CircularProgress,

} from '@material-ui/core';

import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import {withRouter} from 'react-router-dom';
// for store
import connect from "react-redux/es/connect/connect";
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Draggable from 'react-draggable';
import * as Actions from '../../../../store/actions';
import Images from '../../../Images';
import Colors from '../../../Colors';
import Slider from "react-slick";
import Menu from '@material-ui/core/Menu';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import Employee from './Employee';
import Client from './Client';
import UserAccounts from './UserAccounts';
import AccountPage from "../AccountPage";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import _ from 'lodash';

const styles = theme => ({
    title: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    acountMenu:{
        marginTop: 30
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: '#0000006b',
        zIndex: 1000,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    progress: {
        margin: 1,
    },
});


class AdminDashboard extends Component {
    constructor(props){
        super(props);
        this.state={
            paperViewstatus : 1,
            accountItemOpen:false,
            anchorEl: null,
        }
    }

    componentDidMount(){
        this.GetPaperWorkAll();
        this.getUnchecked = setInterval(this.getUnchekcedPaperwork,1000);
    }
    GetPaperWorkAll=()=>{
        const {loginData} = this.props;
        if(loginData !== null && loginData.id !==null && loginData.id !==""){
            var data = {userid:loginData.id};
            this.props.GetPaperworksAll(data);
        }
        else {

        }
        //GetPaperWork
    }
    componentDidUpdate(prevProps, prevState, snapshot){

    }
    componentWilUnmount() {
        clearInterval(this.getUnchecked);
    }
    getUnchekcedPaperwork=()=>{

        // const {unchekcedPaperwork} = this.props;
        // const {notificationCount} = this.state;
        // if(unchekcedPaperwork && unchekcedPaperwork !== null){
        //     if(unchekcedPaperwork.length !== notificationCount ){
        //         this.setState({notificationCount: unchekcedPaperwork.length});
        //         this.GetPaperWorkAll();
        //     }
        // }
        // this.props.GetAllUnCheckedPaperwork();

    }
    handleMenu=(e)=>{
        this.setState({anchorEl:e.currentTarget});
        this.setState({accountItemOpen:!this.state.accountItemOpen});
    }
    handleClose =()=>{
        this.setState({accountItemOpen:!this.state.accountItemOpen});
    }
    Logout=()=>{
        clearInterval(this.getUnchecked);
        this.props.LogOut();
        this.props.history.push('/signup');
        this.handleClose();
    }
    viewNotification=()=>{

    }
    handleMenuItem=(id)=>{
        //1=>emp, 2=>client, 3=>account
        if(id !=="" && id !==2){
            this.setState({paperViewstatus: id});
        }
        if(id === 2){
            if(this.props.loginData && this.props.loginData.admin ==="300"){
                this.setState({paperViewstatus: id});
            }
        }
        this.handleClose();
    }
    render(){
        const {classes} = this.props;
        const { paperViewstatus ,accountItemOpen,anchorEl ,notificationCount} = this.state;

        return(
            <div >
                { this.props.adminloadig === 100 && (
                    <div className={classNames(classes.overlay, 'flex items-center w-full')}>
                        <CircularProgress className={classes.progress} color="secondary"  />
                    </div>
                )}
                <ToastContainer />
                <AppBar position="static">
                    <Toolbar>
                        <img src={Images.favoicon} alt="" style={{width: 50,height: 50,marginRight:15}}/>
                        <Typography variant="h6" className={classes.title}>
                            Admin Dashboard
                        </Typography>
                        <Typography className={classes.title} style={{fontSize: 23,fontWeight: 700,transform: 'translate(-50%, -50%)',left:"50%",position:"absolute",top:32}}>
                            {paperViewstatus === 1 ? "Paperwork":""}
                            {paperViewstatus === 2 ? "Users":""}
                        </Typography>

                        <IconButton aria-label="show 11 new notifications" color="inherit" onClick={this.viewNotification}>
                            <Badge badgeContent={notificationCount} color="secondary" >
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        {true && (
                            <div>
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={this.handleMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    className={classes.acountMenu}
                                    open={accountItemOpen}
                                    onClose={this.handleClose}
                                >

                                    <MenuItem onClick ={()=>this.handleMenuItem(1)}>Paperwork</MenuItem>
                                    <MenuItem onClick ={()=>this.handleMenuItem(2)}>Users</MenuItem>
                                    <MenuItem onClick ={()=>this.handleMenuItem(3)}>My account</MenuItem>
                                    <MenuItem onClick ={this.Logout}>LogOut</MenuItem>
                                </Menu>
                            </div>
                        )}
                    </Toolbar>
                </AppBar>
                {paperViewstatus === 1 ? (<Employee/>):(<div/>)}
                {paperViewstatus === 2 ? (<UserAccounts/>):(<div/>)}
                {paperViewstatus === 3 ? (<AccountPage/>):(<div/>)}
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        LogOut : Actions.LogOut,
        GetPaperworksAll : Actions.GetPaperworksAll,
        GetAllUnCheckedPaperwork : Actions.GetAllUnCheckedPaperwork,
    }, dispatch);
}

function mapStateToProps({auth,admin}) {
    return {
        auth: auth,
        loginData: auth.loginData,
        userData: auth.userData,
        loading: auth.loading,

        adminloadig: admin.adminloadig,
        unchekcedPaperwork: admin.unchekcedPaperwork,
        admin:admin,
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(AdminDashboard));