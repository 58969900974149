import * as Actions from "../actions/";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import {
    CRAI_GANT_CLIENT_GET_ALL_FORM_LIST_START,
    GET_LOGIN_DATA_START,
    REMOVE_PAPER_WORK_ITEM_START,
    UPDAE_CLIENT_DETAIL_ACTIVE_START,
    UPDAE_EMPLOYER_DETAIL_DATA_START, UPDAE_FORM_DETAIL_ACTIVE_START,
    UPDAE_USER_ACCOUNT_DETAIL_START,
    UPDATE_PAPER_WORK_ITEM_START,
    USER_FILE_UPLOAD_START
} from "../actions/auth.actions";


const initialState = {

    data                : null,
    status              : 10,
    message             : '',
    loading             : 10,
    userData            : null,
    createuserStatus    : 100,
    loginData           : null,
    officeCall          : "",
    loginStatus         : 100,// for only login
    paperWork           : null,
    paperWorkStatus     : 100,
    getPaperWork        : null,
    getPaperWorkStatus  : 100,

    messageStatus : 100,
    messageList : null,
    messageMsg : "",
    messageItem :null,
    messageRemoveStatus: 100,
    messageAddStatus : 100,
    messageGetAllListStatus : 100,

    uploadfiledata : null,
    uploadfileStatus : 100,


    updatePaperworkStatus: 100,
    removePaperworkStatus : 100,

    updateEmployerDetailStatus: 100,
    updateClientActiveStatus : 100,

    craiGantFormList :null,
    getcraiGantFormListStatus: 100,

    updateFormDetailStatus : 100,

};

const auth = function(state = initialState, action) {
    switch ( action.type  )
    {
        case Actions.UPDAE_FORM_DETAIL_ACTIVE_START:{
            return {
                ...state,
                updateFormDetailStatus:100,
            }
        }
        case Actions.UPDAE_FORM_DETAIL_ACTIVE_SUCCESS:{
            return {
                ...state,
                updateFormDetailStatus: 200,
            }
        }
        case Actions.UPDAE_FORM_DETAIL_ACTIVE_FAILD:{
            return {
                ...state,
                updateFormDetailStatus: 400,
            }
        }
        case Actions.CRAI_GANT_CLIENT_GET_ALL_FORM_LIST_START:{
            return {
                ...state,
                getcraiGantFormListStatus:100,
            }
        }
        case Actions.CRAI_GANT_CLIENT_GET_ALL_FORM_LIST_SUCCESS:{
            return {
                ...state,
                getcraiGantFormListStatus: 200,
                craiGantFormList: action.payload,
                messageMsg:action.msg,
            }
        }
        case Actions.CRAI_GANT_CLIENT_GET_ALL_FORM_LIST_FAILD:{
            return {
                ...state,
                getcraiGantFormListStatus: 400,
                messageMsg: action.msg,
            }
        }
        case Actions.UPDAE_CLIENT_DETAIL_ACTIVE_START:{
            return {
                ...state,
                updateClientActiveStatus:100,
            }
        }
        case Actions.UPDAE_CLIENT_DETAIL_ACTIVE_SUCCESS:{
            return {
                ...state,
                updateClientActiveStatus: 200,
            }
        }
        case Actions.UPDAE_CLIENT_DETAIL_ACTIVE_FAILD:{
            return {
                ...state,
                updateClientActiveStatus: 400
            }
        }
        case Actions.UPDAE_USER_ACCOUNT_DETAIL_START:{
            return {
                ...state,
                loading: 100,
                message:"",
            }
        }
        case Actions.UPDAE_USER_ACCOUNT_DETAIL_SUCCESS:{
            return {
                ...state,
                loading : 200,
                message :action.msg,
                loginData: action.payload,
            }
        }
        case Actions.UPDAE_USER_ACCOUNT_DETAIL_FAILD:{
            return {
                ...state,
                loading: 400,
                message : action.msg,
            }
        }
        case Actions.UPDAE_EMPLOYER_DETAIL_DATA_START:{
            return {
                ...state,
                updateEmployerDetailStatus:100,
            }
        }
        case Actions.UPDAE_EMPLOYER_DETAIL_DATA_SUCCESS:{
            return {
                ...state,
                updateEmployerDetailStatus:200,
            }
        }
        case Actions.UPDAE_EMPLOYER_DETAIL_DATA_FAILD:{
            return {
                ...state,
                updateEmployerDetailStatus: 400,
            }
        }
        case Actions.GET_LOGIN_DATA_SUCCESS:{
            return {
                ...state,
                loginData:action.payload,
            }
        }
        case Actions.REMOVE_PAPER_WORK_ITEM_START:{
            return {
                ...state,
                removePaperworkStatus:100,
            }
        }
        case Actions.REMOVE_PAPER_WORK_ITEM_SUCCESS:{
            return {
                ...state,
                removePaperworkStatus:200,
            }
        }
        case Actions.REMOVE_PAPER_WORK_ITEM_FAILD:{
            return {
                ...state,
                removePaperworkStatus: 400
            }
        }
        case Actions.UPDATE_PAPER_WORK_ITEM_START:{
            return {
                ...state,
                updatePaperworkStatus:100,
            }
        }
        case Actions.UPDATE_PAPER_WORK_ITEM_SUCCESS:{
            return {
                ...state,
                updatePaperworkStatus: 200,

            }
        }
        case Actions.UPDATE_PAPER_WORK_ITEM_FAILD:{
            return {
                ...state,
                updatePaperworkStatus: 400,
            }
        }
        case Actions.USER_FILE_UPLOAD_START:{
            return{
                ...state,
                uploadfileStatus:100,
                uploadfiledata:null,
            }
        }
        case Actions.USER_FILE_UPLOAD_SUCCESS:{
            return {
                ...state,
                uploadfileStatus: 200,
                uploadfiledata:action.payload
            }
        }
        case Actions.USER_FILE_UPLOAD_FAILD:{
            return {
                ...state,
                uploadfileStatus: 400,
                uploadfiledata:null,
            }
        }
        case Actions.USER_MESSAGE_REMOVE_ITEM_START:{
            return {
                ...state,
                messageStatus:100,
                messageRemoveStatus:100,
            }
        }
        case Actions.USER_MESSAGE_REMOVE_ITEM_SUCCESS:{
            return {
                ...state,
                messageStatus: 200,
                messageRemoveStatus: 200,

            }
        }
        case Actions.USER_MESSAGE_REMOVE_ITEM_FAILD:{
            return {
                ...state,
                messageStatus: 400,
                messageRemoveStatus: 400,
            }
        }
        case Actions.USER_GET_ALL_MESSAGE_LIST_START:{
            return{
                ...state,
                messageGetAllListStatus:100,
                messageStatus:100,
                messageList:null,
                messageMsg:"",
            }
        }
        case Actions.USER_GET_ALL_MESSAGE_LIST_SUCCESS:{
            return {
                ...state,
                messageGetAllListStatus: 200,
                messageStatus: 200,
                messageList: action.payload,
                messageMsg : action.msg,
            }
        }
        case Actions.USER_GET_ALL_MESSAGE_LIST_FAILD:{
            return {
                ...state,
                messageStatus: 400,
                messageGetAllListStatus: 400,
                messageMsg: action.msg,
            }
        }
        case Actions.USER_ADD_NEW_MESSAGE_ITEM_START:{
            return {
                ...state,
                messageItem:null,
                messageStatus: 100,
                messageAddStatus: 100,
                messageMsg:"",
            }
        }
        case Actions.USER_ADD_NEW_MESSAGE_ITEM_SUCCESS:{
            return {
                ...state,
                messageItem: action.payload,
                messageStatus: 200,
                messageAddStatus: 200,
                messageMsg:action.msg,
            }
        }
        case Actions.USER_ADD_NEW_MESSAGE_ITEM_FAILD:{
            return {
                ...state,
                messageStatus: 400,
                messageAddStatus: 400,
                messageMsg: action.msg,
                messageItem: null,
            }
        }
        case Actions.CREATE_NEW_USER_ACCOCUNT_START:{
            return{
                ...state,
                message:"",
                loading:100,
                status: 100,
                createuserStatus:100,
                userData: null,
                loginData: null,
            }
        }
        case Actions.CREATE_NEW_USER_ACCOCUNT_SUCCESS:{
            return {
                ...state,
                loading : 200,
                status: 200,
                createuserStatus:200,
                userData:action.payload,
                message:action.msg,
                loginData: action.userInfo,
            }
        }
        case Actions.CREATE_NEW_USER_ACCOCUNT_FAILD:{
            return {
                ...state,
                status: 400,
                loading: 400,
                userData: null,
                createuserStatus: 400,
                message:action.msg,
                loginData:null,
            }
        }
        case Actions.LOGIN_USER_ACCOCUNT_START:{
            return {
                ...state,
                loading:100,
                status: 100,
                loginStatus: 100,
                userData: null,
                loginData: null,
                message:"",
            }
        }
        case Actions.LOGIN_USER_ACCOCUNT_SUCCESS:{
            return {
                ...state,
                loading : 200,
                status: 200,
                loginStatus: 200,
                userData:action.payload,
                message:action.msg,
                loginData: action.userInfo,
            }
        }
        case Actions.LOGIN_USER_ACCOCUNT_FAILD:{
            return {
                ...state,
                status: 400,
                loading: 400,
                loginStatus: 400,
                userData: null,
                message:action.msg,
                loginData:null,
            }
        }
        case Actions.USER_LOG_OUT:{
            return {
                ...initialState,
            }
        }
        case Actions.CREATE_NEW_USER_ACCOUNT_OFFICE_CALL:{
            return {
                ...state,
                officeCall:action.payload,
            }
        }
        case Actions.USER_PAPER_WORK_SUMBIT_START:{
            return {
                ...state,
                loading: 100,
                paperWorkStatus: 100,
            }
        }
        case Actions.USER_PAPER_WORK_SUMBIT_SUCCESS:{
            return {
                ...state,
                loading : 200,
                paperWorkStatus : 200,
                // paperWork:action.payload,
                message: action.msg,
                getPaperWork:action.item,
            }
        }
        case Actions.USER_PAPER_WORK_SUMBIT_FAILD:{
            return {
                ...state,
                loading : 400,
                paperWorkStatus : 400,
                message:action.msg,
                getPaperWork:action.item,
            }
        }
        case Actions.USER_PAPER_WORK_GET_START:{
            return {
                ...state,
                getPaperWorkStatus: 100,
                getPaperWork:null,
            }
        }
        case Actions.USER_PAPER_WORK_GET_SUCCESS:{
            return {
                ...state,
                getPaperWorkStatus: 200,
                getPaperWork: action.payload,

            }
        }
        case Actions.USER_PAPER_WORK_GET_FAILD:{
            return {
                ...state,
                getPaperWorkStatus: 400,
                getPaperWork: null,
            }
        }
        default:
        {
            return state;
        }
    }
}
const persistConfig = {
    key: 'auth',
    storage: storage,
};
export default persistReducer(persistConfig, auth);