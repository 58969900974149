import {AuthService} from "../service";
import {MessageService} from "../service";



export const CREATE_NEW_USER_ACCOCUNT_START       = "[CREATE NEW ACCOUNT] START";
export const CREATE_NEW_USER_ACCOCUNT_SUCCESS     = "[CREATE NEW ACCOUNT] SUCCESS";
export const CREATE_NEW_USER_ACCOCUNT_FAILD       = "[CREATE NEW ACCOUNT] FAILD";


export const LOGIN_USER_ACCOCUNT_START       = "[LOGIN ACCOUNT] START";
export const LOGIN_USER_ACCOCUNT_SUCCESS     = "[LOGIN ACCOUNT] SUCCESS";
export const LOGIN_USER_ACCOCUNT_FAILD       = "[LOGIN ACCOUNT] FAILD";

export const USER_PAPER_WORK_SUMBIT_START       = "[USER PAPER WORK] SUBMIT START";
export const USER_PAPER_WORK_SUMBIT_SUCCESS     = "[USER PAPER WORK] SUBMIT SUCCESS";
export const USER_PAPER_WORK_SUMBIT_FAILD       = "[USER PAPER WORK] SUBMIT FAILD";


export const USER_PAPER_WORK_GET_START       = "[USER PAPER WORK] GET START";
export const USER_PAPER_WORK_GET_SUCCESS     = "[USER PAPER WORK] GET SUCCESS";
export const USER_PAPER_WORK_GET_FAILD       = "[USER PAPER WORK] GET FAILD";

export const USER_LOG_OUT                         = "[USER AUTH] LOG OUT";

export const CREATE_NEW_USER_ACCOUNT_OFFICE_CALL    ="[CREATE NEW ACCOUNT] OFFICE CALL";


export const USER_GET_ALL_MESSAGE_LIST_START       = "[USER MESSAGE] GET ALL MESSAGE LIST START";
export const USER_GET_ALL_MESSAGE_LIST_SUCCESS     = "[USER MESSAGE] GET ALL MESSAGE LIST SUCCESS";
export const USER_GET_ALL_MESSAGE_LIST_FAILD       = "[USER MESSAGE] GET ALL MESSAGE LIST FAILD";


export const USER_ADD_NEW_MESSAGE_ITEM_START       = "[USER MESSAGE] ADD NEW MESSAGE ITEM START";
export const USER_ADD_NEW_MESSAGE_ITEM_SUCCESS     = "[USER MESSAGE] ADD NEW MESSAGE ITEM SUCCESS";
export const USER_ADD_NEW_MESSAGE_ITEM_FAILD       = "[USER MESSAGE] ADD NEW MESSAGE ITEM FAILD";


export const USER_MESSAGE_REMOVE_ITEM_START       = "[USER MESSAGE] REMOVE USER MESSAGE ITEM START";
export const USER_MESSAGE_REMOVE_ITEM_SUCCESS     = "[USER MESSAGE] REMOVE USER MESSAGE ITEM SUCCESS";
export const USER_MESSAGE_REMOVE_ITEM_FAILD       = "[USER MESSAGE] REMOVE USER MESSAGE ITEM FAILD";

export const USER_FILE_UPLOAD_START       = "[USER FILE] USER FILE UPLOAD START";
export const USER_FILE_UPLOAD_SUCCESS     = "[USER FILE] USER FILE UPLOAD SUCCESS";
export const USER_FILE_UPLOAD_FAILD       = "[USER FILE] USER FILE UPLOAD FAILD";


export const UPDATE_PAPER_WORK_ITEM_START       = "[PAPER WORK] UPDATE PAPER WORK ITEM START";
export const UPDATE_PAPER_WORK_ITEM_SUCCESS     = "[PAPER WORK] UPDATE PAPER WORK ITEM SUCCESS";
export const UPDATE_PAPER_WORK_ITEM_FAILD       = "[PAPER WORK] UPDATE PAPER WORK ITEM FAILD";


export const REMOVE_PAPER_WORK_ITEM_START       = "[PAPER WORK] REMOVE PAPER WORK ITEM START";
export const REMOVE_PAPER_WORK_ITEM_SUCCESS     = "[PAPER WORK] REMOVE PAPER WORK ITEM SUCCESS";
export const REMOVE_PAPER_WORK_ITEM_FAILD       = "[PAPER WORK] REMOVE PAPER WORK ITEM FAILD";

export const GET_LOGIN_DATA_START       = "[GET LOGIN DATA] GET LOGIN DATA START";
export const GET_LOGIN_DATA_SUCCESS     = "[GET LOGIN DATA] GET LOGIN DATA SUCCESS";
export const GET_LOGIN_DATA_FAILD       = "[GET LOGIN DATA] GET LOGIN DATA FAILD";



export const UPDAE_EMPLOYER_DETAIL_DATA_START       = "[UPDATE EMPLOYER] UPDATE EMPLOYER DETAIL DATA START";
export const UPDAE_EMPLOYER_DETAIL_DATA_SUCCESS     = "[UPDATE EMPLOYER] UPDATE EMPLOYER DETAIL DATA SUCCESS";
export const UPDAE_EMPLOYER_DETAIL_DATA_FAILD       = "[UPDATE EMPLOYER] UPDATE EMPLOYER DETAIL DATA FAILD";

export const UPDAE_USER_ACCOUNT_DETAIL_START       = "[UPDATE USER DETAIL] UPDATE USER ACCOUNT DETAIL START";
export const UPDAE_USER_ACCOUNT_DETAIL_SUCCESS     = "[UPDATE USER DETAIL] UPDATE USER ACCOUNT DETAIL SUCCESS";
export const UPDAE_USER_ACCOUNT_DETAIL_FAILD       = "[UPDATE USER DETAIL] UPDATE USER ACCOUNT DETAIL FAILD";


export const UPDAE_CLIENT_DETAIL_ACTIVE_START       = "[UPDATE CLIENT] UPDATE CLIENT ACTIVE START";
export const UPDAE_CLIENT_DETAIL_ACTIVE_SUCCESS     = "[UPDATE CLIENT] UPDATE CLIENT ACTIVE SUCCESS";
export const UPDAE_CLIENT_DETAIL_ACTIVE_FAILD       = "[UPDATE CLIENT] UPDATE CLIENT ACTIVE FAILD";


export const CRAI_GANT_CLIENT_GET_ALL_FORM_LIST_START       = "[CRAT GANT CLIENT] GET ALL FORM LIST START";
export const CRAI_GANT_CLIENT_GET_ALL_FORM_LIST_SUCCESS     = "[CRAT GANT CLIENT] GET ALL FORM LIST SUCCESS";
export const CRAI_GANT_CLIENT_GET_ALL_FORM_LIST_FAILD       = "[CRAT GANT CLIENT] GET ALL FORM LIST FAILD";


export const UPDAE_FORM_DETAIL_ACTIVE_START       = "[UPDATE FORM] UPDATE FORM DETAILS START";
export const UPDAE_FORM_DETAIL_ACTIVE_SUCCESS     = "[UPDATE FORM] UPDATE FORM DETAILS SUCCESS";
export const UPDAE_FORM_DETAIL_ACTIVE_FAILD       = "[UPDATE FORM] UPDATE FORM DETAILS FAILD";



export function UpdateFormDetailInfos(data) {
    return (dispatch) => {
        dispatch({
            type: UPDAE_FORM_DETAIL_ACTIVE_START,
            payload: true
        });
        (async () => {
            let res = await AuthService.upDateFormDetails(data);
            if (res.IsSuccess) {
                dispatch({
                    type: UPDAE_FORM_DETAIL_ACTIVE_SUCCESS,
                    payload: res.data,
                    msg: res.message,
                });
            } else {
                dispatch({
                    type: UPDAE_FORM_DETAIL_ACTIVE_FAILD,
                    payload: res.data,
                    msg: res.message,
                });
            }
        })();
    };
}

export function GetAllCraiGantFormList(data) {
    return (dispatch) => {
        dispatch({
            type: CRAI_GANT_CLIENT_GET_ALL_FORM_LIST_START,
            payload: true
        });
        (async () => {
            let res = await AuthService.getAllUserCraIGantForms(data);
            if (res.IsSuccess) {
                dispatch({
                    type: CRAI_GANT_CLIENT_GET_ALL_FORM_LIST_SUCCESS,
                    payload: res.data,
                    msg: res.message,
                });
            } else {
                dispatch({
                    type: CRAI_GANT_CLIENT_GET_ALL_FORM_LIST_FAILD,
                    payload: res.data,
                    msg: res.message,
                });
            }
        })();
    };
}


export function UpdateCurrentClientActive(data) {
    return (dispatch) => {
        dispatch({
            type: UPDAE_CLIENT_DETAIL_ACTIVE_START,
            payload: true
        });
        (async () => {
            let res = await AuthService.updateClientDetail(data);
            if (res.IsSuccess) {
                dispatch({
                    type: UPDAE_CLIENT_DETAIL_ACTIVE_SUCCESS,
                    payload: res.data,
                    msg: res.message,
                });
            } else {
                dispatch({
                    type: UPDAE_CLIENT_DETAIL_ACTIVE_FAILD,
                    payload: res.data,
                    msg: res.message,
                });
            }
        })();
    };
}

export function UpdateUserAccountDetail(data) {
    return (dispatch) => {
        dispatch({
            type: UPDAE_USER_ACCOUNT_DETAIL_START,
            payload: true
        });
        (async () => {
            let res = await AuthService.UpdateUserDetail(data);
            if (res.IsSuccess) {
                dispatch({
                    type: UPDAE_USER_ACCOUNT_DETAIL_SUCCESS,
                    payload: res.data,
                    msg: res.message,
                });
            } else {
                dispatch({
                    type: UPDAE_USER_ACCOUNT_DETAIL_FAILD,
                    payload: res.data,
                    msg: res.message,
                });
            }
        })();
    };
}

export function UpdateEmployerDetailData(data) {
    return (dispatch) => {
        dispatch({
            type: UPDAE_EMPLOYER_DETAIL_DATA_START,
            payload: true
        });
        (async () => {
            let res = await AuthService.UpdateEmployerDetail(data);
            if (res.IsSuccess) {
                dispatch({
                    type: UPDAE_EMPLOYER_DETAIL_DATA_SUCCESS,
                    payload: res.data,
                    msg: res.message,
                });
            } else {
                dispatch({
                    type: UPDAE_EMPLOYER_DETAIL_DATA_SUCCESS,
                    payload: res.data,
                    msg: res.message,
                });
            }
        })();
    };
}


export function GetLoginData(data) {
    return (dispatch) => {
        dispatch({
            type: GET_LOGIN_DATA_START,
            payload: true
        });
        (async () => {
            let res = await AuthService.getlogindata(data);
            if (res.IsSuccess) {
                dispatch({
                    type: GET_LOGIN_DATA_SUCCESS,
                    payload: res.data,
                    msg: res.message,
                });
            } else {
                dispatch({
                    type: GET_LOGIN_DATA_FAILD,
                    payload: res.data,
                    msg: res.message,
                });
            }
        })();
    };
}


export function RemovePaperWork(data) {
    return (dispatch) => {
        dispatch({
            type: REMOVE_PAPER_WORK_ITEM_START,
            payload: true
        });
        (async () => {
            let res = await AuthService.RemovePaperwork(data);
            if (res.IsSuccess) {
                dispatch({
                    type: REMOVE_PAPER_WORK_ITEM_SUCCESS,
                    payload: res.data,
                    msg: res.message,
                });
            } else {
                dispatch({
                    type: REMOVE_PAPER_WORK_ITEM_FAILD,
                    payload: res.data,
                    msg: res.message,
                });
            }
        })();
    };
}
export function UpdatePaperWork(data) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_PAPER_WORK_ITEM_START,
            payload: true
        });
        (async () => {
            let res = await AuthService.upDatePaperwork(data);
            if (res.IsSuccess) {
                dispatch({
                    type: UPDATE_PAPER_WORK_ITEM_SUCCESS,
                    payload: res.data,
                    msg: res.message,
                });
            } else {
                dispatch({
                    type: UPDATE_PAPER_WORK_ITEM_FAILD,
                    payload: res.data,
                    msg: res.message,
                });
            }
        })();
    };
}

export function FileUpload(data) {
    return (dispatch) => {
        dispatch({
            type: USER_FILE_UPLOAD_START,
            payload: true
        });
        (async () => {
            let res = await MessageService.FileUpload(data);
            if (res.IsSuccess) {
                dispatch({
                    type: USER_FILE_UPLOAD_SUCCESS,
                    payload: res.data,
                    msg: res.message,
                });
            } else {
                dispatch({
                    type: USER_FILE_UPLOAD_FAILD,
                    payload: res.data,
                    msg: res.message,
                });
            }
        })();
    };
}

export function RemoveUserMessage(data) {
    return (dispatch) => {
        dispatch({
            type: USER_MESSAGE_REMOVE_ITEM_START,
            payload: true
        });
        (async () => {
            let res = await MessageService.removeMessage(data);
            if (res.IsSuccess) {
                dispatch({
                    type: USER_MESSAGE_REMOVE_ITEM_SUCCESS,
                    payload: res.data,
                    msg: res.message,
                });
            } else {
                dispatch({
                    type: USER_MESSAGE_REMOVE_ITEM_FAILD,
                    payload: res.data,
                    msg: res.message,
                });
            }
        })();
    };
}

export function GetAllUserMessage(data) {
    return (dispatch) => {
        dispatch({
            type: USER_GET_ALL_MESSAGE_LIST_START,
            payload: true
        });
        (async () => {
            let res = await MessageService.getAllMessageList(data);
            if (res.IsSuccess) {
                dispatch({
                    type: USER_GET_ALL_MESSAGE_LIST_SUCCESS,
                    payload: res.data,
                    msg: res.message,
                });

            } else {
                dispatch({
                    type: USER_GET_ALL_MESSAGE_LIST_FAILD,
                    payload: res.data,
                    msg: res.message,
                });
            }
        })();
    };
}

export function AddNewMessage(data) {
    return (dispatch) => {
        dispatch({
            type: USER_ADD_NEW_MESSAGE_ITEM_START,
            payload: true
        });
        (async () => {
            let res = await MessageService.newMessage(data);
            if (res.IsSuccess) {
                dispatch({
                    type: USER_ADD_NEW_MESSAGE_ITEM_SUCCESS,
                    payload: res.data,
                    msg: res.message,
                });

            } else {
                dispatch({
                    type: USER_ADD_NEW_MESSAGE_ITEM_FAILD,
                    payload: res.data,
                    msg: res.message,
                });
            }
        })();
    };
}


export function GetPaperWork(data) {
    return (dispatch) => {
        dispatch({
            type: USER_PAPER_WORK_GET_START,
            payload: true
        });
        (async () => {
            let res = await AuthService.getPaperwork(data);
            if (res.IsSuccess) {
                dispatch({
                    type: USER_PAPER_WORK_GET_SUCCESS,
                    payload: res.data,
                    item: res.item,
                    msg: res.message,
                });

            } else {
                dispatch({
                    type: USER_PAPER_WORK_GET_FAILD,
                    payload: res.data,
                    msg: res.message,
                });
            }
        })();
    };
}

export function SubmitPaperWork(data) {
    return (dispatch) => {
        dispatch({
            type: USER_PAPER_WORK_SUMBIT_START,
            payload: true
        });
        (async () => {
            let res = await AuthService.submitPaperwork(data);
            if (res.IsSuccess) {
                dispatch({
                    type: USER_PAPER_WORK_SUMBIT_SUCCESS,
                    payload: res.data,
                    item: res.item,
                    msg: res.message,
                });

            } else {
                dispatch({
                    type: USER_PAPER_WORK_SUMBIT_FAILD,
                    payload: res.data,
                    item: res.item,
                    msg: res.message,
                });
            }
        })();
    };
}

export function LoginAccount(data) {
    return (dispatch) => {
        dispatch({
            type: LOGIN_USER_ACCOCUNT_START,
            payload: true
        });

        (async () => {
            let res = await AuthService.loginUser(data);
            if (res.IsSuccess) {

                dispatch({
                    type: LOGIN_USER_ACCOCUNT_SUCCESS,
                    payload: res.data,
                    msg: res.message,
                    userInfo :res.userInfo,
                });

            } else {
                dispatch({
                    type: LOGIN_USER_ACCOCUNT_FAILD,
                    payload: res.data,
                    msg: res.message,
                });
            }
        })();
    };
}


export function ChangeOfficeCallStatus(status) {
    return (dispatch) => {
        dispatch({
            type: CREATE_NEW_USER_ACCOUNT_OFFICE_CALL,
            payload: status
        });
    };
}

export function LogOut() {
    return (dispatch) => {
        dispatch({
            type: USER_LOG_OUT,
            payload: true
        });
    };
}

export function CreateNewUserAccount(data) {
    return (dispatch) => {
        dispatch({
            type: CREATE_NEW_USER_ACCOCUNT_START,
            payload: true
        });

        (async () => {
            let res = await AuthService.createNewUser(data);
            if (res.IsSuccess) {

                dispatch({
                    type: CREATE_NEW_USER_ACCOCUNT_SUCCESS,
                    payload: res.data,
                    msg: res.message,
                    userInfo :res.userInfo,
                });

            } else {
                dispatch({
                    type: CREATE_NEW_USER_ACCOCUNT_FAILD,
                    payload: res.data,
                    msg: res.message,
                });
            }
        })();
    };
}



