import React, {Component} from 'react';
import {
    Select,
    Grid,
    Checkbox,
    FormControlLabel,
    TextField,
    Button,  CircularProgress,

} from '@material-ui/core';

import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import {withRouter} from 'react-router-dom';
// for store
import connect from "react-redux/es/connect/connect";
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Draggable from 'react-draggable';
import * as Actions from '../../../../store/actions';
import * as Service from '../../../../store/service';
import Images from '../../../Images';
import Colors from '../../../Colors';
import Slider from "react-slick";
import Menu from '@material-ui/core/Menu';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import SendIcon from '@material-ui/icons/Send';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import Icon from '@material-ui/core/Icon';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import _ from 'lodash';
import moment from "moment";
import axios from "axios/index";
import ReactTable from "react-table";
import "react-table/react-table.css";
import UserFiler from "./UserFiler";
const axios_instance = axios.create({
    headers: {'Content-Type': 'multipart/form-data'}});

const hexToRgb = (hex) =>{
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};
const styles = theme => ({
    title: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    acountMenu:{
        marginTop: 30
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: '#0000006b',
        zIndex: 1000,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    progress: {
        margin: 1,
    },
    tableTheadRow:{
        // backgroundColor: 'rgba(' + hexToRgb(theme.palette.primary.main).r + ',' + hexToRgb(theme.palette.primary.main).g + ',' + hexToRgb(theme.palette.primary.main).b +', .2)'
        backgroundColor: theme.palette.primary.main
    },
    tableThEven:{
        backgroundColor: 'rgba(' + hexToRgb(theme.palette.secondary.main).r + ',' + hexToRgb(theme.palette.secondary.main).g + ',' + hexToRgb(theme.palette.secondary.main).b +', .3)'
    },
    tableTdEven:{
        // backgroundColor: 'rgba(' + hexToRgb(theme.palette.secondary.main).r + ',' + hexToRgb(theme.palette.secondary.main).g + ',' + hexToRgb(theme.palette.secondary.main).b +', .1)'
    },
    displaynone:{
        display:"none",
    },
    DialogTitle:{
        backgroundColor: '#3f51b5',
        color: 'white',
        fontWeight: 800,
    },
    closeBtn :{
        position: 'absolute',
        top: 9,
        right: 36,
    },
    mainmsgboard:{
        width:"100%",
        height:'calc(100vh - 192px)',
        // backgroundColor:"black",
        display:"flex",
        flexDirection:"row",
    },
    mainmsguserList:{
        width:350,
        height:'calc(100vh - 192px)',
        backgroundColor:"blue",
        overflowY:"scroll",
    },
    mainusermsglist:{
        height:'calc(100vh - 192px)',
        // backgroundColor:"green"
    },
    msgboard:{
        width:"100%",
        display:"flex",
        flexDirection:"column"
    },
    userList:{
        // overflowY:"scroll",
    },
    userItems:{
        height:80,
        border:"solid 1px gray",
        // backgroundColor:"yellow",
    },
    messageboard:{
        height: 'calc(100vh - 192px - 70px)',
        overflowY:"scroll",
        overflowX:"hidden",
    },
    messateitemlist:{

    },
    message:{
        maxWidth:"80%",
        // height: 80,
        backgroundColor:"#efebf3",
        border:"solid 1px gray",
        display:"table",
        clear:"both",
        marginTop:8,
        marginBottom:8,

    },
    messageeven:{
        borderBottomLeftRadius: 10,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 20,
        paddingLeft: 25,
        paddingRight: 25,
        float:"right",
        marginRight:25,
    },
    messageodd:{
        borderBottomRightRadius: 10,
        borderTopRightRadius: 10,
        borderTopLeftRadius: 20,
        paddingLeft: 25,
        paddingRight: 25,
        float:"left",
        marginLeft: 25,
    },
    msgaccountname:{
        display:"flex",
        marginTop: 10
    },
    accountname:{
        marginLeft: 20,
        fontWeight:800
    },
    msgtxtbody:{
        height: 70,
        width:"100%",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        // backgroundColor:"blue",
    },
    messagetext:{
        width:'calc(100% - 250px)',
    },
    messagesendbtn:{
        height: 40,
        marginLeft: 20
    },
    fileinput:{
        display:"none"
    }
});


class MessageLists extends Component {
    constructor(props){
        super(props);
        this.state={
            loginStatus : 100,
            userFilerOption : false,
            message:"",
            selectedFile: null,
            messageData:null,

            receiverid:"",
            type:"",
            typeid:"",
            adminLeavel:"",
        }
        this.inputOpenFileRef = React.createRef();
    }

    componentDidMount(){
        this.CheckAdmin();
        if(this.props !== null && this.props.messageData !==null && this.props.messageData){
            this.GetMessageData();
        }
        if(this.props.userMessage && this.props.userMessage !== null && this.props.userMessage ==="userMessage"){
            this.GetAllMessage("userMessage");
            this.MakePaperDetailData();
        }
        this.scrollToBottom();
        this.CheckMyallMessage();
    }
    MakePaperDetailData=()=>{
        const {getPaperWork} = this.props;
        if(getPaperWork && getPaperWork !== null && getPaperWork.id && getPaperWork.id !==null && getPaperWork.id !==""){
            this.setState({
                typeid:getPaperWork.id,
                type: "paperwork"
            })
        }
    }
    GetMessageData =()=>{
        const {messageData} = this.props;
        if(messageData && messageData !== null){
            this.GetAllMessage();
            this.setState({
                messageData: messageData,
                receiverid: messageData.userid,
                typeid: messageData.id,
                type: messageData.papertype,
            });
        }
    }
    CheckMyallMessage=()=>{
        //this.getMymessage = setInterval(this.GetAllMessage, 5000);
    }
    CheckAdmin=()=>{
        const { loginData } = this.props;
        if(loginData !== null && loginData.admin !==""){
            this.setState({adminLeavel: parseInt(loginData.admin)});
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevProps.messageAddStatus !== this.props.messageAddStatus && this.props.messageAddStatus >=200){
            this.GetAllMessage();
            if(this.props.userMessage && this.props.userMessage !== null && this.props.userMessage ==="userMessage"){
                this.GetAllMessage("userMessage");
            }

        }
        if(JSON.stringify(prevProps.messageList) !== JSON.stringify(this.props.messageList)){
            this.scrollToBottom();
        }


    }
    GetAllMessage=(val)=>{
        const {messageData,loginData,getPaperWork} = this.props;
        var adminLeavel = loginData !== null && loginData.admin !=="" ?  parseInt(loginData.admin):0;
        if(messageData && messageData !== null && messageData.papertype !== ""){//admin paperwork messages
            if( adminLeavel !==""){ // only for admin paperwork msg
                var data ={
                    // receiverid:messageData.userid,
                    typeid:messageData.id,
                    type: "paperwork"
                };
                if(messageData.id !=="" && messageData.userid !==""){
                    this.props.GetAllUserMessage(data);
                }
            }
        }
        if(val && val !== null && val ==="userMessage"){//employer  msg
            if(getPaperWork && getPaperWork !== null && getPaperWork.papertype !==""){
                var id = loginData.id;
                var data ={
                    messageType : "userMsg",
                    typeid:getPaperWork.id,
                    type: "paperwork"
                };
                if(data.type && data.type !== null && data.type !==""){
                    this.props.GetAllUserMessage(data);
                }
            }

        }
        //employee or client message

    }
    componentWilUnmount() {

    }
    handleChangeFormType=(e,name)=>{
        if(name !==""){
            this.setState({[name]:e.target.value});
        }
    }
    keyPress=(e,name)=>{
        if(e.keyCode === 13 && name !==""){
            this.setState({[name]:e.target.value});
            this.senderMessage();
        }
    }
    sendMessageBtn=()=>{
        if(this.state.selectedFile !== null){
            //this.fileUpload();
            this.senderFileMessage();
        }
        else {
            this.senderMessage();
        }


    }
    addfile=()=>{
        this.inputOpenFileRef.current.click()
    }
    onFileChange=(event)=>{
        this.setState({ selectedFile: event.target.files[0] });
        const { message } = this.state;
        var filename = event.target.files[0].name;
        this.setState({message:filename});
    }
    senderFileMessage =async()=>{
        let formData = new FormData();
        if(this.state.selectedFile !== null ){
            formData.append('file',this.state.selectedFile);
            await axios_instance.post(Service.BASE_API_URL+'/message/fileupload', formData)
                .then(response=>{
                    if(response.status===200){
                        var data = response.data;
                        var link = data.data;
                        var IsSuccess = data.IsSuccess;
                        if(IsSuccess && link && link !== null){
                            this.senderMessage(link);
                        }
                    }
                })
        }

    }
    senderMessage =(msgURL)=>{
        const {message,selectedFile , receiverid,type,typeid} = this.state;
        const { loginData } = this.props;
        var data = {
            receiverid: receiverid,
            senderid: loginData !== null ? loginData.id :"",
            type: type !=="" ? "paperwork" :"text",//paperwork
            typeid: typeid,
            date: moment().format("YYYY-MM-DD hh:mm:ss"),
            msg: msgURL !== null && msgURL ? msgURL: message,
            msgtype:msgURL && msgURL !==null ? "file":"msg",
        };
        if(true){
            if( data.senderid !== "" && data.senderid  !== data.receiverid){
                this.setState({message:"",selectedFile:null});
                console.log(data);
                this.props.AddNewMessage(data);
            }
        }

    }
    fileUpload=()=>{
        let formData = new FormData();
        if(this.state.selectedFile !== null ){
            formData.append('file',this.state.selectedFile);
            this.props.FileUpload(formData);
        }

    }
    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "auto" });
    }
    clearfile=()=>{
        this.setState({selectedFile:null,message:""})
    }
    render(){
        const {classes,messageList ,loginData} = this.props;
        const { userFilerOption ,message,receiverid } = this.state;
        var myuserId = loginData !== null ? loginData.id :"";
        return(
            <div >
                { this.props.loading === 100 && (
                    <div className={classNames(classes.overlay, 'flex items-center w-full')}>
                        <CircularProgress className={classes.progress} color="secondary"  />
                    </div>
                )}
                <div className={classes.mainmsgboard}>
                    { userFilerOption ? (<UserFiler/>):(<div/>)}
                    <div className={classes.mainusermsglist} style={userFilerOption ? {width:"calc(100% - 350px)"}:{width:"100%"}}>
                        <div className={classes.messageboard}>
                            <div className={classes.messateitemlist}>

                                {myuserId !== null && myuserId !=="" && messageList !== null && messageList.length > 0 && messageList.map((_msg,_index)=>(
                                    <div key={_index} className={classNames(classes.message , _msg !==null && _msg.senderInfo !==null && this.props.loginData && this.props.loginData !== null && this.props.loginData.id ===  _msg.senderInfo.id ? classes.messageeven : classes.messageodd)}>
                                        <div className={classes.msgaccountname}><AccountCircle/>
                                            <span className={classes.accountname}>
                                                { _msg.senderInfo && _msg.senderInfo !== null && _msg.senderInfo.fname ? _msg.senderInfo.fname +" " +_msg.senderInfo.lname:"" }
                                                </span>
                                        </div>
                                        <div>
                                            {_msg.msgtype ==="msg" ? _msg.msg:""}
                                            {_msg.msgtype !=="msg" ? (<img src={_msg.msg} style={{width: 250,height: 250}}/>):""}
                                        </div>
                                        <div style={{color:"gray",fontSize: 12}}>{_msg.date}</div>
                                    </div>
                                ))}
                                <div style={{ float:"left", clear: "both" }}
                                     ref={(el) => { this.messagesEnd = el; }}>
                                </div>
                            </div>
                        </div>
                        <div className={classes.msgtxtbody}>
                            <TextField
                                label="Message"
                                value={message}
                                variant="outlined"
                                margin="dense"
                                multiline
                                rowsMax={2}
                                className={classes.messagetext}
                                onKeyDown={(e)=>this.keyPress(e,"message")}
                                onChange={(e)=>this.handleChangeFormType(e,"message")}
                            />
                            <input
                                accept="image/*"
                                className={classes.fileinput}
                                id="contained-button-file"
                                name={"file"}
                                type="file"
                                ref={this.inputOpenFileRef}
                                onChange={this.onFileChange}
                            />
                            <IconButton onClick={this.addfile}>
                                <CloudUploadIcon/>
                            </IconButton>
                            {this.state.selectedFile && this.state.selectedFile !== null ? (<Button onClick ={this.clearfile}>Clear</Button>):(<div/>)}

                            <Button variant="contained" color="primary" className={classes.messagesendbtn} endIcon={<SendIcon/>} onClick={this.sendMessageBtn}>
                                Send
                            </Button>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        GetAllUserMessage : Actions.GetAllUserMessage,
        AddNewMessage : Actions.AddNewMessage,
        RemoveUserMessage : Actions.RemoveUserMessage,
        FileUpload : Actions.FileUpload,
    }, dispatch);
}

function mapStateToProps({auth,}) {
    return {
        auth: auth,
        loginData: auth.loginData,
        userData: auth.userData,
        loading: auth.loading,

        //message part
        messageStatus: auth.messageStatus,
        messageList: auth.messageList,
        messageMsg: auth.messageMsg,
        messageItem: auth.messageItem,
        messageRemoveStatus: auth.messageRemoveStatus,
        messageAddStatus: auth.messageAddStatus,
        messageGetAllListStatus: auth.messageGetAllListStatus,


        uploadfileStatus: auth.uploadfileStatus,
        getPaperWork: auth.getPaperWork,
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(MessageLists));