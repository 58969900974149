import React from 'react';
import './App.css';
import Dashboard from './Components/Screens/Dashboard';
function App() {
  return (
    <div className="App"  charset="utf-8">
        <Dashboard  charset="utf-8"/>
    </div>
  );
}

export default App;
