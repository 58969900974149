import React, {Component} from 'react';
import {
    Select,
    Grid,
    Checkbox,
    FormControlLabel,
    TextField,
    Button,  CircularProgress,Paper,

} from '@material-ui/core';

import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import {withRouter} from 'react-router-dom';
// for store
import connect from "react-redux/es/connect/connect";
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Draggable from 'react-draggable';
import * as Actions from '../../../../store/actions';
import Images from '../../../Images';
import Colors from '../../../Colors';
import Slider from "react-slick";
import Menu from '@material-ui/core/Menu';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import ReactTable from "react-table";
import "react-table/react-table.css";
import TrushPaperWork from "./TrushPaperWork";
import PDFfileviewer from "./PDFfileviewer";
import MessageLists from "../Includes/MessageLists";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import moment from "moment/moment";
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';



const hexToRgb = (hex) =>{
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};
const styles = theme => ({
    title: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    acountMenu:{
        marginTop: 30
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: '#0000006b',
        zIndex: 1000,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    progress: {
        margin: 1,
    },
    tableTheadRow:{
        // backgroundColor: 'rgba(' + hexToRgb(theme.palette.primary.main).r + ',' + hexToRgb(theme.palette.primary.main).g + ',' + hexToRgb(theme.palette.primary.main).b +', .2)'
        backgroundColor: theme.palette.primary.main
    },
    tableThEven:{
        backgroundColor: 'rgba(' + hexToRgb(theme.palette.secondary.main).r + ',' + hexToRgb(theme.palette.secondary.main).g + ',' + hexToRgb(theme.palette.secondary.main).b +', .3)'
    },
    tableTdEven:{
        // backgroundColor: 'rgba(' + hexToRgb(theme.palette.secondary.main).r + ',' + hexToRgb(theme.palette.secondary.main).g + ',' + hexToRgb(theme.palette.secondary.main).b +', .1)'
    },
    displaynone:{
        display:"none",
    },
    DialogTitle:{
        backgroundColor: '#3f51b5',
        color: 'white',
        fontWeight: 800,
    },
    closeBtn :{
        position: 'absolute',
        top: 9,
        right: 36,
    },
    formTypeSelect:{
        width:"80%"
    },
    addnewformbtn:{
        marginTop: 25,
        marginBottom:25,
        marginLeft: 25
    },
    formItems:{
        width:"45%",
    },
    Itemsrow:{
        width:"90%",
        marginLeft:"5%",
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"space-between",
        marginTop: 25,
    },
    filenamespan:{
        fontSize: 12,
        fontWeight: 700
    },
    fileinput:{
        display:"none"
    },
    submitbuttons:{
        display:"flex",
        flexDirection:"column",
        // justifyContent:"center",
        // alignItems:"center",
    },
    newDataSelect:{
        width: 250,
        marginLeft: 25,
    }
});


class Employee extends Component {
    constructor(props){
        super(props);
        this.state={
            loginStatus : 100,
            tabIndex: 0,
            paperType: "",
            paperData : null,
            openDialog: false,

            adminLeavel:0,
            adminChangeLevel: 300,
            messageData : null,


            newformstatus : false,
            formname:"",
            formfile:null,
            formtype:"Employer",
            filename:"",

            formItemViewstatus: false,
            formItemData: null,
            formItemId: "",

            newdataset:"client",
            newDataSettxt:"",

            questionId:"",
            questiontxt:"",
            questionDialog:false,
            selectedRow : null,


            pdfformnames : "",
            pdfbytes:"",


            viewuserFormPDFURL :"",
            viewuserFormDialogStatus: false,
        }
        this.inputOpenFileRef = React.createRef();
    }

    componentDidMount(){
        this.CheckAdminStatus();
        this.GetAllCurrentEmployerList();
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevProps.updatePaperworkStatus === 100 && this.props.updatePaperworkStatus >= 200 ){
            this.GetPaperWorkAll();
        }
        if(prevProps.updateEmployerDetailStatus === 100 && this.props.updateEmployerDetailStatus >= 200 ){
            this.GetAllCurrentEmployerList();
        }
        if(prevProps.updateClientActiveStatus === 100 && this.props.updateClientActiveStatus >= 200 ){
            this.GetAllCurrentClientList();
        }
        if(prevProps.addnewformstatus === 100 && this.props.addnewformstatus >= 200 ){
            this.showmessage(this.props.addnewformstatus,this.props.admin.msg);
            this.GetAllFormList();
        }
        if(prevProps.removeformitemstatus === 100 && this.props.removeformitemstatus >= 200 ){
            this.GetAllFormList();
        }
        if(prevProps.updateformmetadatastatus === 100 && this.props.updateformmetadatastatus >= 200 ){
            this.GetAllFormList();
        }
        if(prevProps.getallformstatus === 100 && this.props.getallformstatus >= 200 ){
            this.getFormItemData();
        }
        if(prevProps.addnewDatasetItemStatus === 100 && this.props.addnewDatasetItemStatus >= 200 ){
            this.showmessage(this.props.addnewDatasetItemStatus,this.props.admin.msg);
            this.GetAllFormList();
        }
        if(prevProps.updateFormMetaDataItemFieldsStatus === 100 && this.props.updateFormMetaDataItemFieldsStatus >= 200 ){
            this.GetAllFormList();
        }
        if(prevProps.updateNameValueItemStatus === 100 && this.props.updateNameValueItemStatus >= 200 ){
            this.GetAllFormList();
            this.getAllSubmitedFormList();
        }



    }
    showmessage=(status,msg)=>{
        if(msg ==="") return;
        if(status >200){

            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else if(status ===200){
            toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    GetPaperWorkAll=()=>{
        const {loginData} = this.props;
        if(loginData !== null && loginData.id !==null && loginData.id !==""){
            var data = {userid:loginData.id};
            this.props.GetPaperworksAll(data);
        }

    }
    componentWilUnmount() {

    }
    GetAllCurrentEmployerList=()=>{
        this.props.GetAllCurrentEmployer();
    }
    GetAllCurrentClientList=()=>{
        this.props.GetAllCurrentClientList();
    }
    GetAllFormList=()=>{
        this.props.AdminGetAllFormList();
    }
    handleChangeIndex =(event, index)=>{
        this.setState({tabIndex:index});
        if(index === 1){
            this.GetAllCurrentEmployerList();
        }
        else if(index === 2){
            this.GetAllCurrentClientList();
        }
        else if(index === 3){
            this.GetAllFormList();
        }
        else if(index === 4){
            this.getAllSubmitedFormList();
        }
    }

    CheckAdminStatus = ()=>{
        const { loginData } = this.props;
        if(loginData !== null && loginData.admin !==""){
            this.setState({adminLeavel: parseInt(loginData.admin)});
        }
    }
    viewPaperWork=(data)=>{
        if(data !== null && data.papertype === "trust"){
            this.setState({paperType : "1"});
            this.setState({paperData: data});
            this.setState({openDialog: true});
        }
        else if(data !== null && (data.papertype ==="AD Paperwork" || data.papertype ==="Christmore" || data.papertype ==="Newfelt" || data.papertype ==="Sherwood")){
            this.setState({paperData: data.data });
            this.setState({paperType : "2"});
            this.setState({openDialog: true});
        }
        if(data && data !== null){
            var id = data.id;
            var mydata = {
                value: "200",
                name: "readstatus",
                id : data.id,
            }
            this.props.UpdatePaperWork(mydata);
        }

    }
    removePaper=(data)=>{
        const {adminLeavel, adminChangeLevel} = this.state;
        if(adminLeavel >= adminChangeLevel){
            if(data && data.id !=="" && data.id !== null){
                var id = data.id;
                var data ={id};
                this.props.RemovePaperWork(data);
            }
        }
    }
    viewMessages=(data)=>{
        const {adminLeavel, adminChangeLevel} = this.state;
        if(true){
            if(data && data.id !=="" && data.id !== null){
                var id = data.id;
                var userid = data.userid;
                var papertype = data.papertype;
                var data ={id,userid,papertype};
                this.setState({messageData: data});
            }
        }
        this.ChangeDialgo("100");
    }
    ChangeDialgo=(typeValue)=>{
        if(typeValue !==""){
            this.setState({paperType: typeValue,openDialog: true});
        }

    }
    handleDialogClose=()=>{
        this.setState({openDialog: false});
        this.setState({messageData: null,paperData:null});
    }
    handleFormDialogClose=()=>{
        this.setState({formItemViewstatus:false,formItemData:null,formItemId:""});
    }
    activate=(val, data)=>{
        if(val !== null && data !== null){
            var mydata = {
                value: val,
                name: "active",
                id : data.id,
            }
            this.props.UpdatePaperWork(mydata);
        }

    }
    changeCurrentEMPactive=(data)=>{
        if(data && data !== null){
            var empInfo  = data.empInfo;
            var activeStatus = empInfo && empInfo !== null && empInfo.active ? empInfo.active :"";
            var empID = empInfo && empInfo !== null && empInfo.empID ? empInfo.empID :"";
            if(empID && activeStatus){
                var data ={
                    empID:empID,
                    name:"active",
                    value: activeStatus ==="No" ?"Yes":"No",
                }
                this.props.UpdateEmployerDetailData(data);
            }
            else {

            }

        }
    }
    changeCurrentClientActive=(data)=>{
        if(data && data !== null){
            var empInfo  = data.empInfo;
            var activeStatus = empInfo && empInfo !== null && empInfo.active ? empInfo.active :"";
            var empID = empInfo && empInfo !== null && empInfo.clientID ? empInfo.clientID :"";
            if(empID ){
                var mydata ={
                    clientID:empID,
                    name:"active",
                    value: activeStatus ==="Yes" ?"No":"Yes",
                }
                this.props.UpdateCurrentClientActive(mydata);
            }
        }
    }
    changecurrentemployerstatus=(data)=>{
        if(data && data !== null){
            var empInfo  = data.empInfo;
            var empID = empInfo && empInfo !== null && empInfo.empID ? empInfo.empID :"";
            var currentemp = data && data.currentemp ==="yes" ? false : true;
            var value = currentemp ? moment().format("YYYY-MM-DD") :"0000-00-00";
            var data ={
                empID:empID,
                name :"startdate",
                value:value,
            };
            this.props.UpdateEmployerDetailData(data);
        }
    }
    getFormItemData=()=>{

        const {formItemId,formItemData} = this.state;
        const {formList} = this.props;
        if(formItemId !=="" && formItemData !== null){
            if(formList && formList.length > 0){
                let midformItem = _.filter(formList,item=>item.id === formItemId);
                if(midformItem && midformItem.length ){
                    this.setState({formItemData:midformItem[0]});
                }
            }
        }
    }
    viewForm=(data)=>{
        /**
         * view form data with form meta data
         */
        if(data && data.id){
            this.setState({formItemId: data.id});
            //formItemId
        }
        this.setState({formItemViewstatus: true,formItemData:data});

    }
    removeForm=(data)=>{
        /**
         * remove form from forms
         */
        if(data && data.id){
            let mydata = {
                formid:data.id,
            };
            this.props.AdminRemoveFormItem(mydata);
        }

    }
    addnewForm=()=>{
        /**
         * add new form pdf file with Dialog
         */
        this.setState({newformstatus: true});
    }
    handleCloseAddnewformDialog=()=>{
        this.setState({newformstatus: false});
    }
    handleChangeFormType=(e,name)=>{
        if(name !==""){
            this.setState({[name]:e.target.value});
        }
    }
    onFileChange=async(event)=>{
        this.GetPDFvaluefields(event);
        this.setState({ formfile: event.target.files[0] });
        var filename = event.target.files[0].name;
        this.setState({filename:filename});

    }
    selectFile=()=>{
        this.inputOpenFileRef.current.click()
    }
    GetPDFvaluefields=async(event)=>{
        const file = event.target.files[0];
        const bytes = await this.readFileToArrayBuffer(file);
        const pdfDoc = await PDFDocument.load(bytes);
        const form = pdfDoc.getForm();
        const fields = form.getFields();

        let midformnames = "";
        fields.forEach(field => {
            const type = field.constructor.name;
            const name = field.getName();
            let value = "";
            if(name && name !== null && name !==""){
                let midvalue = form.getTextField(name);
                if(midvalue && midvalue !== null ){
                    midvalue.setText("");
                }
            }
            if( name && name !=="" && name !== null){
                midformnames +=name +"@@@@@";
            }
            // console.log(`${type}: ${name}------${value}`);
        });
        // let mypdfDoc = pdfDoc.save();

        this.setState({pdfformnames: midformnames});
        this.setState({pdfbytes: bytes});

        // console.log(bytes);
        // const pdfBytes = await pdfDoc.save();
        // var pdffile = new Blob([pdfBytes], { type: 'application/pdf' });
        // var fileURL = URL.createObjectURL(pdffile);
        // console.log(fileURL+"===");
        // window.open(fileURL);

    }
    ab2str=(buf)=>{
        return String.fromCharCode.apply(null, new Uint8Array(buf));
    }
    str2ab=(str)=>{
        var buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
        var bufView = new Uint8Array(buf);
        for (var i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return buf;
    }
    AddNewPdffile=async()=>{
        /**
         * submit pdf file ,AddNewAdminForm
         *
         */
        const {formname,formtype,formfile ,pdfformnames,pdfbytes} = this.state;
        const {loginData} = this.props;
        if(formname === null || formname ===""){
            this.showmessage(400,"Please Input Form Name");
        }
        else if(formtype === null || formtype ===""){
            this.showmessage(400,"Please Select Form Type");
        }
        else if(formfile === null){
            this.showmessage(400,"Please Select PDF File");
        }
        else {
            // let uintary = this.ab2str(pdfbytes);

            let uintary = pdfbytes;
            let formData = new FormData();
            formData.append('file',this.state.formfile);
            formData.append('userid',loginData.id);
            formData.append('formname',formname);
            formData.append('type',formtype);
            formData.append('pdfformnames',pdfformnames);
            formData.append('pdfdoc',uintary);


            this.props.AddNewAdminForm(formData);
            this.setState({
                formname:"",
                filename:"",
                formfile:null,
                pdfformnames:"",
                pdfbytes:"",
            })

        }

    }
    removeFormmetadata=(data)=>{
        /**
         * remove form meta data item
         */
        if(data && data.id){
            let middata={
                id:data.id,
            };
            this.props.AdminRemoveFormMetaDataItem(middata);
        }

    }
    changeformmetadata=(e,row)=>{
        let select_val = e.target.value;
        let id = row.id;
        if(select_val && id){
            let mydata = {
                id : id,
                name : select_val,
            }
            this.props.AdminUpdateFormMetadataitem(mydata);
        }
    }
    ChangeNewDataSetValue=(e,id)=>{
        let val = e.target.value;
        let event_id = id;
        if(val !== "" && event_id !== ""){
            this.setState({[`DataSet_${event_id}`]: val});
            // this.setState({dataset: val});
        }
    }
    AddNewDataSetValue=(id)=>{
        let datasetvalue = this.state["DataSet_"+id];
        console.log(datasetvalue);
    }
    addNewDataSet=()=>{
        const {newdataset,newDataSettxt} = this.state;
        if(newdataset && newDataSettxt){
            let data = {
                name : newDataSettxt,
                type : newdataset,
            }
            this.props.AdminAddNewDataSetItem(data);
            this.setState({newDataSettxt: ""});
        }
        else if(newdataset ==="" || newdataset ===null){
            this.showmessage(400,"Please Select Type");
        }
        else if(newDataSettxt ==="" || newDataSettxt ===null){
            this.showmessage(400,"Please Input Data Set Name");
        }

    }
    addNewQuestion=(data)=>{
        /**
         *Add Question with row.original
         */

        if(data && data.id){
            this.setState({questionId : data.id});
            this.setState({questiontxt:data.question});
            this.setState({selectedRow:data});

        }
        this.setState({questionDialog: true});
    }
    handleCloseQuestionDialog=()=>{
        this.setState({questionDialog: false});
        this.setState({selectedRow: null,questionId:"",questiontxt:""});
    }
    AddNewQuestion=()=>{
        /**
         *add new question with id
         */
        const {questiontxt,questionId} = this.state;
        if(questiontxt !== "" && questiontxt !== null && questionId !=="" && questionId !== null){
            let mydata = {
                id : questionId,
                name :"question",
                value : questiontxt
            }
            this.setState({questiontxt: "",questionId:"",questionDialog: false});
            this.props.AdminUpdateFormMetaDataItemsField(mydata);

        }
        else {
            this.setState({questionDialog: false})
        }
         //AdminUpdateFormMetaDataItemsField
    }
    handleChange = (event,id) => {

        let data ={
            id,
            name:"checkbox",
            value:event.target.checked.toString(),
            dbname:"forms"

        }

        if(data && data.id && data.dbname !==""){
            this.props.AdminUpdateItemDatawithNameValue(data);
        }
        //AdminUpdateItemDatawithNameValue
    };
    readFileToArrayBuffer = async (fileData) => {
        return new Promise( (resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(fileData);
            reader.onload = () => {
                const result = reader.result;
                const bytes = new Uint8Array(result);
                resolve(bytes);
            };
        });
    }
    getAllSubmitedFormList = ()=>{
        this.props.AdminGetAllSubmitFormList();
    }
    viewFormUser=async(data)=>{
        //viewuserFormPDFURL
        console.log(data);
        await this.makepdfile(data);
    }
    makepdfile=async(row)=>{
        if(row === null){return}
        console.log(row);
        let bytes = row &&  row.form && row.form.pdfdoc ? row.form.pdfdoc : null;
        let detail = row.detail;
        if(bytes && bytes !=="" && bytes !== null){
            let mybytes = bytes.split(",");
            let resbytes = new Uint8Array(mybytes);
            const pdfDoc = await PDFDocument.load(resbytes);
            const form = pdfDoc.getForm();
            const fields = form.getFields();
            fields.forEach(field => {
                const type = field.constructor.name;
                const name = field.getName();
                if(name && name !== null && name !==""){
                    let midvalue = form.getTextField(name);
                    if(midvalue && midvalue !== null ){
                        let midmetaData = _.filter(detail,item=>item.fieldname===name);
                        let my_val ="";
                        if(midmetaData && midmetaData.length > 0){
                            my_val = midmetaData[0].value;
                        }
                        midvalue.setText(my_val);
                    }
                }
            });

            const pdfBytes = await pdfDoc.save();
            var pdffile = new Blob([pdfBytes], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(pdffile);
            this.setState({viewuserFormPDFURL:fileURL});
            window.open(fileURL);

        }
    }
    removeUserForm=(data)=>{

    }
    changeuserformstatus=(e,data)=>{
        let val = e.target.value;
        if(data && data !==null){
            let id = data.id;
            if(id){
                let data = {
                    id:id,
                    name:"status",
                    value:val,
                    dbname:"formstatus"
                }
                this.props.AdminUpdateItemDatawithNameValue(data);
            }
        }
    }
    render(){
        const {classes ,allPaperwork ,currentemployerlist,currentclientlist ,formList,submitedFormsList} = this.props;
        const {tabIndex,openDialog ,paperType ,paperData ,adminLeavel,adminChangeLevel,messageData ,formItemViewstatus ,formItemData ,newformstatus ,formname,formfile,formtype,filename,newdataset,newDataSettxt,selectedRow} = this.state;
        return(
            <div >
                <ToastContainer/>
                <Paper square>
                    <Tabs
                        value={tabIndex}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleChangeIndex}
                        aria-label="disabled tabs example"
                    >
                        <Tab label="Paperwork" />
                        <Tab label="Cureent Employers" />
                        <Tab label="Cureent Clients" />
                        <Tab label="Forms" />
                        <Tab label="View Submited Forms" />
                    </Tabs>
                </Paper>
                <div>
                    {tabIndex === 0 ? (
                        <ReactTable
                            data ={allPaperwork !== null ? allPaperwork :[]}
                            minRows = {0}
                            getTheadGroupProps={(state, rowInfo, column, instance) => {
                                return {
                                    style: {
                                        // padding: "10px 10px",
                                        fontSize: 16,
                                        fontWeight: 700
                                    },
                                }
                            }}
                            getTheadGroupThProps={(state, rowInfo, column, instance) => {
                                return {
                                    style: {
                                        // padding: "10px 10px",
                                        fontSize: 18,
                                        fontWeight: 700,
                                    },
                                    className: classNames("flex items-center justify-start")
                                }
                            }}
                            getTheadThProps={(state, rowInfo, column, instance) => {
                                let border = '1px solid rgba(255,255,255,.6)';
                                if (column.Header === 'Actions') border = 'none';
                                return {
                                    style: {
                                        fontSize: '18px',
                                        height: 50,
                                        // fontFamily: 'Muli,Roboto,"Helvetica",Arial,sans-serif',
                                        fontWeight: 400,
                                        lineHeight: 1.75,
                                        color: 'white',
                                        borderRight: border
                                    },
                                }
                            }}
                            getTheadProps={(state, rowInfo, column, instance) => {
                                return {
                                    style: {
                                        fontSize: 13,
                                    },
                                    className: classes.tableTheadRow
                                }
                            }}
                            getTdProps={(state, rowInfo, column, instance) => {
                                return {
                                    style: {
                                        textAlign: 'center',
                                        flexDirection: 'row',
                                        fontSize: 15,
                                        padding: "0",
                                        height: 50,
                                        paddingTop: 12,
                                    },
                                }
                            }}

                            columns={[
                                {
                                    columns: [
                                        {
                                            Header: "No",
                                            accessor: "name",
                                            filterAll: true,
                                            width: '10%',
                                            className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                            Cell: row =>{
                                                return(<div>{row.index +1}</div>)
                                            }
                                        },

                                        {
                                            Header: "User Name",
                                            accessor: "email",
                                            filterAll: true,
                                            width: '20%',
                                            className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                            Cell: row =>{
                                                return(<div style={{color:"blue",fontWeight:800}}>
                                                    {row.original.userinfo && row.original.userinfo !== null && row.original.userinfo.fname  !== null && row.original.userinfo.fname && row.original.userinfo.fname !=="" ? row.original.userinfo.fname +" ":""}
                                                    {row.original.userinfo && row.original.userinfo !== null && row.original.userinfo.lname !== null && row.original.userinfo.lname && row.original.userinfo.lname !=="" ? row.original.userinfo.lname:" "}
                                                    </div>)
                                            },
                                        },

                                        {
                                            Header: "Paperwork Type",
                                            accessor: "date",
                                            width: '10%',
                                            className: classNames("flex items-center  justify-start p-12-impor"),
                                            Cell: row =>{
                                                return(<div style={{color:row.original.papertype ==="AD Paperwork"?"#de4e4e": "blue",fontWeight: 600}}>{row.original.papertype==="trust" ? "Trust":row.original.papertype}</div>)
                                            },
                                        },
                                        {
                                            Header: "User Type",
                                            accessor: "date",
                                            width: '10%',
                                            className: classNames("flex items-center  justify-start p-12-impor"),
                                            Cell: row =>{
                                                return(<div>{row.original.userinfo && row.original.userinfo !== null && row.original.userinfo.admin ==="" ? <span style={{color:"blue",fontWeight:600}}>{row.original.userinfo && row.original.userinfo !== null && row.original.userinfo.type}</span>:row.original.userinfo && row.original.userinfo !== null && row.original.userinfo.admin !=="" ? <span style={{color:"red",fontWeight:800}}>admin</span>:<span style={{color:"yellow",fontWeight:800}}>Unknown</span>}</div>)
                                            },
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "date",
                                            width: '10%',
                                            className: classNames("flex items-center  justify-start p-12-impor"),
                                            Cell: row =>{
                                                return(<div>
                                                    {row.original.active ==="yes" ? <Button style={{color:"green",fontWeight:600}} onClick={()=>this.activate("no",row.original)}>Active</Button>:<Button style={{color:"red",fontWeight:600}} onClick={()=>this.activate("yes",row.original)}>Inactive</Button> }


                                                    </div>)
                                            },
                                        },
                                        {
                                            Header: "Check Status",
                                            accessor: "readstatus",
                                            width: '10%',
                                            className: classNames("flex items-center  justify-start p-12-impor"),
                                            Cell: row =>{
                                                return(<div>
                                                    {row.original.readstatus ==="200" ? <span style={{color:"green",fontWeight:600}} >Checked</span>:<span style={{color:"red",fontWeight:600}} >UnChecked</span> }
                                                </div>)
                                            },
                                        },
                                        {
                                            Header: "View",
                                            accessor: "volume",
                                            width: '10%',
                                            className: classNames(classes.tableTdEven, "flex items-center  justify-end p-12-impor "),
                                            Cell: row=>{
                                                return (
                                                    <Button variant="contained"  style={{width: "90%",marginLeft:"5%"}} onClick={()=>this.viewPaperWork(row.original)}>View</Button>
                                                )
                                            }

                                        },
                                        {
                                            Header: "Messages",
                                            accessor: "volume",
                                            width: '10%',
                                            className: classNames(classes.tableTdEven, "flex items-center  justify-end p-12-impor"),
                                            Cell: row=>{
                                                return (
                                                    <Button variant="contained" disabled={ adminLeavel !=="" && adminLeavel !==null ? false : true} style={{width: "90%",marginLeft:"5%"}} onClick={()=>this.viewMessages(row.original)}>Messages</Button>
                                                )
                                            }
                                        },
                                        {
                                            Header: "Action",
                                            accessor: "volume",
                                            width: '10%',
                                            className: classNames(classes.tableTdEven, "flex items-center  justify-end p-12-impor"),
                                            Cell: row=>{
                                                return (
                                                    <Button variant="contained"  disabled={adminLeavel >= adminChangeLevel ? false: true} style={{width: "90%",marginLeft:"5%",}} onClick={()=>this.removePaper(row.original)}>
                                                        Remove
                                                    </Button>
                                                )
                                            }
                                        },
                                    ]
                                },
                            ]}
                            defaultPageSize={10}
                            className={classNames( "-striped -highlight")}
                            totalRecords = {allPaperwork !== null ? allPaperwork.length:0}
                            style={{
                                height: '100%',
                            }}
                        />
                    ): (<div/>)}
                    {tabIndex === 1 ? (
                        <ReactTable
                            data ={currentemployerlist}
                            minRows = {0}
                            getTheadGroupProps={(state, rowInfo, column, instance) => {
                                return {
                                    style: {
                                        // padding: "10px 10px",
                                        fontSize: 16,
                                        fontWeight: 700
                                    },
                                }
                            }}
                            getTheadGroupThProps={(state, rowInfo, column, instance) => {
                                return {
                                    style: {
                                        // padding: "10px 10px",
                                        fontSize: 18,
                                        fontWeight: 700,
                                    },
                                    className: classNames("flex items-center justify-start")
                                }
                            }}
                            getTheadThProps={(state, rowInfo, column, instance) => {
                                let border = '1px solid rgba(255,255,255,.6)';
                                if (column.Header === 'Actions') border = 'none';
                                return {
                                    style: {
                                        fontSize: '18px',
                                        height: 50,
                                        // fontFamily: 'Muli,Roboto,"Helvetica",Arial,sans-serif',
                                        fontWeight: 400,
                                        lineHeight: 1.75,
                                        color: 'white',
                                        borderRight: border
                                    },
                                }
                            }}
                            getTheadProps={(state, rowInfo, column, instance) => {
                                return {
                                    style: {
                                        fontSize: 13,
                                    },
                                    className: classes.tableTheadRow
                                }
                            }}
                            getTdProps={(state, rowInfo, column, instance) => {
                                return {
                                    style: {
                                        textAlign: 'center',
                                        flexDirection: 'row',
                                        fontSize: 15,
                                        padding: "0",
                                        height: 50,
                                        paddingTop: 12,
                                    },
                                }
                            }}

                            columns={[
                                {
                                    columns: [
                                        {
                                            Header: "No",
                                            accessor: "name",
                                            filterAll: true,
                                            width: '10%',
                                            className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                            Cell: row =>{
                                                return(<div>{row.index +1}</div>)
                                            }
                                        },

                                        {
                                            Header: "User Name",
                                            accessor: "email",
                                            filterAll: true,
                                            width: '20%',
                                            className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                            Cell: row =>{
                                                return(<div style={{color:"blue",fontWeight:800}}>
                                                    {row.original && row.original !== null && row.original.fname  !== null && row.original.fname && row.original.fname !=="" ? row.original.fname +" ":""}
                                                    {row.original && row.original !== null && row.original.lname !== null && row.original.lname && row.original.lname !=="" ? row.original.lname:" "}
                                                </div>)
                                            },
                                        },


                                        {
                                            Header: "User Type",
                                            accessor: "date",
                                            width: '10%',
                                            className: classNames("flex items-center  justify-start p-12-impor"),
                                            Cell: row =>{
                                                return(<div>{row.original && row.original !== null && row.original.admin ==="" ? <span style={{color:"blue",fontWeight:600}}>{row.original && row.original !== null && row.original.type}</span>:row.original && row.original !== null && row.original.admin !=="" ? <span style={{color:"red",fontWeight:800}}>admin</span>:<span style={{color:"yellow",fontWeight:800}}>Unknown</span>}</div>)
                                            },
                                        },
                                        {
                                            Header: "Active",
                                            accessor: "date",
                                            width: '10%',
                                            className: classNames("flex items-center  justify-start p-12-impor"),
                                            Cell: row =>{
                                                return(<div>{row.original.empInfo && row.original.empInfo !== null && row.original.empInfo.active !=="" ? <Button style={{color:row.original.empInfo.active==="No" ?"red":"green",fontWeight:600}} onClick={()=>this.changeCurrentEMPactive(row.original)}>{row.original.empInfo.active}</Button>:<span/>}</div>)
                                            },
                                        },

                                        {
                                            Header: "Current Employer",
                                            accessor: "currentemp",
                                            width: '10%',
                                            className: classNames("flex items-center  justify-start p-12-impor"),
                                            Cell: row =>{
                                                return(<div>
                                                    {row.original.currentemp ==="no" ? <Button style={{color:"red",fontWeight:600}} onClick={()=>this.changecurrentemployerstatus(row.original )}>No</Button>:<Button style={{color:"green",fontWeight:600}} onClick={()=>this.changecurrentemployerstatus(row.original )}>Yes</Button> }
                                                </div>)
                                            },
                                        },
                                    ]
                                },
                            ]}
                            defaultPageSize={10}
                            className={classNames( "-striped -highlight")}
                            totalRecords = {currentemployerlist && currentemployerlist !== null ? currentemployerlist.length:0}
                            style={{
                                height: '100%',
                            }}
                        />
                    ): (<div/>)}
                    {tabIndex === 2 ? (
                        <ReactTable
                            data ={currentclientlist}
                            minRows = {0}
                            getTheadGroupProps={(state, rowInfo, column, instance) => {
                                return {
                                    style: {
                                        // padding: "10px 10px",
                                        fontSize: 16,
                                        fontWeight: 700
                                    },
                                }
                            }}
                            getTheadGroupThProps={(state, rowInfo, column, instance) => {
                                return {
                                    style: {
                                        // padding: "10px 10px",
                                        fontSize: 18,
                                        fontWeight: 700,
                                    },
                                    className: classNames("flex items-center justify-start")
                                }
                            }}
                            getTheadThProps={(state, rowInfo, column, instance) => {
                                let border = '1px solid rgba(255,255,255,.6)';
                                if (column.Header === 'Actions') border = 'none';
                                return {
                                    style: {
                                        fontSize: '18px',
                                        height: 50,
                                        // fontFamily: 'Muli,Roboto,"Helvetica",Arial,sans-serif',
                                        fontWeight: 400,
                                        lineHeight: 1.75,
                                        color: 'white',
                                        borderRight: border
                                    },
                                }
                            }}
                            getTheadProps={(state, rowInfo, column, instance) => {
                                return {
                                    style: {
                                        fontSize: 13,
                                    },
                                    className: classes.tableTheadRow
                                }
                            }}
                            getTdProps={(state, rowInfo, column, instance) => {
                                return {
                                    style: {
                                        textAlign: 'center',
                                        flexDirection: 'row',
                                        fontSize: 15,
                                        padding: "0",
                                        height: 50,
                                        paddingTop: 12,
                                    },
                                }
                            }}

                            columns={[
                                {
                                    columns: [
                                        {
                                            Header: "No",
                                            accessor: "name",
                                            filterAll: true,
                                            width: '10%',
                                            className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                            Cell: row =>{
                                                return(<div>{row.index +1}</div>)
                                            }
                                        },

                                        {
                                            Header: "User Name",
                                            accessor: "email",
                                            filterAll: true,
                                            width: '20%',
                                            className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                            Cell: row =>{
                                                return(<div style={{color:"blue",fontWeight:800}}>
                                                    {row.original && row.original !== null && row.original.fname  !== null && row.original.fname && row.original.fname !=="" ? row.original.fname +" ":""}
                                                    {row.original && row.original !== null && row.original.lname !== null && row.original.lname && row.original.lname !=="" ? row.original.lname:" "}
                                                </div>)
                                            },
                                        },


                                        {
                                            Header: "User Type",
                                            accessor: "date",
                                            width: '10%',
                                            className: classNames("flex items-center  justify-start p-12-impor"),
                                            Cell: row =>{
                                                return(<div>{row.original && row.original !== null && row.original.admin ==="" ? <span style={{color:"blue",fontWeight:600}}>{row.original && row.original !== null && row.original.type}</span>:row.original && row.original !== null && row.original.admin !=="" ? <span style={{color:"red",fontWeight:800}}>admin</span>:<span style={{color:"yellow",fontWeight:800}}>Unknown</span>}</div>)
                                            },
                                        },
                                        {
                                            Header: "Active",
                                            accessor: "date",
                                            width: '10%',
                                            className: classNames("flex items-center  justify-start p-12-impor"),
                                            Cell: row =>{
                                                return(<div>
                                                    {row.original.empInfo && row.original.empInfo !== null && row.original.empInfo.active ==="Yes" ?
                                                        <Button style={{color:row.original.empInfo.active==="Yes" ?"green":"red",fontWeight:600}} onClick={()=>this.changeCurrentClientActive(row.original)}>Yes</Button>:
                                                        <Button style={{color:row.original.empInfo.active==="No" || row.original.empInfo.active==="" ?"red":"green",fontWeight:600}} onClick={()=>this.changeCurrentClientActive(row.original)}>No</Button>
                                                    }</div>)
                                            },
                                        },

                                        {
                                            Header: "Current Client",
                                            accessor: "currentemp",
                                            width: '10%',
                                            className: classNames("flex items-center  justify-start p-12-impor"),
                                            Cell: row =>{
                                                return(<div>
                                                    {row.original.currentemp ==="no" ? <Button style={{color:"red",fontWeight:600}} onClick={()=>this.changecurrentemployerstatus(row.original )}>No</Button>:<Button style={{color:"green",fontWeight:600}} onClick={()=>this.changecurrentemployerstatus(row.original )}>Yes</Button> }
                                                </div>)
                                            },
                                        },
                                    ]
                                },
                            ]}
                            defaultPageSize={10}
                            className={classNames( "-striped -highlight")}
                            totalRecords = {currentclientlist && currentclientlist !== null ? currentclientlist.length:0}
                            style={{
                                height: '100%',
                            }}
                        />
                    ): (<div/>)}
                    {tabIndex === 3 ?(
                        <div>
                            <div><Button variant="contained" color="primary" onClick ={()=>this.addnewForm()} className={classes.addnewformbtn}>Add New Form</Button></div>
                            <ReactTable
                                data ={formList}
                                minRows = {0}
                                getTheadGroupProps={(state, rowInfo, column, instance) => {
                                    return {
                                        style: {
                                            // padding: "10px 10px",
                                            fontSize: 16,
                                            fontWeight: 700
                                        },
                                    }
                                }}
                                getTheadGroupThProps={(state, rowInfo, column, instance) => {
                                    return {
                                        style: {
                                            // padding: "10px 10px",
                                            fontSize: 18,
                                            fontWeight: 700,
                                        },
                                        className: classNames("flex items-center justify-start")
                                    }
                                }}
                                getTheadThProps={(state, rowInfo, column, instance) => {
                                    let border = '1px solid rgba(255,255,255,.6)';
                                    if (column.Header === 'Actions') border = 'none';
                                    return {
                                        style: {
                                            fontSize: '18px',
                                            height: 50,
                                            // fontFamily: 'Muli,Roboto,"Helvetica",Arial,sans-serif',
                                            fontWeight: 400,
                                            lineHeight: 1.75,
                                            color: 'white',
                                            borderRight: border
                                        },
                                    }
                                }}
                                getTheadProps={(state, rowInfo, column, instance) => {
                                    return {
                                        style: {
                                            fontSize: 13,
                                        },
                                        className: classes.tableTheadRow
                                    }
                                }}
                                getTdProps={(state, rowInfo, column, instance) => {
                                    return {
                                        style: {
                                            textAlign: 'center',
                                            flexDirection: 'row',
                                            fontSize: 15,
                                            padding: "0",
                                            height: 50,
                                            paddingTop: 12,
                                        },
                                    }
                                }}

                                columns={[
                                    {
                                        columns: [
                                            {
                                                Header: "No",
                                                accessor: "name",
                                                filterAll: true,
                                                width: '10%',
                                                className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                                Cell: row =>{
                                                    return(<div>{row.index +1}</div>)
                                                }
                                            },

                                            {
                                                Header: "Form Name",
                                                accessor: "name",
                                                filterAll: true,
                                                width: '20%',
                                                className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                            },
                                            {
                                                Header: "User Name",
                                                accessor: "name",
                                                filterAll: true,
                                                width: '20%',
                                                className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                                Cell: row=>{
                                                    return (<div>{row.original && row.original.userInfo && row.original.userInfo !== null ? row.original.userInfo.fname + " " +row.original.userInfo.lname :""}</div>)
                                                }
                                            },

                                            {
                                                Header: "View",
                                                accessor: "url",
                                                width: '10%',
                                                className: classNames("flex items-center  justify-start p-12-impor"),
                                                Cell: row =>{
                                                    return(<div><a href={row.original !== null && row.original.url ? row.original.url:""} target="_blank">{row.original.name}</a></div>)
                                                },
                                            },
                                            {
                                                Header: "Type",
                                                accessor: "type",
                                                width: '10%',
                                                className: classNames("flex items-center  justify-start p-12-impor"),
                                            },
                                            // {
                                            //     Header: "Trigger",
                                            //     accessor: "checkbox",
                                            //     width: '10%',
                                            //     className: classNames("flex items-center  justify-start p-12-impor"),
                                            //     Cell: row=>{
                                            //         return (
                                            //             <div>
                                            //                 <Checkbox
                                            //                     checked={row.original.checkbox === "true" ? true : false}
                                            //                     color="primary"
                                            //                     inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            //                     onChange={(e)=>this.handleChange(e,row.original.id)}
                                            //                 />
                                            //             </div>
                                            //         )
                                            //     }
                                            // },
                                            {
                                                Header: "View",
                                                accessor: "created",
                                                width: '10%',
                                                className: classNames("flex items-center  justify-start p-12-impor"),
                                                Cell: row =>{
                                                    return(<div><Button variant="contained" onClick={()=>this.viewForm(row.original)}>View</Button></div>)
                                                },
                                            },

                                            {
                                                Header: "Remove",
                                                accessor: "created",
                                                width: '10%',
                                                className: classNames("flex items-center  justify-start p-12-impor"),
                                                Cell: row =>{
                                                    return(<div><Button variant="contained" onClick={()=>this.removeForm(row.original)}>Remove</Button></div>)
                                                },
                                            },
                                        ]
                                    },
                                ]}
                                defaultPageSize={10}
                                className={classNames( "-striped -highlight")}
                                totalRecords = {formList && formList !== null ? formList.length:0}
                                style={{
                                    height: '100%',
                                }}
                            />
                        </div>
                    ):(<div/>)}
                    {tabIndex === 4 ?(
                        <div>

                            <ReactTable
                                data ={submitedFormsList}
                                minRows = {0}
                                getTheadGroupProps={(state, rowInfo, column, instance) => {
                                    return {
                                        style: {
                                            // padding: "10px 10px",
                                            fontSize: 16,
                                            fontWeight: 700
                                        },
                                    }
                                }}
                                getTheadGroupThProps={(state, rowInfo, column, instance) => {
                                    return {
                                        style: {
                                            // padding: "10px 10px",
                                            fontSize: 18,
                                            fontWeight: 700,
                                        },
                                        className: classNames("flex items-center justify-start")
                                    }
                                }}
                                getTheadThProps={(state, rowInfo, column, instance) => {
                                    let border = '1px solid rgba(255,255,255,.6)';
                                    if (column.Header === 'Actions') border = 'none';
                                    return {
                                        style: {
                                            fontSize: '18px',
                                            height: 50,
                                            // fontFamily: 'Muli,Roboto,"Helvetica",Arial,sans-serif',
                                            fontWeight: 400,
                                            lineHeight: 1.75,
                                            color: 'white',
                                            borderRight: border
                                        },
                                    }
                                }}
                                getTheadProps={(state, rowInfo, column, instance) => {
                                    return {
                                        style: {
                                            fontSize: 13,
                                        },
                                        className: classes.tableTheadRow
                                    }
                                }}
                                getTdProps={(state, rowInfo, column, instance) => {
                                    return {
                                        style: {
                                            textAlign: 'center',
                                            flexDirection: 'row',
                                            fontSize: 15,
                                            padding: "0",
                                            height: 50,
                                            paddingTop: 12,
                                        },
                                    }
                                }}

                                columns={[
                                    {
                                        columns: [
                                            {
                                                Header: "No",
                                                accessor: "name",
                                                filterAll: true,
                                                width: '10%',
                                                className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                                Cell: row =>{
                                                    return(<div>{row.index +1}</div>)
                                                }
                                            },

                                            {
                                                Header: "Form Name",
                                                accessor: "name",
                                                filterAll: true,
                                                width: '10%',
                                                className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                                Cell:row=>{
                                                    return (<div>{row.original.form && row.original.form.name ? row.original.form.name :""}</div>)
                                                }
                                            },
                                            {
                                                Header: "User Name",
                                                accessor: "name",
                                                filterAll: true,
                                                width: '10%',
                                                className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                                Cell: row=>{
                                                    return (<div>{row.original && row.original.userinfo && row.original.userinfo !== null ? row.original.userinfo.fname + " " +row.original.userinfo.lname :""}</div>)
                                                }
                                            },
                                            {
                                                Header: "Type",
                                                accessor: "name",
                                                filterAll: true,
                                                width: '10%',
                                                className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                                Cell: row=>{
                                                    return (<div>{row.original && row.original.userinfo && row.original.userinfo !== null ? row.original.userinfo.type:""}</div>)
                                                }
                                            },

                                            {
                                                Header: "View Form",
                                                accessor: "url",
                                                width: '10%',
                                                className: classNames("flex items-center  justify-start p-12-impor"),
                                                Cell: row =>{
                                                    return(<div><a href={row.original !== null && row.original.form && row.original.form !== null && row.original.form.url ? row.original.form.url:""} target="_blank">{row.original.form.name}</a></div>)
                                                },
                                            },


                                            {
                                                Header: "View User Form",
                                                accessor: "created",
                                                width: '10%',
                                                className: classNames("flex items-center  justify-start p-12-impor"),
                                                Cell: row =>{
                                                    return(<div><Button variant="contained" onClick={()=>this.viewFormUser(row.original)}>View User Form</Button></div>)
                                                },
                                            },
                                            {
                                                Header: "Status",
                                                accessor: "status",
                                                width: '10%',
                                                className: classNames("flex items-center  justify-start p-12-impor"),
                                                Cell: row =>{
                                                    return(<div>
                                                        <select name="" id="" value={row.original.status} onChange={(e)=>this.changeuserformstatus(e,row.original)}>
                                                            <option value={"100"}>Waiting</option>
                                                            <option value={"200"}>Approved</option>
                                                            <option value={"400"}>Declined</option>
                                                        </select>
                                                    </div>)
                                                },
                                            },
                                            {
                                                Header: "Remove",
                                                accessor: "created",
                                                width: '10%',
                                                className: classNames("flex items-center  justify-start p-12-impor"),
                                                Cell: row =>{
                                                    return(<div><Button variant="contained" onClick={()=>this.removeUserForm(row.original)}>Remove</Button></div>)
                                                },
                                            },
                                        ]
                                    },
                                ]}
                                defaultPageSize={10}
                                className={classNames( "-striped -highlight")}
                                totalRecords = {submitedFormsList && submitedFormsList !== null ? submitedFormsList.length:0}
                                style={{
                                    height: '100%',
                                }}
                            />
                        </div>
                    ):(<div/>)}
                </div>


                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={openDialog}
                    fullScreen={false}
                    onClose={this.handleDialogClose}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title" className={classes.DialogTitle}>
                        {paperType ==="1" || paperType ==="2" || paperType === "3" || paperType === "4" || paperType ==="5" ? "Paperwork Detail Viewer":""}
                        {paperType ==="100" ? "Messages":""}

                        <IconButton edge="start" color="inherit" aria-label="close" className={classes.closeBtn} onClick ={this.handleDialogClose}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={paperType ==="100"  ?{overflowY:"hidden"}:{}}>
                        {paperType ==="1" ? (<TrushPaperWork getPropsData={paperData}/>):(<div/>)}
                        {paperType ==="2" ? (<PDFfileviewer getPropsData={paperData}/>):(<div/>)}
                        {paperType ==="100" ? (<MessageLists messageData={messageData}/>):(<div/>) }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={formItemViewstatus}
                    fullScreen={false}
                    onClose={this.handleFormDialogClose}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title" className={classes.DialogTitle}>
                        Form Detail Viewer
                        <IconButton edge="start" color="inherit" aria-label="close" className={classes.closeBtn} onClick ={this.handleFormDialogClose}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={paperType ==="100"  ?{overflowY:"hidden"}:{}}>
                        {formItemData && formItemData.formdata ?  (
                            <div>
                                <span style={{fontSize: 25, fontWeight: 600}}>Found {formItemData && formItemData.formdata ? formItemData.formdata.length : 0} Fields</span>
                                <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                    <TextField
                                        id="standard-select-currency"
                                        select
                                        label="Please Select Type"
                                        value={newdataset}
                                        onChange={(e)=>this.handleChangeFormType(e,"newdataset")}
                                        variant="outlined"
                                        margin="dense"
                                        InputProps={{
                                            classes: {
                                                input: classes.inputformTypeSelect,
                                            },
                                        }}
                                        className={classes.newDataSelect}
                                    >
                                        <MenuItem value={"client"} className={classes.menuitemformTypeSelect}>
                                            client
                                        </MenuItem>
                                        <MenuItem value={"employer"} className={classes.menuitemformTypeSelect}>
                                            employer
                                        </MenuItem>
                                    </TextField>
                                    <TextField
                                        label="New Data Set Name"
                                        value={newDataSettxt}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.newDataSelect}
                                        onChange={(e)=>this.handleChangeFormType(e,"newDataSettxt")}
                                    />
                                    <Button variant="contained" onClick ={this.addNewDataSet} style={{marginLeft: 25,height: 35}}>Add</Button>

                                </div>

                                <ReactTable
                                    data ={formItemData.formdata && formItemData.formdata.length > 0 ? formItemData.formdata :[]}
                                    minRows = {0}
                                    getTheadGroupProps={(state, rowInfo, column, instance) => {
                                        return {
                                            style: {
                                                // padding: "10px 10px",
                                                fontSize: 16,
                                                fontWeight: 700
                                            },
                                        }
                                    }}
                                    getTheadGroupThProps={(state, rowInfo, column, instance) => {
                                        return {
                                            style: {
                                                // padding: "10px 10px",
                                                fontSize: 18,
                                                fontWeight: 700,
                                            },
                                            className: classNames("flex items-center justify-start")
                                        }
                                    }}
                                    getTheadThProps={(state, rowInfo, column, instance) => {
                                        let border = '1px solid rgba(255,255,255,.6)';
                                        if (column.Header === 'Actions') border = 'none';
                                        return {
                                            style: {
                                                fontSize: '18px',
                                                height: 50,
                                                // fontFamily: 'Muli,Roboto,"Helvetica",Arial,sans-serif',
                                                fontWeight: 400,
                                                lineHeight: 1.75,
                                                color: 'white',
                                                borderRight: border
                                            },
                                        }
                                    }}
                                    getTheadProps={(state, rowInfo, column, instance) => {
                                        return {
                                            style: {
                                                fontSize: 13,
                                            },
                                            className: classes.tableTheadRow
                                        }
                                    }}
                                    getTdProps={(state, rowInfo, column, instance) => {
                                        return {
                                            style: {
                                                textAlign: 'center',
                                                flexDirection: 'row',
                                                fontSize: 15,
                                                padding: "0",
                                                height: 50,
                                                paddingTop: 12,
                                            },
                                        }
                                    }}

                                    columns={[
                                        {
                                            columns: [
                                                {
                                                    Header: "No",
                                                    accessor: "name",
                                                    filterAll: true,
                                                    width: '5%',
                                                    className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                                    Cell: row =>{
                                                        return(<div>{row.index +1}</div>)
                                                    }
                                                },

                                                {
                                                    Header: "Field Name",
                                                    accessor: "fieldname",
                                                    filterAll: true,
                                                    width: '10%',
                                                    className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                                },
                                                {
                                                    Header: "Data Set Name",
                                                    accessor: "name",
                                                    filterAll: true,
                                                    width: '10%',
                                                    className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                                    Cell: row=>{
                                                        return (
                                                            <div>
                                                                <select name="" id="" value={row.original.name} onChange={(e)=>this.changeformmetadata(e,row.original)}>
                                                                    <option value={"New DataSet"}>New DataSet</option>
                                                                    {formItemData && formItemData.fields && formItemData.fields.length ? formItemData.fields.map((item,index)=>(
                                                                        <option value={item.name} key={index}>{item.name}</option>
                                                                    )):(<div/>)}
                                                                </select>
                                                            </div>
                                                        )
                                                    }
                                                },
                                                // {
                                                //     Header: "New Name",
                                                //     accessor: "name",
                                                //     filterAll: true,
                                                //     width: '10%',
                                                //     className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                                //     Cell: row=>{
                                                //         return (
                                                //             <div style={{width:"100%"}}>
                                                //                 {/*<input type="text" onChange={(e)=>this.ChangeNewDataSetValue(e,row.original.id)}*/}
                                                //                        {/*// value={this.state["DataSet_"+row.original.id] ? this.state["DataSet_"+row.original.id] :""}*/}
                                                //                 {/*/>*/}
                                                //                 <TextField
                                                //                     label="First Name"
                                                //                     // value={this.state["DataSet_"+row.original.id] ? this.state["DataSet_"+row.original.id] :""}
                                                //                     variant="outlined"
                                                //                     margin="dense"
                                                //                     className={classes.formTypeSelect}
                                                //                     onChange={(e)=>this.ChangeNewDataSetValue(e,row.original.id)}
                                                //                 />
                                                //                 <Button onClick={()=>this.AddNewDataSetValue(row.original.id)}>Add</Button>
                                                //             </div>
                                                //         )
                                                //     }
                                                // },
                                                {
                                                    Header: "Type",
                                                    accessor: "type",
                                                    width: '10%',
                                                    className: classNames("flex items-center  justify-start p-12-impor"),
                                                },
                                                {
                                                    Header: "Question",
                                                    accessor: "question",
                                                    width: '10%',
                                                    className: classNames("flex items-center  justify-start p-12-impor"),
                                                    Cell: row =>{
                                                        return(
                                                            <div>
                                                            {row.original.question}
                                                            {/*<Button  variant="contained" onClick = {()=>this.addNewQuestion(row.original)}>Add</Button>*/}
                                                            </div>
                                                        )
                                                    },
                                                },
                                                {
                                                    Header: "Update Question",
                                                    accessor: "created",
                                                    width: '10%',
                                                    className: classNames("flex items-center  justify-start p-12-impor"),
                                                    Cell: row =>{
                                                        return(<div><Button  variant="contained" onClick = {()=>this.addNewQuestion(row.original)}>Update</Button></div>)
                                                    },
                                                },
                                                {
                                                    Header: "Remove",
                                                    accessor: "created",
                                                    width: '10%',
                                                    className: classNames("flex items-center  justify-start p-12-impor"),
                                                    Cell: row =>{
                                                        return(<div><Button variant="contained" onClick={()=>this.removeFormmetadata(row.original)}>Remove</Button></div>)
                                                    },
                                                },
                                            ]
                                        },
                                    ]}
                                    defaultPageSize={10}
                                    className={classNames( "-striped -highlight")}
                                    totalRecords = {formItemData && formItemData.formdata !== null ? formItemData.formdata.length:0}
                                    style={{
                                        height: '100%',
                                    }}
                                />
                            </div>
                        ):(<div/>)}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleFormDialogClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={newformstatus}
                    fullScreen={false}
                    onClose={this.handleCloseAddnewformDialog}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title" className={classes.DialogTitle}>
                        Add New Form
                        <IconButton edge="start" color="inherit" aria-label="close" className={classes.closeBtn} onClick ={this.handleCloseAddnewformDialog}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={paperType ==="100"  ?{overflowY:"hidden"}:{}}>
                        <div className={classes.Itemsrow}>
                            <TextField
                                label="Form Name"
                                value={formname}
                                variant="outlined"
                                margin="dense"
                                className={classes.formItems}
                                onChange={(e)=>this.handleChangeFormType(e,"formname")}
                            />


                            <TextField
                                id="standard-select-currency"
                                select
                                label="Please Select Type"
                                value={formtype}
                                onChange={(e)=>this.handleChangeFormType(e,"formtype")}
                                variant="outlined"
                                margin="dense"
                                InputProps={{
                                    classes: {
                                        input: classes.inputformTypeSelect,
                                    },
                                }}
                                className={classes.formItems}
                            >
                                <MenuItem  value={"Client"} className={classes.menuitemformTypeSelect}>
                                    Client
                                </MenuItem>
                                <MenuItem  value={"Employer"} className={classes.menuitemformTypeSelect}>
                                    Employer
                                </MenuItem>
                            </TextField>
                        </div>
                        <div className={classes.Itemsrow}>
                            <div className={classes.submitbuttons}>
                                <span className={classes.filenamespan}>{filename}</span>
                                <input
                                    accept=".pdf"
                                    className={classes.fileinput}
                                    id="contained-button-file"
                                    name={"file"}
                                    type="file"
                                    ref={this.inputOpenFileRef}
                                    onChange={this.onFileChange}
                                />
                                <Button onClick={this.selectFile} variant="contained">Choose File</Button>
                            </div>
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.AddNewPdffile} color="primary" variant="contained">
                            Add
                        </Button>
                        <Button onClick={this.handleCloseAddnewformDialog} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={this.state.questionDialog}
                    fullScreen={false}
                    onClose={this.handleCloseQuestionDialog}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title" className={classes.DialogTitle}>
                        Update Question <span style={{    color: '#00ff7e',fontWeight: 800}}>({selectedRow && selectedRow.fieldname ? selectedRow.fieldname :""})</span>
                        <IconButton edge="start" color="inherit" aria-label="close" className={classes.closeBtn} onClick ={this.handleCloseQuestionDialog}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={paperType ==="100"  ?{overflowY:"hidden"}:{}}>
                        <TextField
                            label="Question"
                            multiline
                            value={this.state.questiontxt}
                            variant="outlined"
                            margin="dense"
                            rowsMax={10}
                            rows ={4}
                            className={classes.formTypeSelect}
                            onChange={(e)=>this.handleChangeFormType(e,"questiontxt")}
                        />

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.AddNewQuestion} color="primary" variant="contained">
                            Update
                        </Button>
                        <Button onClick={this.handleCloseQuestionDialog} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        UpdatePaperWork :Actions.UpdatePaperWork,
        RemovePaperWork :Actions.RemovePaperWork,
        GetPaperworksAll : Actions.GetPaperworksAll,
        GetAllCurrentEmployer : Actions.GetAllCurrentEmployer,
        UpdateEmployerDetailData : Actions.UpdateEmployerDetailData,
        GetAllCurrentClientList : Actions.GetAllCurrentClientList,
        UpdateCurrentClientActive : Actions.UpdateCurrentClientActive,
        AdminGetAllFormList : Actions.AdminGetAllFormList,
        AdminRemoveFormItem : Actions.AdminRemoveFormItem,
        AddNewAdminForm : Actions.AddNewAdminForm,
        AdminRemoveFormMetaDataItem : Actions.AdminRemoveFormMetaDataItem,
        AdminUpdateFormMetadataitem : Actions.AdminUpdateFormMetadataitem,
        AdminAddNewDataSetItem : Actions.AdminAddNewDataSetItem,
        AdminUpdateFormMetaDataItemsField : Actions.AdminUpdateFormMetaDataItemsField,
        AdminUpdateItemDatawithNameValue : Actions.AdminUpdateItemDatawithNameValue,
        AdminGetAllSubmitFormList : Actions.AdminGetAllSubmitFormList,
    }, dispatch);
}

function mapStateToProps({auth,admin}) {
    return {
        auth: auth,
        loginData: auth.loginData,
        userData: auth.userData,
        loading: auth.loading,
        updatePaperworkStatus: auth.updatePaperworkStatus,
        updateEmployerDetailStatus: auth.updateEmployerDetailStatus,
        updateClientActiveStatus: auth.updateClientActiveStatus,

        admin:admin,
        empPaperwork: admin.empPaperwork,
        allPaperwork: admin.allPaperwork,
        currentemployerlist: admin.currentemployerlist,
        currentclientlist: admin.currentclientlist,
        addnewformstatus: admin.addnewformstatus,
        formList: admin.formList,
        removeformitemstatus: admin.removeformitemstatus,
        updateformmetadatastatus: admin.updateformmetadatastatus,
        getallformstatus: admin.getallformstatus,
        addnewDatasetItemStatus: admin.addnewDatasetItemStatus,
        updateFormMetaDataItemFieldsStatus: admin.updateFormMetaDataItemFieldsStatus,
        updateNameValueItemStatus: admin.updateNameValueItemStatus,
        submitedFormsListstatus: admin.submitedFormsListstatus,
        submitedFormsList: admin.submitedFormsList,

    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(Employee));