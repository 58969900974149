import { combineReducers } from 'redux';

import auth from './auth.reducer';
import admin from './admin.reducer';

const createReducer =
    (asyncReducers) =>
        (state, action) =>
            combineReducers({
                admin,
                auth,
                ...asyncReducers
            })(state, action);


export default createReducer;