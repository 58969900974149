import * as Actions from "../actions/";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import {
    ADMIN_ADD_NEW_DATA_SET_ITEM_START,
    ADMIN_ADD_NEW_FORM_START,
    ADMIN_GET_ALL_CURRENT_EMPLOYER_LIST_START,
    ADMIN_GET_ALL_FORM_LIST_START, ADMIN_GET_ALL_SUBMITED_FORM_ITEM_DATA_START,
    ADMIN_GET_ALL_UNCHECKED_PAPER_WORK_SUCCESS,
    ADMIN_REMOVE_FORM_ITEM_START,
    ADMIN_REMOVE_FORM_META_DATA_ITEM_START, ADMIN_UPDATE_FORM_META_DATA_ITEM_FIELDS_START,
    ADMIN_UPDATE_FORM_META_DATA_ITEM_START, ADMIN_UPDATE_ITEM_DATA_WITH_NAME_VALUE_START,
    GET_ALL_CURRENT_CLIENT_LIST_SUCCESS,
    GET_ALL_USERS_LIST_START,
    GET_ALL_USERS_LIST_SUCCESS,
    REMOVE_USER_ACCOUNT_START,
    UPDATE_USER_ACCOUNT_ROLE_START
} from "../actions/admin.actions";



const initialState = {
    adminloadig : 100,
    allPaperwork : null,
    empPaperwork : null,
    cltPaperwork : null,
    msg : "",
    unchekcedPaperwork : null,
    currentemployerlist : [],
    currentclientlist : [],
    usersList :[],

    removeuseraccountstatus: 100,
    updateuseraccountstatus: 100,

    addnewformstatus: 100,
    addnewformdata : null,
    formList : [],
    getallformstatus: 100,
    removeformitemstatus: 100,
    removeformitemdata : null,

    removeformmetadataitemstatus: 100,
    removeformmetadataitem:null,

    updateformmetadatastatus: 100,
    updateformmetadata: null,

    addnewDatasetItemStatus : 100,
    addnewDatasetItem: null,

    updateFormMetaDataItemFieldsStatus: 100,

    updateNameValueItemStatus: 100,

    submitedFormsList: [],
    submitedFormsListstatus: 100,
};

const admin = function(state = initialState, action) {
    switch ( action.type  ){
        case Actions.ADMIN_GET_ALL_SUBMITED_FORM_ITEM_DATA_START:{
            return {
                ...state,
                submitedFormsListstatus:100,
            }
        }
        case Actions.ADMIN_GET_ALL_SUBMITED_FORM_ITEM_DATA_SUCCESS: {
            return {
                ...state,
                submitedFormsListstatus: 200,
                submitedFormsList: action.payload,
            }
        }
        case Actions.ADMIN_GET_ALL_SUBMITED_FORM_ITEM_DATA_FAILD:{
            return{
                ...state,
                submitedFormsListstatus: 400,
            }
        }
        case Actions.ADMIN_UPDATE_ITEM_DATA_WITH_NAME_VALUE_START:{
            return {
                ...state,
                updateNameValueItemStatus:100,
            }
        }
        case Actions.ADMIN_UPDATE_ITEM_DATA_WITH_NAME_VALUE_SUCCESS:{
            return {
                ...state,
                updateNameValueItemStatus:200,
                msg: action.msg,
            }
        }
        case Actions.ADMIN_UPDATE_ITEM_DATA_WITH_NAME_VALUE_FAILD:{
            return {
                ...state,
                updateNameValueItemStatus: 400,
                msg : action.msg
            }
        }
        case Actions.ADMIN_UPDATE_FORM_META_DATA_ITEM_FIELDS_START:{
            return {
                ...state,
                updateFormMetaDataItemFieldsStatus:100,
            }
        }
        case Actions.ADMIN_UPDATE_FORM_META_DATA_ITEM_FIELDS_SUCCESS:{
            return {
                ...state,
                updateFormMetaDataItemFieldsStatus: 200,
                msg : action.msg,
            }
        }
        case Actions.ADMIN_UPDATE_FORM_META_DATA_ITEM_FIELDS_FAILD:{
            return {
                ...state,
                updateFormMetaDataItemFieldsStatus: 400,
                msg : action.msg
            }
        }
        case Actions.ADMIN_ADD_NEW_DATA_SET_ITEM_START:{
            return {
                ...state,
                addnewDatasetItem:null,
                addnewDatasetItemStatus:100,
            }
        }
        case Actions.ADMIN_ADD_NEW_DATA_SET_ITEM_SUCCESS:{
            return {
                ...state,
                addnewDatasetItem: action.payload,
                addnewDatasetItemStatus: 200,
                msg : action.msg,
            }
        }
        case Actions.ADMIN_ADD_NEW_DATA_SET_ITEM_FAILD:{
            return {
                ...state,
                addnewDatasetItemStatus: 400,
                addnewDatasetItem:null,
                msg : action.msg,
            }
        }
        case Actions.ADMIN_UPDATE_FORM_META_DATA_ITEM_START:{
            return {
                ...state,
                updateformmetadatastatus:100,
            }
        }
        case Actions.ADMIN_UPDATE_FORM_META_DATA_ITEM_SUCCESS:{
            return {
                ...state,
                updateformmetadatastatus: 200,
                updateformmetadata:action.payload,
            }
        }
        case Actions.ADMIN_UPDATE_FORM_META_DATA_ITEM_FAILD:{
            return {
                ...state,
                updateformmetadatastatus: 400
            }
        }
        case Actions.ADMIN_REMOVE_FORM_META_DATA_ITEM_START:{
            return {
                ...state,
                removeformmetadataitem:null,
                removeformmetadataitemstatus:100,
            }
        }
        case Actions.ADMIN_REMOVE_FORM_META_DATA_ITEM_SUCCESS:{
            return {
                ...state,
                removeformmetadataitem:action.payload,
                removeformmetadataitemstatus:200,
            }
        }
        case Actions.ADMIN_REMOVE_FORM_META_DATA_ITEM_FAILD:{
            return {
                ...state,
                removeformmetadataitemstatus: 400,
                removeformmetadataitem:action.payload,
            }
        }
        case Actions.ADMIN_REMOVE_FORM_ITEM_START:{
            return {
                ...state,
                removeformitemdata:null,
                removeformitemstatus:100,
            }
        }
        case Actions.ADMIN_REMOVE_FORM_ITEM_SUCCESS:{
            return {
                ...state,
                removeformitemdata:action.payload,
                removeformitemstatus: 200,
            }
        }
        case Actions.ADMIN_REMOVE_FORM_ITEM_FAILD:{
            return {
                ...state,
                removeformitemstatus: 400,
                removeformitemdata: null,
            }
        }
        case Actions.ADMIN_GET_ALL_FORM_LIST_START:{
            return {
                ...state,
                getallformstatus:100,
            }
        }
        case Actions.ADMIN_GET_ALL_FORM_LIST_SUCCESS:{
            return {
                ...state,
                getallformstatus: 200,
                formList:action.payload,
            }
        }
        case Actions.ADMIN_GET_ALL_FORM_LIST_FAILD:{
            return {
                ...state,
                getallformstatus: 400,
            }
        }
        case Actions.ADMIN_ADD_NEW_FORM_START:{
            return {
                ...state,
                addnewformdata:null,
                addnewformstatus:100,
            }
        }
        case Actions.ADMIN_ADD_NEW_FORM_SUCCESS:{
            return {
                ...state,
                addnewformdata:action.payload,
                addnewformstatus: 200,
                msg:action.msg,
            }
        }
        case Actions.ADMIN_ADD_NEW_FORM_FAILD:{
            return {
                ...state,
                addnewformdata:null,
                addnewformstatus: 400,
                msg:action.msg,
            }
        }
        case Actions.UPDATE_USER_ACCOUNT_ROLE_START:{
            return {
                ...state,
                updateuseraccountstatus:100,
            }
        }
        case Actions.UPDATE_USER_ACCOUNT_ROLE_SUCCESS:{
            return {
                ...state,
                updateuseraccountstatus: 200,
            }
        }
        case Actions.UPDATE_USER_ACCOUNT_ROLE_FAILD:{
            return {
                ...state,
                updateuseraccountstatus: 400,
            }
        }
        case Actions.REMOVE_USER_ACCOUNT_START:{
            return {
                ...state,
                removeuseraccountstatus:100,
            }
        }
        case Actions.REMOVE_USER_ACCOUNT_SUCCESS:{
            return {
                ...state,
                removeuseraccountstatus: 200,
            }
        }
        case Actions.REMOVE_USER_ACCOUNT_FAILD:{
            return {
                ...state,
                removeuseraccountstatus: 400,
            }
        }
        case Actions.GET_ALL_USERS_LIST_START:{
            return {
                ...state,
            }
        }
        case Actions.GET_ALL_USERS_LIST_SUCCESS:{
            return {
                ...state,
                usersList:action.payload,
            }
        }
        case Actions.GET_ALL_USERS_LIST_FAILD:{
            return {
                ...state,
            }
        }
        case Actions.GET_ALL_CURRENT_CLIENT_LIST_SUCCESS:{
            return {
                ...state,
                currentclientlist:action.payload,
            }
        }
        case Actions.ADMIN_GET_ALL_CURRENT_EMPLOYER_LIST_START:{
            return {
                ...state,
            }
        }
        case Actions.ADMIN_GET_ALL_CURRENT_EMPLOYER_LIST_SUCCESS:{
            return {
                ...state,
                currentemployerlist:action.payload,
            }
        }
        case Actions.ADMIN_GET_ALL_CURRENT_EMPLOYER_LIST_FAILD:{
            return {
                ...state,
            }
        }
        case Actions.ADMIN_GET_ALL_UNCHECKED_PAPER_WORK_SUCCESS:{
            return {
                ...state,
                unchekcedPaperwork: action.payload
            }
        }
        case Actions.ADMIN_GET_ALL_PAPER_WORK_START:{
            return {
                ...state,
                adminloadig: 100,
            }
        }
        case Actions.ADMIN_GET_ALL_PAPER_WORK_SUCCESS:{
            return {
                ...state,
                adminloadig: 200,
                allPaperwork:action.payload,
                empPaperwork:action.emp,
                cltPaperwork:action.clt,
            }
        }
        case Actions.ADMIN_GET_ALL_PAPER_WORK_FAILD:{
            return {
                ...state,
                adminloadig: 400,
            }
        }
        case Actions.USER_LOG_OUT:{
            return {
                ...initialState,
            }
        }
        default:
        {
            return state;
        }
    }
}
const persistConfig = {
    key: 'admin',
    storage: storage,
};
export default persistReducer(persistConfig, admin);