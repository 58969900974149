import React, {Component} from 'react';
import {
    Select,
    Grid,
    Checkbox,
    FormControlLabel,
    TextField,
    Button,  CircularProgress,Paper

} from '@material-ui/core';

import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import {withRouter} from 'react-router-dom';
// for store
import connect from "react-redux/es/connect/connect";
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Draggable from 'react-draggable';
import * as Actions from '../../../store/actions';
import Images from '../../Images';
import Colors from '../../Colors';
import Slider from "react-slick";
import Menu from '@material-ui/core/Menu';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import MessageLists from "./Includes/MessageLists";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import _ from 'lodash';

import PreEmploymentApp from "./EmployeePaper/PreEmploymentApp";
import ADpaperwork from "./EmployeePaper/ADpaperwork";
import CraigGants from "./EmployeePaper/CraigGants";
import AccountPage from "./AccountPage";



function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};




const styles = theme => ({
    title: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    acountMenu:{
        marginTop: 30
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: '#0000006b',
        zIndex: 1000,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    progress: {
        margin: 1,
    },
    username:{
        fontSize: 13,
        fontWeight: 800
    },
    DialogTitle:{
        backgroundColor: '#3f51b5',
        color: 'white',
        fontWeight: 800,
    },
    closeBtn :{
        position: 'absolute',
        top: 9,
        right: 36,
    },
});


class EmployeeDashboard extends Component {
    constructor(props){
        super(props);
        this.state={
            PaperType : 0,
            value :0,
            employeeStatus: 100,
            accountItemOpen:false,
            anchorEl:null,
            notificationCount: 0,
            openDialog:false,//false
            viewDialogStatus: 1,//messageview ==1
        }
    }

    componentDidMount(){
        this.CheckEmployeeStatus();
        this.getMyLoginData = setInterval(this.CheckLoginData, 2000);
    }

    componentDidUpdate(prevProps, prevState, snapshot){

    }
    CheckEmployeeStatus=()=>{
        const { userData ,loginData } = this.props;
        if(loginData !== null){
            if(loginData.paperstatus ==="100"){
                console.log("pre emp passed");
                // this.setState({PaperType: 1});
            }
            else if(loginData.paperstatus ==="200"){
                console.log("current emp");
                // this.setState({PaperType: 2});
            }
            else{
                console.log("temp");
                this.setState({PaperType: 0});
            }
        }
        else {
            this.setState({PaperType: 0 });
        }

    }
    componentWilUnmount() {
        clearInterval(this.getMyLoginData);
    }
    CheckAdmin=()=>{
        const { loginData } = this.props;
        if(loginData !== null){
            if(loginData.admin && loginData.admin !==null && loginData.admin !== "" ){
                this.props.history.push('/admin');
            }
        }
    }
    CheckLoginData=()=>{
         this.CheckEmployeeStatus();
        this.CheckAdmin();
        const {loginData} = this.props;
        if(loginData && loginData !== null){
            var id = loginData.id;
            if(id !==""  && id){
                this.props.GetLoginData({id:id});
            }
        }
    }

    handleChange = (event, newValue)=>{
        this.setState({value: newValue});
    }
    handleMenu=(e)=>{
        this.setState({anchorEl:e.currentTarget});
        this.setState({accountItemOpen:!this.state.accountItemOpen});
    }
    handleClose =()=>{
        this.setState({accountItemOpen:!this.state.accountItemOpen});
    }
    myaccountpage=()=>{
        this.handleClose();
        this.setState({openDialog: true,viewDialogStatus: 2});
    }
    Logout=()=>{
        clearInterval(this.getMyLoginData);
        this.props.LogOut();
        this.props.history.push('/signup');
        this.handleClose();
    }
    viewNotification=()=>{
        // this.setState({openDialog: true,viewDialogStatus: 1});
    }
    handleDialogClose=()=>{
        this.setState({openDialog: false,viewDialogStatus:0});

    }
    handleChangeIndex =(event, index)=>{
        //
        let midType = -1;
        const { userData ,loginData } = this.props;
        if(loginData !== null){
            if(loginData.paperstatus ==="100"){
                console.log("pre emp passed");
                // this.setState({PaperType: 1});
            }
            else if(loginData.paperstatus ==="200"){
                console.log("current emp");
                // this.setState({PaperType: 2});
            }
            else{
                console.log("temp");
                midType = 0;
            }
        }
        else {
            midType = 0;
        }


        if(loginData !== null && midType !== 0){
            if(loginData.paperstatus ==="100" || loginData.paperstatus ==="200"){
                this.setState({PaperType:index});
            }
            this.setState({PaperType:index});
        }
    }
    viewMessageBox=()=>{
        this.setState({openDialog: true,viewDialogStatus: 1});
    }
    render(){
        const {classes ,loginData} = this.props;
        const { PaperType ,value,accountItemOpen,anchorEl ,notificationCount,openDialog ,viewDialogStatus} = this.state;
        return(
            <div>
                { this.props.loading === 100 && (
                    <div className={classNames(classes.overlay, 'flex items-center w-full')}>
                        <CircularProgress className={classes.progress} color="secondary"  />
                    </div>
                )}
                <ToastContainer />
                <AppBar position="static">
                    <Toolbar>
                        {/*<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">*/}
                            {/*<MenuIcon />*/}
                        {/*</IconButton>*/}
                        <img src={Images.favoicon} alt="" style={{width: 50,height: 50,marginRight:15}}/>
                        <Typography edge="start" variant="h6" className={classes.title}>
                            Employee Dashboard
                        </Typography>
                        <div className={classes.username}>
                            {loginData && loginData !== null && loginData.fname ? loginData.fname :""} {loginData && loginData !== null && loginData.lname ? loginData.lname :""}
                        </div>
                        <IconButton aria-label="show 11 new notifications" color="inherit" onClick={this.viewNotification}>
                            <Badge badgeContent={notificationCount} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        {true && (
                            <div>
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={this.handleMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    className={classes.acountMenu}
                                    open={accountItemOpen}
                                    onClose={this.handleClose}
                                >

                                    <MenuItem onClick ={this.myaccountpage}><AccountCircle/> My account</MenuItem>
                                    <MenuItem onClick ={this.Logout}>LogOut</MenuItem>
                                </Menu>
                            </div>
                        )}
                    </Toolbar>
                </AppBar>
                <Paper square>
                    <Tabs
                        value={PaperType}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleChangeIndex}
                        aria-label="disabled tabs example"
                    >
                        <Tab label="Pre-EMPLOYMENT APPLICATION" />
                        <Tab label="AD PaperWork" />
                        <Tab label="Craig Gant's employee" />
                    </Tabs>
                </Paper>
                { PaperType === 0 ? (<PreEmploymentApp viewMessageBox={this.viewMessageBox}/>):(<div/>)}
                { PaperType === 1 ? (<ADpaperwork viewMessageBox={this.viewMessageBox}/>):(<div/>)}
                { PaperType === 2 ? (<CraigGants viewMessageBox={this.viewMessageBox}/>):(<div/>)}

                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={openDialog}
                    fullScreen={false}
                    onClose={this.handleDialogClose}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle id="max-width-dialog-title" className={classes.DialogTitle}>
                        {viewDialogStatus === 1 ? "MessageBox":""}
                        {viewDialogStatus === 2 ? "My account":""}
                        <IconButton edge="start" color="inherit" aria-label="close" className={classes.closeBtn} onClick ={this.handleDialogClose}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={{overflowY:"hidden"}}>
                        {viewDialogStatus === 1  ? <MessageLists userMessage={"userMessage"}/> :<div/>}
                        {viewDialogStatus === 2  ? <AccountPage /> :<div/>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        LogOut : Actions.LogOut,
        GetPaperWork:Actions.GetPaperWork,
        GetLoginData:Actions.GetLoginData,
    }, dispatch);
}

function mapStateToProps({auth,}) {
    return {
        auth: auth,
        loginData: auth.loginData,
        userData: auth.userData,
        loading: auth.loading,
        createuserStatus: auth.createuserStatus,
        loginStatus: auth.loginStatus,
        getPaperWorkStatus: auth.getPaperWorkStatus,
        getPaperWork: auth.getPaperWork,
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(EmployeeDashboard));