import React, {Component} from 'react';
import {
    Select,
    Grid,
    Checkbox,
    FormControlLabel,
    TextField,
    Button,  CircularProgress,

} from '@material-ui/core';

import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import {withRouter} from 'react-router-dom';
// for store
import connect from "react-redux/es/connect/connect";
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Draggable from 'react-draggable';
import * as Actions from '../../../store/actions';
import Images from '../../Images';
import Colors from '../../Colors';
import Slider from "react-slick";
import HoverImage from "react-hover-image";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = theme => ({
    rowItem:{
        width:'90%',
        marginLeft:"5%",
        display:"flex",
        flexDirection:"row",
        justifyContent:"flex-start",


    },
    formTypeSelect:{
        marginTop:25,
        minWidth: 300,
        maxWidth: 300,
        marginLeft:25,
        marginRight: 25
    },
    formBtn:{
        width:"50%",
        marginTop: 30,

    }
});


class AccountPage extends Component {
    constructor(props){
        super(props);
        this.state={
            loginStatus : 100,
            username:"",
            fname:"",
            lname :"",
            oldPassword:"",
            userPassword:"",
            userrePassword:"",
            type:"",
            email:"",
            id:"",
        }
    }

    componentDidMount(){
        this.initUserData();
    }
    initUserData =()=>{
        const {loginData} = this.props;
        if(loginData && loginData !==null){
            this.setState({
                ...this.state,
                ...loginData,
            })
        }
    }
    showmessage=(status,msg)=>{
        if(msg ==="") return;
        if(status >200){

            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else if(status ===200){
            toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevProps.loading === 100 && this.props.loading >= 200 ){
            this.showmessage(this.props.loading,this.props.message);
            if(this.props.loading === 200){
                this.initUserData();
            }
        }
    }
    componentWilUnmount() {

    }
    handleChangeFormType=(e,name)=>{
        if(name !==""){
            this.setState({[name]:e.target.value});
        }
    }
    updateAccountInfo=()=>{
        const {fname,lname,email,userPassword,userrePassword,oldPassword ,id} = this.state;
        var data ={};
        if(!id || id ===""){
            return;
        }
        else if(!fname || fname ===""){
            this.showmessage(400,"Please Input First Name");
        }
        else if(!lname || lname ===""){
            this.showmessage(400,"Please Input Last Name");
        }
        else if(oldPassword || oldPassword !=="" || userrePassword || userrePassword !=="" || userPassword || userPassword !==""){
            if(!oldPassword || oldPassword ===""){
                this.showmessage(400,"Please Input Old Password");
            }
            else if(!userPassword || userPassword ===""){
                this.showmessage(400,"Please Input Password");
            }
            else if(!userrePassword || userrePassword ===""){
                this.showmessage(400,"Please Input Re - Password");
            }
            else if(userrePassword !=="" && userrePassword && userrePassword !==userPassword){
                this.showmessage(400,"Please Input Matched Re - Password");
            }
            else {
                data={id, fname,lname, oldPassword,userPassword};
                this.props.UpdateUserAccountDetail(data);
            }
        }
        else {
            data={id, fname,lname};
            this.props.UpdateUserAccountDetail(data);
        }
    }
    render(){
        const {classes} = this.props;
        const {fname,lname,email,userPassword,userrePassword,oldPassword} = this.state;
        return(
            <div >
                <ToastContainer />
                <div className={classes.rowItem}>
                    <span style={{
                        fontSize: 23,
                        fontWeight: 900
                    }}>My Account Detail</span>
                </div>
                <div className={classes.rowItem}>
                    <TextField
                        label="First Name"
                        value={fname}
                        variant="outlined"
                        margin="dense"
                        className={classes.formTypeSelect}
                        onChange={(e)=>this.handleChangeFormType(e,"fname")}
                    />
                    <TextField
                        label="Last Name"
                        value={lname}
                        variant="outlined"
                        margin="dense"
                        className={classes.formTypeSelect}
                        onChange={(e)=>this.handleChangeFormType(e,"lname")}
                    />

                </div>
                <div className={classes.rowItem}>
                    <TextField
                        label="Email"
                        value={email}
                        variant="outlined"
                        margin="dense"
                        disabled={true}
                        className={classes.formTypeSelect}
                        onChange={(e)=>this.handleChangeFormType(e,"email")}
                    />
                </div>
                <div className={classes.rowItem}>
                    <TextField
                        label="Old - Password"
                        value={oldPassword}
                        variant="outlined"
                        margin="dense"
                        type="password"
                        className={classes.formTypeSelect}
                        onChange={(e)=>this.handleChangeFormType(e,"oldPassword")}
                    />
                    <TextField
                        label="Password"
                        value={userPassword}
                        variant="outlined"
                        margin="dense"
                        type="password"
                        className={classes.formTypeSelect}
                        onChange={(e)=>this.handleChangeFormType(e,"userPassword")}
                    />
                    <TextField
                        label="Re - Password"
                        value={userrePassword}
                        variant="outlined"
                        margin="dense"
                        type="password"
                        className={classes.formTypeSelect}
                        onChange={(e)=>this.handleChangeFormType(e,"userrePassword")}
                    />
                </div>
                <div className={classes.rowItem} style={{justifyContent:"center"}}>
                    <Button variant="contained" color="primary" className = {classes.formBtn} onClick = {this.updateAccountInfo}>
                        Update
                    </Button>
                </div>



            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        LogOut : Actions.LogOut,
        UpdateUserAccountDetail : Actions.UpdateUserAccountDetail,
    }, dispatch);
}

function mapStateToProps({auth,}) {
    return {
        auth: auth,
        loginData: auth.loginData,
        userData: auth.userData,
        loading: auth.loading,
        createuserStatus: auth.createuserStatus,
        loginStatus: auth.loginStatus,
        message: auth.message,
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(AccountPage));