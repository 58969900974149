import React, {Component} from 'react';
import {
    Select,
    Grid,
    Checkbox,
    FormControlLabel,
    TextField,
    Button,  CircularProgress,

} from '@material-ui/core';

import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import {withRouter} from 'react-router-dom';
// for store
import connect from "react-redux/es/connect/connect";
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Draggable from 'react-draggable';
import * as Actions from '../../../../store/actions';
import Images from '../../../Images';
import Colors from '../../../Colors';
import Slider from "react-slick";
import Menu from '@material-ui/core/Menu';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import ReactTable from "react-table";
import "react-table/react-table.css";
import moment from "moment";

import _ from 'lodash';
const hexToRgb = (hex) =>{
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};
const styles = theme => ({
    title: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    acountMenu:{
        marginTop: 30
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: '#0000006b',
        zIndex: 1000,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    progress: {
        margin: 1,
    },
    tableTheadRow:{
        // backgroundColor: 'rgba(' + hexToRgb(theme.palette.primary.main).r + ',' + hexToRgb(theme.palette.primary.main).g + ',' + hexToRgb(theme.palette.primary.main).b +', .2)'
        backgroundColor: theme.palette.primary.main
    },
    tableThEven:{
        backgroundColor: 'rgba(' + hexToRgb(theme.palette.secondary.main).r + ',' + hexToRgb(theme.palette.secondary.main).g + ',' + hexToRgb(theme.palette.secondary.main).b +', .3)'
    },
    tableTdEven:{
        // backgroundColor: 'rgba(' + hexToRgb(theme.palette.secondary.main).r + ',' + hexToRgb(theme.palette.secondary.main).g + ',' + hexToRgb(theme.palette.secondary.main).b +', .1)'
    },
});


class UserAccounts extends Component {
    constructor(props){
        super(props);
        this.state={
            loginStatus : 100,
        }
    }

    componentDidMount(){
        this.GetAllusers();
    }
    GetAllusers=()=>{
        this.props.GetAllUsersList();
    }
    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevProps.removeuseraccountstatus !== this.props.removeuseraccountstatus && this.props.removeuseraccountstatus >= 200){
            this.GetAllusers();
        }
        if(prevProps.updateuseraccountstatus !== this.props.updateuseraccountstatus && this.props.updateuseraccountstatus >= 200){
            this.GetAllusers();
        }

    }
    componentWilUnmount() {

    }
    removeUser=(data)=>{
        if(data && data !== null){
            var id = data.id;
            var mydata={id};
            this.props.RemoveUserAccount(mydata);
        }
    }
    handleChange = (event,data) => {
        this.setState({usertype: event.target.value});
        if(data && data !== null){
            var type = "";
            var name = "";
            if(event.target.value ==="admin"){
                type ="100";
                name ="admin";
            }
            else if(event.target.value ==="superadmin"){
                type ="300";
                name = "admin";
            }
            else {
                type =event.target.value;
                name = "type";
            }
            var mydata = {
                id: data.id,
                name: name,
                value : type,
            }
            this.props.UpdateUserAccountRole(mydata);
        }
    };

    render(){
        const {classes,usersList} = this.props;


        return(
            <div >
                <ReactTable
                    data ={usersList}
                    minRows = {0}
                    getTheadGroupProps={(state, rowInfo, column, instance) => {
                        return {
                            style: {
                                // padding: "10px 10px",
                                fontSize: 16,
                                fontWeight: 700
                            },
                        }
                    }}
                    getTheadGroupThProps={(state, rowInfo, column, instance) => {
                        return {
                            style: {
                                // padding: "10px 10px",
                                fontSize: 18,
                                fontWeight: 700,
                            },
                            className: classNames("flex items-center justify-start")
                        }
                    }}
                    getTheadThProps={(state, rowInfo, column, instance) => {
                        let border = '1px solid rgba(255,255,255,.6)';
                        if (column.Header === 'Actions') border = 'none';
                        return {
                            style: {
                                fontSize: '18px',
                                height: 50,
                                // fontFamily: 'Muli,Roboto,"Helvetica",Arial,sans-serif',
                                fontWeight: 400,
                                lineHeight: 1.75,
                                color: 'white',
                                borderRight: border
                            },
                        }
                    }}
                    getTheadProps={(state, rowInfo, column, instance) => {
                        return {
                            style: {
                                fontSize: 13,
                            },
                            className: classes.tableTheadRow
                        }
                    }}
                    getTdProps={(state, rowInfo, column, instance) => {
                        return {
                            style: {
                                textAlign: 'center',
                                flexDirection: 'row',
                                fontSize: 15,
                                padding: "0",
                                height: 50,
                                paddingTop: 12,
                            },
                        }
                    }}

                    columns={[
                        {
                            columns: [
                                {
                                    Header: "No",
                                    accessor: "name",
                                    filterAll: true,
                                    width: '10%',
                                    className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                    Cell: row =>{
                                        return(<div>{row.index +1}</div>)
                                    }
                                },

                                {
                                    Header: "User Name",
                                    accessor: "email",
                                    filterAll: true,
                                    width: '20%',
                                    className: classNames(classes.tableTdEven, "flex items-center  justify-center"),
                                    Cell: row =>{
                                        return(<div style={{color:"blue",fontWeight:800}}>
                                            {row.original && row.original !== null && row.original.fname  !== null && row.original.fname && row.original.fname !=="" ? row.original.fname +" ":""}
                                            {row.original && row.original !== null && row.original.lname !== null && row.original.lname && row.original.lname !=="" ? row.original.lname:" "}
                                        </div>)
                                    },
                                },


                                {
                                    Header: "User Type",
                                    accessor: "type",
                                    width: '10%',
                                    className: classNames("flex items-center  justify-start p-12-impor"),
                                    Cell:row=>{
                                        return (
                                            <TextField
                                                id="standard-select-currency-native"
                                                select
                                                value={row.original.admin ==="100" ?"admin":row.original.admin ==="300" ? "superadmin" :row.original.type}
                                                onChange={(e)=>this.handleChange(e,row.original)}
                                                SelectProps={{
                                                    native: true,
                                                }}
                                            >
                                                <option  value={"employee"}>
                                                    Employer
                                                </option>
                                                <option  value={"client"}>
                                                    Client
                                                </option>
                                                <option  value={"admin"}>
                                                    admin
                                                </option>
                                                <option  value={"superadmin"}>
                                                    Super Admin
                                                </option>
                                            </TextField>
                                        )
                                    }

                                },
                                {
                                    Header: "Created Date",
                                    accessor: "created",
                                    width: '10%',
                                    className: classNames("flex items-center  justify-start p-12-impor"),
                                    Cell:row=>{
                                        return (
                                            <div>{moment.unix(row.original.created).format("YYYY-MM-DD HH:mm:ss")}</div>
                                        )
                                    }

                                },

                                {
                                    Header: "Action",
                                    accessor: "currentemp",
                                    width: '10%',
                                    className: classNames("flex items-center  justify-start p-12-impor"),
                                    Cell:row=> {
                                        return (
                                            <Button color="secondary" onClick={()=>this.removeUser(row.original)}>Remove</Button>
                                        )
                                    }


                                },
                            ]
                        },
                    ]}
                    defaultPageSize={10}
                    className={classNames( "-striped -highlight")}
                    totalRecords = {usersList && usersList !== null ? usersList.length:0}
                    style={{
                        height: '100%',
                    }}
                />
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        GetAllUsersList:Actions.GetAllUsersList,
        RemoveUserAccount:Actions.RemoveUserAccount,
        UpdateUserAccountRole:Actions.UpdateUserAccountRole,
    }, dispatch);
}

function mapStateToProps({auth,admin}) {
    return {
        auth: auth,
        loginData: auth.loginData,
        userData: auth.userData,
        loading: auth.loading,
        usersList: admin.usersList,
        removeuseraccountstatus: admin.removeuseraccountstatus,
        updateuseraccountstatus: admin.updateuseraccountstatus,

    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(UserAccounts));