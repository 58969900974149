import React, {Component} from 'react';
import {
    Select,
    Grid,
    Checkbox,
    FormControlLabel,FormGroup,
    TextField,Radio,
    Button,  CircularProgress,

} from '@material-ui/core';

import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import {withRouter} from 'react-router-dom';
// for store
import connect from "react-redux/es/connect/connect";
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Draggable from 'react-draggable';
import * as Actions from '../../../../store/actions';


import Paper from '@material-ui/core/Paper';
import Slider from "react-slick";
import HoverImage from "react-hover-image";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LockIcon from '@material-ui/icons/Lock';
import _ from 'lodash';
import SignatureCanvas from 'react-signature-canvas';
import SignaturePad from 'react-signature-canvas';
import moment from "moment/moment";
import {toast} from "react-toastify/dist/index";


const styles = theme => ({
    root:{
        width: "100%",
        // height:"100vh",
        display:"flex",
        flexDirection:"column",

    },
    topheader:{
        display:"flex",
        flexDirection:"column",
        width:350,
        marginLeft:100,
        marginTop: 10,
    },
    topHeaderTxt:{
        fontSize: 18,
        fontWeight: 900,

    },
    infopaper:{
        width:"90%",
        marginLeft:"5%",
        marginTop:15,
        border: 'solid 1px gray',
    },
    paperItem:{
        width:"100%",
        display:"flex",
        flexDirection:"column",
    },
    paperrow:{
        width:"96%",
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"space-between",
        marginLeft:"2%"
    },
    paperTitletxt:{
        color:"gray",
        fontSize: 20,
        fontWeight: 800
    },
    papermincell:{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        textAlign:"center"
    },
    sigPad :{
        width: '100%',
        height: '100%',
    },
    sigImage:{
        width:150,
        height: 60,
        border:"solid 1px gray"
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: '#0000006b',
        zIndex: 1000,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex'
    },
    progress: {
        margin: 1,
    },
});


class PreEmploymentApp extends Component {
    constructor(props){
        super(props);
        this.state={
            appDate:moment().format("YYYY-MM-DD"),
            userFirstName:"",
            userMiddleName:"",
            userLastName:"",
            userMaidenName:"",
            userSSN:"",
            userBirth:moment().format("YYYY-MM-DD"),
            userdriverLicense:"",
            userAddr:"",
            userCity:"",
            userState:"",
            zipcode:"",
            cellPhone:"",
            alternatePhone:"",
            email:"",
            older:"",
            uscitizen:"",
            legallyentitled:"",

            //employment desired
            applyingfor:"",
            trushhomecarebefore:"",
            trushhomecarewhen:"",
            trushhomecareknowanyone:"",
            trushhomecareknowanyonewhom:"",
            trushhomecarehearabout:"",
            referedby:"",

            //Education
            highschoolinstitutionname:"",
            highschoolinstitutionto:"",
            highschoolinstitutionfrom:"",
            highschooldegreereceive:"",
            highschoolgraduate:"",
            collegeinstitutionname:"",
            collegeinstitutionto:"",
            collegeinstitutionfrom:"",
            collegedegreereceive:"",
            collegegraduate:"",
            otherinstitutionname:"",
            otherinstitutionto:"",
            otherinstitutionfrom:"",
            otherdegreereceive:"",
            othergraduate:"",
            licensecertification1:"",
            licenseno1:"",
            stateissued1:"",
            expirationdate1:"",
            licensecertification2:"",
            licenseno2:"",
            stateissued2:"",
            expirationdate2:"",

            //workexperience
            employer1:"",
            startDate1:"",
            endDate1:"",
            currentlyemployer1:"",
            workexpphone1:"",
            workexpjobtitle1:"",
            workexpstartingsalary1:"",
            workexpendsalary1:"",
            workexpDuties1:"",
            workexpReasonforleaving1:"",

            employer2:"",
            startDate2:"",
            endDate2:"",
            currentlyemployer2:"",
            workexpphone2:"",
            workexpjobtitle2:"",
            workexpstartingsalary2:"",
            workexpendsalary2:"",
            workexpDuties2:"",
            workexpReasonforleaving2:"",

            employer3:"",
            startDate3:"",
            endDate3:"",
            currentlyemployer3:"",
            workexpphone3:"",
            workexpjobtitle3:"",
            workexpstartingsalary3:"",
            workexpendsalary3:"",
            workexpDuties3:"",
            workexpReasonforleaving3:"",

            employer4:"",
            startDate4:"",
            endDate4:"",
            currentlyemployer4:"",
            workexpphone4:"",
            workexpjobtitle4:"",
            workexpstartingsalary4:"",
            workexpendsalary4:"",
            workexpDuties4:"",
            workexpReasonforleaving4:"",

            //reference
            referencename1:"",
            referencerelation1:"",
            referenceyrsknown1:"",
            referencecontactinfo1:"",


            referencename2:"",
            referencerelation2:"",
            referenceyrsknown2:"",
            referencecontactinfo2:"",

            referencename3:"",
            referencerelation3:"",
            referenceyrsknown3:"",
            referencecontactinfo3:"",

            referencename4:"",
            referencerelation4:"",
            referenceyrsknown4:"",
            referencecontactinfo4:"",

            emergencycontactName:"",
            emergencycontactrelationship:"",
            emergencycontactphone:"",

            sigPad:{},
            appSign:null,
            forofficeuseonly:"",
            interviewconductedby:"",
            interviewconducteddate:"",
        }
    }

    componentDidMount(){
        this.getPaperWorkItem();
    }
    getPaperWorkItem=()=>{
        const {loginData} = this.props;
        if(loginData !== null ){
            var userid = loginData.id;
            var papertype="trust";
            var data ={userid, papertype};
            if(userid !=="" && userid !== null){
                this.props.GetPaperWork(data);
            }
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevProps.paperWorkStatus !== this.props.paperWorkStatus && this.props.paperWorkStatus >= 200){
            this.showmessage(this.props.paperWorkStatus,this.props.auth.message);
            if(this.props.loginStatus === 200){
                this.initFormdata();
            }
        }
        if(prevProps.getPaperWorkStatus !== this.props.getPaperWorkStatus && this.props.getPaperWorkStatus >= 200){
            this.upDatePaperWork();
        }
        if( prevProps.loginData.paperstatus !==  this.props.loginData.paperstatus ){
            this.getPaperWorkItem();
        }
    }
    componentWilUnmount() {

    }
    upDatePaperWork=()=>{
        const {getPaperWork} = this.props;
        if(getPaperWork !== null && getPaperWork.data !== null && getPaperWork.data !==""){
            var data = JSON.parse(getPaperWork.data);
            this.setState({...data});
        }
    }
    showmessage=(status,msg)=>{
        if(msg ==="") return;
        if(status >200){

            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        else if(status ===200){
            toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    handleChangeFormType=(e,name)=>{
        if(this.props.loginData.paperstatus ===""){

        }
        if(name !=="" && this.props.loginData && this.props.loginData !== null && this.props.loginData.paperstatus ===""){
            this.setState({[name]:e.target.value});
        }
    }
    handleCheckRadio=(e,name)=>{
        this.setState({[name]:e.target.value});
    }
    trim = () => {
        this.setState({appSign: this.sigPad.getTrimmedCanvas().toDataURL('image/png')})
    }
    clearSign = () => {
        this.sigPad.clear();
        this.setState({appSign:null});
    }
    userSignTrim=()=>{
        this.trim();
    }
    userSignTrimClear=()=>{
        this.clearSign();
    }
    initFormdata=()=>{
        this.setState({
            appDate:moment().format("YYYY-MM-DD"),
            userFirstName:"",
            userMiddleName:"",
            userLastName:"",
            userMaidenName:"",
            userSSN:"",
            userBirth:moment().format("YYYY-MM-DD"),
            userdriverLicense:"",
            userAddr:"",
            userCity:"",
            userState:"",
            zipcode:"",
            cellPhone:"",
            alternatePhone:"",
            email:"",
            older:"",
            uscitizen:"",
            legallyentitled:"",

            //employment desired
            applyingfor:"",
            trushhomecarebefore:"",
            trushhomecarewhen:"",
            trushhomecareknowanyone:"",
            trushhomecareknowanyonewhom:"",
            trushhomecarehearabout:"",
            referedby:"",

            //Education
            highschoolinstitutionname:"",
            highschoolinstitutionto:"",
            highschoolinstitutionfrom:"",
            highschooldegreereceive:"",
            highschoolgraduate:"",
            collegeinstitutionname:"",
            collegeinstitutionto:"",
            collegeinstitutionfrom:"",
            collegedegreereceive:"",
            collegegraduate:"",
            otherinstitutionname:"",
            otherinstitutionto:"",
            otherinstitutionfrom:"",
            otherdegreereceive:"",
            othergraduate:"",
            licensecertification1:"",
            licenseno1:"",
            stateissued1:"",
            expirationdate1:"",
            licensecertification2:"",
            licenseno2:"",
            stateissued2:"",
            expirationdate2:"",

            //workexperience
            employer1:"",
            startDate1:"",
            endDate1:"",
            currentlyemployer1:"",
            workexpphone1:"",
            workexpjobtitle1:"",
            workexpstartingsalary1:"",
            workexpendsalary1:"",
            workexpDuties1:"",
            workexpReasonforleaving1:"",

            employer2:"",
            startDate2:"",
            endDate2:"",
            currentlyemployer2:"",
            workexpphone2:"",
            workexpjobtitle2:"",
            workexpstartingsalary2:"",
            workexpendsalary2:"",
            workexpDuties2:"",
            workexpReasonforleaving2:"",

            employer3:"",
            startDate3:"",
            endDate3:"",
            currentlyemployer3:"",
            workexpphone3:"",
            workexpjobtitle3:"",
            workexpstartingsalary3:"",
            workexpendsalary3:"",
            workexpDuties3:"",
            workexpReasonforleaving3:"",

            employer4:"",
            startDate4:"",
            endDate4:"",
            currentlyemployer4:"",
            workexpphone4:"",
            workexpjobtitle4:"",
            workexpstartingsalary4:"",
            workexpendsalary4:"",
            workexpDuties4:"",
            workexpReasonforleaving4:"",

            //reference
            referencename1:"",
            referencerelation1:"",
            referenceyrsknown1:"",
            referencecontactinfo1:"",


            referencename2:"",
            referencerelation2:"",
            referenceyrsknown2:"",
            referencecontactinfo2:"",

            referencename3:"",
            referencerelation3:"",
            referenceyrsknown3:"",
            referencecontactinfo3:"",

            referencename4:"",
            referencerelation4:"",
            referenceyrsknown4:"",
            referencecontactinfo4:"",

            emergencycontactName:"",
            emergencycontactrelationship:"",
            emergencycontactphone:"",

            sigPad:{},
            appSign:null,
            forofficeuseonly:"",
            interviewconductedby:"",
            interviewconducteddate:"",
        });
    }
    submitMyPaper=()=>{
        const {loginData} = this.props;
        console.log(loginData);
        const {

            appDate ,userFirstName,userMiddleName,userLastName ,userMaidenName,userdriverLicense,userBirth,userSSN,userAddr,userCity,userState,zipcode,cellPhone,alternatePhone, email,older,uscitizen,legallyentitled,
            applyingfor,trushhomecarebefore,trushhomecarewhen,trushhomecareknowanyone,trushhomecareknowanyonewhom,trushhomecarehearabout,referedby,
            highschoolinstitutionname,highschoolinstitutionfrom,highschoolinstitutionto,highschooldegreereceive,highschoolgraduate,collegeinstitutionname,collegeinstitutionto,collegeinstitutionfrom,collegedegreereceive,collegegraduate,
            otherinstitutionname,otherinstitutionto,otherinstitutionfrom,otherdegreereceive,othergraduate,licensecertification1,licenseno1,stateissued1,expirationdate1,licensecertification2,licenseno2,stateissued2,expirationdate2,
            employer1,startDate1,endDate1,currentlyemployer1,workexpphone1,workexpjobtitle1,workexpstartingsalary1,workexpendsalary1,workexpDuties1,workexpReasonforleaving1,
            employer2,startDate2,endDate2,currentlyemployer2,workexpphone2,workexpjobtitle2,workexpstartingsalary2,workexpendsalary2,workexpDuties2,workexpReasonforleaving2,
            employer3,startDate3,endDate3,currentlyemployer3,workexpphone3,workexpjobtitle3,workexpstartingsalary3,workexpendsalary3,workexpDuties3,workexpReasonforleaving3,
            employer4,startDate4,endDate4,currentlyemployer4,workexpphone4,workexpjobtitle4,workexpstartingsalary4,workexpendsalary4,workexpDuties4,workexpReasonforleaving4,
            referencename1,referencerelation1,referenceyrsknown1,referencecontactinfo1,
            referencename2,referencerelation2,referenceyrsknown2,referencecontactinfo2,
            referencename3,referencerelation3,referenceyrsknown3,referencecontactinfo3,
            referencename4,referencerelation4,referenceyrsknown4,referencecontactinfo4,
            emergencycontactName,emergencycontactrelationship,emergencycontactphone,
            appSign,forofficeuseonly,interviewconductedby,interviewconducteddate

        } = this.state;
        var mdata ={
            appDate ,userFirstName,userMiddleName,userLastName ,userMaidenName,userdriverLicense,userBirth,userSSN,userAddr,userCity,userState,zipcode,cellPhone,alternatePhone, email,older,uscitizen,legallyentitled,
            applyingfor,trushhomecarebefore,trushhomecarewhen,trushhomecareknowanyone,trushhomecareknowanyonewhom,trushhomecarehearabout,referedby,
            highschoolinstitutionname,highschoolinstitutionfrom,highschoolinstitutionto,highschooldegreereceive,highschoolgraduate,collegeinstitutionname,collegeinstitutionto,collegeinstitutionfrom,collegedegreereceive,collegegraduate,
            otherinstitutionname,otherinstitutionto,otherinstitutionfrom,otherdegreereceive,othergraduate,licensecertification1,licenseno1,stateissued1,expirationdate1,licensecertification2,licenseno2,stateissued2,expirationdate2,
            employer1,startDate1,endDate1,currentlyemployer1,workexpphone1,workexpjobtitle1,workexpstartingsalary1,workexpendsalary1,workexpDuties1,workexpReasonforleaving1,
            employer2,startDate2,endDate2,currentlyemployer2,workexpphone2,workexpjobtitle2,workexpstartingsalary2,workexpendsalary2,workexpDuties2,workexpReasonforleaving2,
            employer3,startDate3,endDate3,currentlyemployer3,workexpphone3,workexpjobtitle3,workexpstartingsalary3,workexpendsalary3,workexpDuties3,workexpReasonforleaving3,
            employer4,startDate4,endDate4,currentlyemployer4,workexpphone4,workexpjobtitle4,workexpstartingsalary4,workexpendsalary4,workexpDuties4,workexpReasonforleaving4,
            referencename1,referencerelation1,referenceyrsknown1,referencecontactinfo1,
            referencename2,referencerelation2,referenceyrsknown2,referencecontactinfo2,
            referencename3,referencerelation3,referenceyrsknown3,referencecontactinfo3,
            referencename4,referencerelation4,referenceyrsknown4,referencecontactinfo4,
            emergencycontactName,emergencycontactrelationship,emergencycontactphone,
            appSign,forofficeuseonly,interviewconductedby,interviewconducteddate
        };

        if( loginData !== null && loginData.id && loginData.id !=="" ){
            var userid = loginData.id;
            var papertype="trust";
            var data = JSON.stringify(mdata);
            var vdata={ userid, data,papertype,};

            console.log(vdata);
            this.props.SubmitPaperWork(vdata);
        }

    }
    viewMessage=()=>{
        this.props.viewMessageBox();
    }
    render(){
        const {classes} = this.props;
        const {

            appDate ,userFirstName,userMiddleName,userLastName ,userMaidenName,userdriverLicense,userBirth,userSSN,userAddr,userCity,userState,zipcode,cellPhone,alternatePhone, email,older,uscitizen,legallyentitled,
            applyingfor,trushhomecarebefore,trushhomecarewhen,trushhomecareknowanyone,trushhomecareknowanyonewhom,trushhomecarehearabout,referedby,
            highschoolinstitutionname,highschoolinstitutionfrom,highschoolinstitutionto,highschooldegreereceive,highschoolgraduate,collegeinstitutionname,collegeinstitutionto,collegeinstitutionfrom,collegedegreereceive,collegegraduate,
            otherinstitutionname,otherinstitutionto,otherinstitutionfrom,otherdegreereceive,othergraduate,licensecertification1,licenseno1,stateissued1,expirationdate1,licensecertification2,licenseno2,stateissued2,expirationdate2,
            employer1,startDate1,endDate1,currentlyemployer1,workexpphone1,workexpjobtitle1,workexpstartingsalary1,workexpendsalary1,workexpDuties1,workexpReasonforleaving1,
            employer2,startDate2,endDate2,currentlyemployer2,workexpphone2,workexpjobtitle2,workexpstartingsalary2,workexpendsalary2,workexpDuties2,workexpReasonforleaving2,
            employer3,startDate3,endDate3,currentlyemployer3,workexpphone3,workexpjobtitle3,workexpstartingsalary3,workexpendsalary3,workexpDuties3,workexpReasonforleaving3,
            employer4,startDate4,endDate4,currentlyemployer4,workexpphone4,workexpjobtitle4,workexpstartingsalary4,workexpendsalary4,workexpDuties4,workexpReasonforleaving4,
            referencename1,referencerelation1,referenceyrsknown1,referencecontactinfo1,
            referencename2,referencerelation2,referenceyrsknown2,referencecontactinfo2,
            referencename3,referencerelation3,referenceyrsknown3,referencecontactinfo3,
            referencename4,referencerelation4,referenceyrsknown4,referencecontactinfo4,
            emergencycontactName,emergencycontactrelationship,emergencycontactphone,
            appSign,forofficeuseonly,interviewconductedby,interviewconducteddate

        } = this.state;
        return(
            <div className={classes.root}>
                { this.props.getPaperWorkStatus === 100 && (
                    <div className={classNames(classes.overlay, 'flex items-center w-full')}>
                        <CircularProgress className={classes.progress} color="secondary"  />
                    </div>
                )}
                <div className={classes.topheader}>
                    <span className={classes.topHeaderTxt}>TRUST HomeCare, LLC </span>
                    <span className={classes.topHeaderTxt}>Pre-EMPLOYMENT APPLICATION</span>
                    <span style={{color:"red",fontWeight:600}}>{this.props.getPaperWork !== null && this.props.getPaperWork && this.props.getPaperWork.readstatus ==="200" && this.props.getPaperWork.active !=="yes" ? "Please Fill in correctly and submit again.":""}</span>
                    <span style={{color:"red",fontWeight:600}}>{this.props.getPaperWork !== null && this.props.getPaperWork && this.props.getPaperWork.readstatus ==="100"?"Under Review":""}</span>
                    <div style={{display:"flex"}}>
                        {/*<span style={{color:"green",fontWeight: 800}}>{this.props.loginData && this.props.loginData.paperstatus ==="100" ? "Active" :""}</span>*/}
                        {/*<span style={{color:"green",fontWeight: 800}}>{this.props.loginData && this.props.loginData.paperstatus ==="100" ? <LockIcon/>:""}</span>*/}
                        <span style={{color:"green",fontWeight: 800}}>{this.props.getPaperWork && this.props.getPaperWork.active ==="yes" ? "Active" :""}</span>
                        <span style={{color:"green",fontWeight: 800}}>{this.props.getPaperWork && this.props.getPaperWork.active ==="yes" ? <LockIcon/>:""}</span>
                    </div>

                    <div>
                        <Button variant="contained" color="primary" onClick={this.viewMessage} disabled={this.props.getPaperWork && this.props.getPaperWork !== null && this.props.getPaperWork.id && this.props.getPaperWork.id !==null ? false : true}>
                            Message
                        </Button>
                    </div>

                </div>
                <div className={classes.infopaper}>
                    <Paper className={classes.paperItem}>
                        <div className={classes.paperrow}>
                            <span className={classes.paperTitletxt} style={{width:"65%",marginLeft: 10,textAlign:"left"}}>Personal Information</span>
                            <TextField
                                id="date"
                                label="Date of Application"
                                type="date"
                                variant="outlined"
                                margin="dense"
                                value={appDate}
                                style={{width:"30%"}}
                                onChange={(e)=>this.handleChangeFormType(e,"appDate")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </div>
                        <div className={classes.paperrow}>
                            <TextField
                                label="First Name"
                                value={userFirstName}
                                variant="outlined"
                                margin="dense"
                                style={{width:"23%"}}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"userFirstName")}
                            />

                            <TextField
                                label="Middle initial"
                                value={userMiddleName}
                                variant="outlined"
                                margin="dense"
                                style={{width:"23%"}}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"userMiddleName")}
                            />

                            <TextField
                                label="Last Name"
                                value={userLastName}
                                variant="outlined"
                                margin="dense"
                                style={{width:"23%"}}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"userLastName")}
                            />
                            <TextField
                                label="Maiden Name"
                                value={userMaidenName}
                                variant="outlined"
                                margin="dense"
                                style={{width:"23%"}}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"userMaidenName")}
                            />
                        </div>
                        <div className={classes.paperrow}>
                            <TextField
                                label="Social Security No."
                                value={userSSN}
                                variant="outlined"
                                margin="dense"
                                style={{width:"33%"}}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"userSSN")}
                            />
                            <TextField
                                id="date"
                                label="Date of Birth"
                                type="date"
                                variant="outlined"
                                margin="dense"
                                style={{width:"33%"}}
                                value={userBirth}
                                onChange={(e)=>this.handleChangeFormType(e,"userBirth")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                label="Social Security No."
                                value={userdriverLicense}
                                variant="outlined"
                                margin="dense"
                                style={{width:"33%"}}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"userdriverLicense")}
                            />
                        </div>
                        <div className={classes.paperrow}>
                            <TextField
                                label="Mailing Address"
                                value={userAddr}
                                variant="outlined"
                                margin="dense"
                                style={{width:"40%"}}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"userAddr")}
                            />
                            <TextField
                                label="City"
                                value={userCity}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%"}}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"userCity")}
                            />
                            <TextField
                                label="State"
                                value={userState}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%"}}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"userState")}
                            />
                            <TextField
                                label="Zip Code"
                                value={zipcode}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%"}}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"zipcode")}
                            />
                        </div>
                        <div className={classes.paperrow}>
                            <TextField
                                label="Cell Phone"
                                value={cellPhone}
                                variant="outlined"
                                margin="dense"
                                style={{width:"33%"}}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"cellPhone")}
                            />
                            <TextField
                                label="Alternate Phone"
                                value={alternatePhone}
                                variant="outlined"
                                margin="dense"
                                style={{width:"33%"}}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"alternatePhone")}
                            />
                            <TextField
                                label="Email"
                                value={email}
                                variant="outlined"
                                margin="dense"
                                style={{width:"33%"}}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"email")}
                            />
                        </div>
                        <div className={classes.paperrow}>
                            <div className={classes.papermincell} style={{width:"33%"}}>
                                <span style={{fontWeight:600,width:"100%"}}>Are you 18 and older?</span>
                                <div>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Radio
                                                checked={older==="yes"}
                                                color="primary"
                                                value="yes"
                                                onChange={(e)=>this.handleCheckRadio(e,'older')}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            control={<Radio
                                                checked={older ==="no"}
                                                color="primary"
                                                value="no"
                                                onChange={(e)=>this.handleCheckRadio(e,'older')}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />}
                                            label="No"
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className={classes.papermincell} style={{width:"33%"}}>
                                <span style={{fontWeight:600,width:"100%"}}>Are you a U.S. Citizen?</span>
                                <div>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Radio
                                                checked={uscitizen==="yes"}
                                                color="primary"
                                                value="yes"
                                                onChange={(e)=>this.handleCheckRadio(e,'uscitizen')}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            control={<Radio
                                                checked={uscitizen ==="no"}
                                                color="primary"
                                                value="no"
                                                onChange={(e)=>this.handleCheckRadio(e,'uscitizen')}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />}
                                            label="No"
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className={classes.papermincell} style={{width:"33%"}}>
                                <span style={{fontWeight:600,width:"100%"}}>Are you legally entitled to work in the U.S.?</span>
                                <div>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Radio
                                                checked={legallyentitled==="yes"}
                                                color="primary"
                                                value="yes"
                                                onChange={(e)=>this.handleCheckRadio(e,'legallyentitled')}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            control={<Radio
                                                checked={legallyentitled ==="no"}
                                                color="primary"
                                                value="no"
                                                onChange={(e)=>this.handleCheckRadio(e,'legallyentitled')}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />}
                                            label="No"
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </Paper>
                </div>
                <div className={classes.infopaper}>
                    <Paper className={classes.paperItem}>
                        <div className={classes.paperrow}>
                            <span className={classes.paperTitletxt} style={{width:"60%",marginLeft: 10,textAlign:"left"}}>Employment Desired</span>
                            <TextField
                                label="Applying for:"
                                value={applyingfor}
                                variant="outlined"
                                margin="dense"
                                style={{width:"39%"}}
                                multiline
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"applyingfor")}
                            />
                        </div>
                        <div className={classes.paperrow}>
                            <div className={classes.papermincell} style={{width:"30%"}}>
                                <span style={{fontWeight:600,width:"100%"}}>Have you previously worked, or applied,to TRUST HomeCare before?</span>
                                <div>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Radio
                                                checked={trushhomecarebefore==="yes"}
                                                color="primary"
                                                value="yes"
                                                onChange={(e)=>this.handleCheckRadio(e,'trushhomecarebefore')}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            control={<Radio
                                                checked={trushhomecarebefore ==="no"}
                                                color="primary"
                                                value="no"
                                                onChange={(e)=>this.handleCheckRadio(e,'trushhomecarebefore')}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />}
                                            label="No"
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className={classes.papermincell} style={{width:"20%"}}>
                                <TextField
                                    label="If yes,when?"
                                    value={trushhomecarewhen}
                                    variant="outlined"
                                    margin="dense"
                                    style={{width:"100%"}}
                                    multiline
                                    disabled={trushhomecarebefore ==="no"}
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"trushhomecarewhen")}
                                />
                            </div>
                            <div className={classes.papermincell} style={{width:"25%"}}>
                                <span style={{fontWeight:600,width:"100%"}}>Do you know anyone at TRUST HomeCare?</span>
                                <div>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Radio
                                                checked={trushhomecareknowanyone==="yes"}
                                                color="primary"
                                                value="yes"
                                                onChange={(e)=>this.handleCheckRadio(e,'trushhomecareknowanyone')}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />}
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            control={<Radio
                                                checked={trushhomecareknowanyone ==="no"}
                                                color="primary"
                                                value="no"
                                                onChange={(e)=>this.handleCheckRadio(e,'trushhomecareknowanyone')}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />}
                                            label="No"
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className={classes.papermincell} style={{width:"20%"}}>
                                <TextField
                                    label="If yes,whom?"
                                    value={trushhomecareknowanyonewhom}
                                    variant="outlined"
                                    margin="dense"
                                    style={{width:"100%"}}
                                    multiline
                                    disabled={trushhomecareknowanyone==="no"}
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"trushhomecareknowanyonewhom")}
                                />
                            </div>
                        </div>
                        <div className={classes.paperrow}>
                            <div className={classes.papermincell} style={{width:"60%"}}>
                                <TextField
                                    label="How did you hear about TRUST HomeCare?"
                                    value={trushhomecarehearabout}
                                    variant="outlined"
                                    margin="dense"
                                    style={{width:"100%"}}
                                    multiline
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"trushhomecarehearabout")}
                                />
                            </div>
                            <div className={classes.papermincell} style={{width:"39%"}}>
                                <TextField
                                    label="Referred by:"
                                    value={referedby}
                                    variant="outlined"
                                    margin="dense"
                                    style={{width:"100%"}}
                                    multiline
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"referedby")}
                                />
                            </div>
                        </div>
                    </Paper>
                </div>
                <div className={classes.infopaper}>
                    <Paper className={classes.paperItem}>
                        <div className={classes.paperrow}>
                            <span className={classes.paperTitletxt} style={{width:"60%",marginLeft: 10,textAlign:"left",marginTop:10}}>Education and Training</span>
                        </div>
                        <div className={classes.paperrow} style={{border:"solid 1px gray",fontWeight:800}}>
                            <div className={classes.papermincell} style={{width:"15%"}}>

                            </div>
                            <div className={classes.papermincell} style={{width:"25%",border:"solid 1px gray"}}>
                                <span>Institution Name & Location</span>
                            </div>
                            <div className={classes.papermincell} style={{width:"20%",border:"solid 1px gray"}}>
                                <span>Dates Attended</span>
                            </div>
                            <div className={classes.papermincell} style={{width:"20%",border:"solid 1px gray"}}>
                                <span>Degree Received</span>
                            </div>
                            <div className={classes.papermincell} style={{width:"20%",border:"solid 1px gray"}}>
                                <span>Graduate?</span>
                            </div>
                        </div>
                        <div className={classes.paperrow} style={{border:"solid 1px gray"}}>
                            <div className={classes.papermincell} style={{width:"15%",height:110,border:"solid 1px gray",justifyContent:"center"}}>
                                <span>High School</span>
                            </div>
                            <div className={classes.papermincell} style={{width:"25%",border:"solid 1px gray",height:110}}>
                                <TextField
                                    label=""
                                    value={highschoolinstitutionname}
                                    // variant="underline"
                                    margin="dense"
                                    style={{width:"100%",marginTop:43}}
                                    multiline
                                    rows={3}
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"highschoolinstitutionname")}
                                />
                            </div>
                            <div className={classes.papermincell} style={{width:"20%",border:"solid 1px gray",height:110}}>
                                <TextField
                                    label="To:"
                                    value={highschoolinstitutionto}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",paddingTop:1}}
                                    multiline
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"highschoolinstitutionto")}
                                />
                                <TextField
                                    label="From:"
                                    value={highschoolinstitutionfrom}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",paddingTop:1}}
                                    multiline
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"highschoolinstitutionfrom")}
                                />
                            </div>
                            <div className={classes.papermincell} style={{width:"20%",border:"solid 1px gray",height:110}}>
                                <TextField
                                    label=""
                                    value={highschooldegreereceive}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",marginTop:43}}
                                    multiline
                                    rows={3}
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"highschooldegreereceive")}
                                />
                            </div>
                            <div className={classes.papermincell} style={{width:"20%",border:"solid 1px gray",height: 110,justifyContent:"center"}}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Radio
                                            checked={highschoolgraduate==="yes"}
                                            color="primary"
                                            value="yes"
                                            onChange={(e)=>this.handleCheckRadio(e,'highschoolgraduate')}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        control={<Radio
                                            checked={highschoolgraduate ==="no"}
                                            color="primary"
                                            value="no"
                                            onChange={(e)=>this.handleCheckRadio(e,'highschoolgraduate')}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="No"
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className={classes.paperrow} style={{border:"solid 1px gray"}}>
                            <div className={classes.papermincell} style={{width:"15%",height:110,border:"solid 1px gray",justifyContent:"center"}}>
                                <span>College</span>
                            </div>
                            <div className={classes.papermincell} style={{width:"25%",border:"solid 1px gray",height:110}}>
                                <TextField
                                    label=""
                                    value={collegeinstitutionname}
                                    // variant="underline"
                                    margin="dense"
                                    style={{width:"100%",marginTop:43}}
                                    multiline
                                    rows={3}
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"collegeinstitutionname")}
                                />
                            </div>
                            <div className={classes.papermincell} style={{width:"20%",border:"solid 1px gray",height:110}}>
                                <TextField
                                    label="To:"
                                    value={collegeinstitutionto}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",paddingTop:1}}
                                    multiline
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"collegeinstitutionto")}
                                />
                                <TextField
                                    label="From:"
                                    value={collegeinstitutionfrom}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",paddingTop:1}}
                                    multiline
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"collegeinstitutionfrom")}
                                />
                            </div>
                            <div className={classes.papermincell} style={{width:"20%",border:"solid 1px gray",height:110}}>
                                <TextField
                                    label=""
                                    value={collegedegreereceive}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",marginTop:43}}
                                    multiline
                                    rows={3}
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"collegedegreereceive")}
                                />
                            </div>
                            <div className={classes.papermincell} style={{width:"20%",border:"solid 1px gray",height: 110,justifyContent:"center"}}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Radio
                                            checked={collegegraduate==="yes"}
                                            color="primary"
                                            value="yes"
                                            onChange={(e)=>this.handleCheckRadio(e,'collegegraduate')}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        control={<Radio
                                            checked={collegegraduate ==="no"}
                                            color="primary"
                                            value="no"
                                            onChange={(e)=>this.handleCheckRadio(e,'collegegraduate')}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="No"
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className={classes.paperrow} style={{border:"solid 1px gray"}}>
                            <div className={classes.papermincell} style={{width:"15%",height:110,border:"solid 1px gray",justifyContent:"center"}}>
                                <span>Other</span>
                            </div>
                            <div className={classes.papermincell} style={{width:"25%",border:"solid 1px gray",height:110}}>
                                <TextField
                                    label=""
                                    value={otherinstitutionname}
                                    // variant="underline"
                                    margin="dense"
                                    style={{width:"100%",marginTop:43}}
                                    multiline
                                    rows={3}
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"otherinstitutionname")}
                                />
                            </div>
                            <div className={classes.papermincell} style={{width:"20%",border:"solid 1px gray",height:110}}>
                                <TextField
                                    label="To:"
                                    value={otherinstitutionto}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",paddingTop:1}}
                                    multiline
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"otherinstitutionto")}
                                />
                                <TextField
                                    label="From:"
                                    value={otherinstitutionfrom}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",paddingTop:1}}
                                    multiline
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"otherinstitutionfrom")}
                                />
                            </div>
                            <div className={classes.papermincell} style={{width:"20%",border:"solid 1px gray",height:110}}>
                                <TextField
                                    label=""
                                    value={otherdegreereceive}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",marginTop:43}}
                                    multiline
                                    rows={3}
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"otherdegreereceive")}
                                />
                            </div>
                            <div className={classes.papermincell} style={{width:"20%",border:"solid 1px gray",height: 110,justifyContent:"center"}}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Radio
                                            checked={othergraduate==="yes"}
                                            color="primary"
                                            value="yes"
                                            onChange={(e)=>this.handleCheckRadio(e,'othergraduate')}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        control={<Radio
                                            checked={othergraduate ==="no"}
                                            color="primary"
                                            value="no"
                                            onChange={(e)=>this.handleCheckRadio(e,'othergraduate')}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="No"
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className={classes.paperrow} style={{border:"solid 1px gray",fontWeight:800}}>
                            <div className={classes.papermincell} style={{width:"30%",border:"solid 1px gray",justifyContent:"center"}}>
                                <span>License,certification,etc</span>
                            </div>
                            <div className={classes.papermincell} style={{width:"23%",border:"solid 1px gray",justifyContent:"center"}}>
                                <span>License No.</span>
                            </div>
                            <div className={classes.papermincell} style={{width:"23%",border:"solid 1px gray",justifyContent:"center"}}>
                                <span>State Issued</span>
                            </div>
                            <div className={classes.papermincell} style={{width:"23%",border:"solid 1px gray",justifyContent:"center"}}>
                                <span>Expiration Date</span>
                            </div>
                        </div>
                        <div className={classes.paperrow} style={{border:"solid 1px gray"}}>
                            <div className={classes.papermincell} style={{width:"30%",border:"solid 1px gray",justifyContent:"center"}}>
                                <TextField
                                    label=""
                                    value={licensecertification1}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",marginTop:0}}
                                    multiline
                                    rows={1}
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"licensecertification1")}
                                />
                            </div>
                            <div className={classes.papermincell} style={{width:"23%",border:"solid 1px gray",justifyContent:"center"}}>
                                <TextField
                                    label=""
                                    value={licenseno1}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",marginTop:0}}
                                    multiline
                                    rows={1}
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"licenseno1")}
                                />
                            </div>
                            <div className={classes.papermincell} style={{width:"23%",border:"solid 1px gray",justifyContent:"center"}}>
                                <TextField
                                    label=""
                                    value={stateissued1}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",marginTop:0}}
                                    multiline
                                    rows={1}
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"stateissued1")}
                                />
                            </div>
                            <div className={classes.papermincell} style={{width:"23%",border:"solid 1px gray",justifyContent:"center"}}>
                                <TextField
                                    label=""
                                    value={expirationdate1}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",marginTop:0}}
                                    multiline
                                    rows={1}
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"expirationdate1")}
                                />
                            </div>
                        </div>
                        <div className={classes.paperrow} style={{border:"solid 1px gray"}}>
                            <div className={classes.papermincell} style={{width:"30%",border:"solid 1px gray",justifyContent:"center"}}>
                                <TextField
                                    label=""
                                    value={licensecertification2}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",marginTop:0}}
                                    multiline
                                    rows={1}
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"licensecertification2")}
                                />
                            </div>
                            <div className={classes.papermincell} style={{width:"23%",border:"solid 1px gray",justifyContent:"center"}}>
                                <TextField
                                    label=""
                                    value={licenseno2}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",marginTop:0}}
                                    multiline
                                    rows={1}
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"licenseno2")}
                                />
                            </div>
                            <div className={classes.papermincell} style={{width:"23%",border:"solid 1px gray",justifyContent:"center"}}>
                                <TextField
                                    label=""
                                    value={stateissued2}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",marginTop:0}}
                                    multiline
                                    rows={1}
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"stateissued2")}
                                />
                            </div>
                            <div className={classes.papermincell} style={{width:"23%",border:"solid 1px gray",justifyContent:"center"}}>
                                <TextField
                                    label=""
                                    value={expirationdate2}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",marginTop:0}}
                                    multiline
                                    rows={1}
                                    rowsMax={4}
                                    className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"expirationdate2")}
                                />
                            </div>
                        </div>
                    </Paper>
                </div>
                <div className={classes.infopaper}>
                    <Paper className={classes.paperItem}>
                        <div className={classes.paperrow}>
                            <span className={classes.paperTitletxt} style={{width:200,marginLeft: 10,textAlign:"left",marginTop:10}}>Work Experience</span>
                            <span style={{fontWeight: 800}}>(please list previous employers of the last 5 years starting most recent or present employer)</span>
                        </div>
                        <div className={classes.paperrow}>
                            <TextField
                                label="1.Employer"
                                value={employer1}
                                variant="outlined"
                                margin="dense"
                                style={{width:"30%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"employer1")}
                            />
                            <TextField
                                label="Start Date"
                                value={startDate1}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"startDate1")}
                            />
                            <TextField
                                label="End Date"
                                value={endDate1}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"endDate1")}
                            />
                            <div className={classes.papermincell} style={{width:"30%",justifyContent:"center"}}>
                                <span>Currently Employed?</span>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Radio
                                            checked={currentlyemployer1==="yes"}
                                            color="primary"
                                            value="yes"
                                            onChange={(e)=>this.handleCheckRadio(e,'currentlyemployer1')}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        control={<Radio
                                            checked={currentlyemployer1 ==="no"}
                                            color="primary"
                                            value="no"
                                            onChange={(e)=>this.handleCheckRadio(e,'currentlyemployer1')}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="No"
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className={classes.paperrow}>
                            <TextField
                                label="Phone"
                                value={workexpphone1}
                                variant="outlined"
                                margin="dense"
                                style={{width:"30%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpphone1")}
                            />
                            <TextField
                                label="Job Title"
                                value={workexpjobtitle1}
                                variant="outlined"
                                margin="dense"
                                style={{width:"30%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpjobtitle1")}
                            />
                            <TextField
                                label="Starting Salary"
                                value={workexpstartingsalary1}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpstartingsalary1")}
                            />
                            <TextField
                                label="End Salary"
                                value={workexpendsalary1}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpendsalary1")}
                            />
                        </div>
                        <div className={classes.paperrow}>
                            <TextField
                                label="Duties"
                                value={workexpDuties1}
                                variant="outlined"
                                margin="dense"
                                style={{width:"59%",marginTop:10}}
                                multiline
                                rows={2}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpDuties1")}
                            />
                            <TextField
                                label="Reason for Leaving"
                                value={workexpReasonforleaving1}
                                variant="outlined"
                                margin="dense"
                                style={{width:"39%",marginTop:10}}
                                multiline
                                rows={2}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpReasonforleaving1")}
                            />
                        </div>
                        <div className={classes.paperrow}>
                            <TextField
                                label="2.Employer"
                                value={employer2}
                                variant="outlined"
                                margin="dense"
                                style={{width:"30%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"employer2")}
                            />
                            <TextField
                                label="Start Date"
                                value={startDate2}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"startDate2")}
                            />
                            <TextField
                                label="End Date"
                                value={endDate2}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"endDate2")}
                            />
                            <div className={classes.papermincell} style={{width:"30%",justifyContent:"center"}}>
                                <span>Currently Employed?</span>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Radio
                                            checked={currentlyemployer2==="yes"}
                                            color="primary"
                                            value="yes"
                                            onChange={(e)=>this.handleCheckRadio(e,'currentlyemployer2')}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        control={<Radio
                                            checked={currentlyemployer2 ==="no"}
                                            color="primary"
                                            value="no"
                                            onChange={(e)=>this.handleCheckRadio(e,'currentlyemployer2')}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="No"
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className={classes.paperrow}>
                            <TextField
                                label="Phone"
                                value={workexpphone2}
                                variant="outlined"
                                margin="dense"
                                style={{width:"30%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpphone2")}
                            />
                            <TextField
                                label="Job Title"
                                value={workexpjobtitle2}
                                variant="outlined"
                                margin="dense"
                                style={{width:"30%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpjobtitle2")}
                            />
                            <TextField
                                label="Starting Salary"
                                value={workexpstartingsalary2}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpstartingsalary2")}
                            />
                            <TextField
                                label="End Salary"
                                value={workexpendsalary2}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpendsalary2")}
                            />
                        </div>
                        <div className={classes.paperrow}>
                            <TextField
                                label="Duties"
                                value={workexpDuties2}
                                variant="outlined"
                                margin="dense"
                                style={{width:"59%",marginTop:10}}
                                multiline
                                rows={2}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpDuties2")}
                            />
                            <TextField
                                label="Reason for Leaving"
                                value={workexpReasonforleaving2}
                                variant="outlined"
                                margin="dense"
                                style={{width:"39%",marginTop:10}}
                                multiline
                                rows={2}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpReasonforleaving2")}
                            />
                        </div>
                        <div className={classes.paperrow}>
                            <TextField
                                label="3.Employer"
                                value={employer3}
                                variant="outlined"
                                margin="dense"
                                style={{width:"30%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"employer3")}
                            />
                            <TextField
                                label="Start Date"
                                value={startDate3}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"startDate3")}
                            />
                            <TextField
                                label="End Date"
                                value={endDate3}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"endDate3")}
                            />
                            <div className={classes.papermincell} style={{width:"30%",justifyContent:"center"}}>
                                <span>Currently Employed?</span>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Radio
                                            checked={currentlyemployer3==="yes"}
                                            color="primary"
                                            value="yes"
                                            onChange={(e)=>this.handleCheckRadio(e,'currentlyemployer3')}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        control={<Radio
                                            checked={currentlyemployer3 ==="no"}
                                            color="primary"
                                            value="no"
                                            onChange={(e)=>this.handleCheckRadio(e,'currentlyemployer3')}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="No"
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className={classes.paperrow}>
                            <TextField
                                label="Phone"
                                value={workexpphone3}
                                variant="outlined"
                                margin="dense"
                                style={{width:"30%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpphone3")}
                            />
                            <TextField
                                label="Job Title"
                                value={workexpjobtitle3}
                                variant="outlined"
                                margin="dense"
                                style={{width:"30%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpjobtitle3")}
                            />
                            <TextField
                                label="Starting Salary"
                                value={workexpstartingsalary3}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpstartingsalary3")}
                            />
                            <TextField
                                label="End Salary"
                                value={workexpendsalary3}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpendsalary3")}
                            />
                        </div>
                        <div className={classes.paperrow}>
                            <TextField
                                label="Duties"
                                value={workexpDuties3}
                                variant="outlined"
                                margin="dense"
                                style={{width:"59%",marginTop:10}}
                                multiline
                                rows={2}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpDuties3")}
                            />
                            <TextField
                                label="Reason for Leaving"
                                value={workexpReasonforleaving3}
                                variant="outlined"
                                margin="dense"
                                style={{width:"39%",marginTop:10}}
                                multiline
                                rows={2}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpReasonforleaving3")}
                            />
                        </div>



                        <div className={classes.paperrow}>
                            <TextField
                                label="4.Employer"
                                value={employer4}
                                variant="outlined"
                                margin="dense"
                                style={{width:"30%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"employer4")}
                            />
                            <TextField
                                label="Start Date"
                                value={startDate4}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"startDate4")}
                            />
                            <TextField
                                label="End Date"
                                value={endDate4}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"endDate4")}
                            />
                            <div className={classes.papermincell} style={{width:"30%",justifyContent:"center"}}>
                                <span>Currently Employed?</span>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Radio
                                            checked={currentlyemployer4==="yes"}
                                            color="primary"
                                            value="yes"
                                            onChange={(e)=>this.handleCheckRadio(e,'currentlyemployer4')}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        control={<Radio
                                            checked={currentlyemployer4 ==="no"}
                                            color="primary"
                                            value="no"
                                            onChange={(e)=>this.handleCheckRadio(e,'currentlyemployer4')}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="No"
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className={classes.paperrow}>
                            <TextField
                                label="Phone"
                                value={workexpphone4}
                                variant="outlined"
                                margin="dense"
                                style={{width:"30%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpphone4")}
                            />
                            <TextField
                                label="Job Title"
                                value={workexpjobtitle4}
                                variant="outlined"
                                margin="dense"
                                style={{width:"30%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpjobtitle4")}
                            />
                            <TextField
                                label="Starting Salary"
                                value={workexpstartingsalary4}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpstartingsalary4")}
                            />
                            <TextField
                                label="End Salary"
                                value={workexpendsalary4}
                                variant="outlined"
                                margin="dense"
                                style={{width:"19%",marginTop:10}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpendsalary4")}
                            />
                        </div>
                        <div className={classes.paperrow}>
                            <TextField
                                label="Duties"
                                value={workexpDuties4}
                                variant="outlined"
                                margin="dense"
                                style={{width:"59%",marginTop:10}}
                                multiline
                                rows={2}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpDuties4")}
                            />
                            <TextField
                                label="Reason for Leaving"
                                value={workexpReasonforleaving4}
                                variant="outlined"
                                margin="dense"
                                style={{width:"39%",marginTop:10}}
                                multiline
                                rows={2}
                                rowsMax={4}
                                className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"workexpReasonforleaving4")}
                            />
                        </div>
                    </Paper>
                </div>
                <div className={classes.infopaper}>
                    <Paper className={classes.paperItem}>
                        <div className={classes.paperrow}>
                            <span className={classes.paperTitletxt} style={{width:200,marginLeft: 10,textAlign:"left",marginTop:10}}>Reference</span>
                        </div>
                        <table style={{borderCollapse: 'collapse'}}>
                            <tbody>
                            <tr className={classes.paperrow} style={{border:"solid 1px gray",borderCollapse: 'collapse',textAlign:"center"}}>
                                <td style={{width:"30%",border:"solid 1px gray"}}>Name</td>
                                <td style={{width:"20%",border:"solid 1px gray"}}>Relation</td>
                                <td style={{width:"15%",border:"solid 1px gray"}}>Yrs.Known</td>
                                <td style={{width:"35%",border:"solid 1px gray"}}>Contact Information(phone,email,etc.)</td>
                            </tr>
                            <tr className={classes.paperrow} style={{border:"solid 1px gray",borderCollapse: 'collapse',textAlign:"center"}}>
                                <td style={{width:"30%",border:"solid 1px gray",flexDirection:"row",display:"flex"}}>
                                    <span style={{marginLeft: 10}}>1.</span>
                                    <TextField
                                        // label="1."
                                        value={referencename1}
                                        // variant="outlined"
                                        margin="dense"
                                        style={{width:"100%",marginTop:0}}
                                        multiline
                                        rows={1}
                                        rowsMax={4}
                                        // className={classes.formTypeSelect}
                                        onChange={(e)=>this.handleChangeFormType(e,"referencename1")}
                                    />
                                </td>
                                <td style={{width:"20%",border:"solid 1px gray"}}>
                                    <TextField
                                        label=""
                                        value={referencerelation1}
                                        // variant="outlined"
                                        margin="dense"
                                        style={{width:"100%",marginTop:0}}
                                        multiline
                                        rows={1}
                                        rowsMax={4}
                                        // className={classes.formTypeSelect}
                                        onChange={(e)=>this.handleChangeFormType(e,"referencerelation1")}
                                    />
                                </td>
                                <td style={{width:"15%",border:"solid 1px gray"}}>
                                    <TextField
                                        label=""
                                        value={referenceyrsknown1}
                                        // variant="outlined"
                                        margin="dense"
                                        style={{width:"100%",marginTop:0}}
                                        multiline
                                        rows={1}
                                        rowsMax={4}
                                        // className={classes.formTypeSelect}
                                        onChange={(e)=>this.handleChangeFormType(e,"referenceyrsknown1")}
                                    /></td>
                                <td style={{width:"35%",border:"solid 1px gray"}}>
                                    <TextField
                                        label=""
                                        value={referencecontactinfo1}
                                        // variant="outlined"
                                        margin="dense"
                                        style={{width:"100%",marginTop:0}}
                                        multiline
                                        rows={1}
                                        rowsMax={4}
                                        // className={classes.formTypeSelect}
                                        onChange={(e)=>this.handleChangeFormType(e,"referencecontactinfo1")}
                                    />
                                </td>
                            </tr>
                            <tr className={classes.paperrow} style={{border:"solid 1px gray",borderCollapse: 'collapse',textAlign:"center"}}>
                                <td style={{width:"30%",border:"solid 1px gray",flexDirection:"row",display:"flex"}}>
                                    <span style={{marginLeft: 10}}>2.</span>
                                    <TextField
                                        // label="1."
                                        value={referencename2}
                                        // variant="outlined"
                                        margin="dense"
                                        style={{width:"100%",marginTop:0}}
                                        multiline
                                        rows={1}
                                        rowsMax={4}
                                        // className={classes.formTypeSelect}
                                        onChange={(e)=>this.handleChangeFormType(e,"referencename2")}
                                    />
                                </td>
                                <td style={{width:"20%",border:"solid 1px gray"}}>
                                    <TextField
                                        label=""
                                        value={referencerelation2}
                                        // variant="outlined"
                                        margin="dense"
                                        style={{width:"100%",marginTop:0}}
                                        multiline
                                        rows={1}
                                        rowsMax={4}
                                        // className={classes.formTypeSelect}
                                        onChange={(e)=>this.handleChangeFormType(e,"referencerelation2")}
                                    />
                                </td>
                                <td style={{width:"15%",border:"solid 1px gray"}}>
                                    <TextField
                                        label=""
                                        value={referenceyrsknown2}
                                        // variant="outlined"
                                        margin="dense"
                                        style={{width:"100%",marginTop:0}}
                                        multiline
                                        rows={1}
                                        rowsMax={4}
                                        // className={classes.formTypeSelect}
                                        onChange={(e)=>this.handleChangeFormType(e,"referenceyrsknown2")}
                                    /></td>
                                <td style={{width:"35%",border:"solid 1px gray"}}>
                                    <TextField
                                        label=""
                                        value={referencecontactinfo2}
                                        // variant="outlined"
                                        margin="dense"
                                        style={{width:"100%",marginTop:0}}
                                        multiline
                                        rows={1}
                                        rowsMax={4}
                                        // className={classes.formTypeSelect}
                                        onChange={(e)=>this.handleChangeFormType(e,"referencecontactinfo2")}
                                    />
                                </td>
                            </tr>
                            <tr className={classes.paperrow} style={{border:"solid 1px gray",borderCollapse: 'collapse',textAlign:"center"}}>
                                <td style={{width:"30%",border:"solid 1px gray",flexDirection:"row",display:"flex"}}>
                                    <span style={{marginLeft: 10}}>3.</span>
                                    <TextField
                                        // label="1."
                                        value={referencename3}
                                        // variant="outlined"
                                        margin="dense"
                                        style={{width:"100%",marginTop:0}}
                                        multiline
                                        rows={1}
                                        rowsMax={4}
                                        // className={classes.formTypeSelect}
                                        onChange={(e)=>this.handleChangeFormType(e,"referencename3")}
                                    />
                                </td>
                                <td style={{width:"20%",border:"solid 1px gray"}}>
                                    <TextField
                                        label=""
                                        value={referencerelation3}
                                        // variant="outlined"
                                        margin="dense"
                                        style={{width:"100%",marginTop:0}}
                                        multiline
                                        rows={1}
                                        rowsMax={4}
                                        // className={classes.formTypeSelect}
                                        onChange={(e)=>this.handleChangeFormType(e,"referencerelation3")}
                                    />
                                </td>
                                <td style={{width:"15%",border:"solid 1px gray"}}>
                                    <TextField
                                        label=""
                                        value={referenceyrsknown3}
                                        // variant="outlined"
                                        margin="dense"
                                        style={{width:"100%",marginTop:0}}
                                        multiline
                                        rows={1}
                                        rowsMax={4}
                                        // className={classes.formTypeSelect}
                                        onChange={(e)=>this.handleChangeFormType(e,"referenceyrsknown3")}
                                    /></td>
                                <td style={{width:"35%",border:"solid 1px gray"}}>
                                    <TextField
                                        label=""
                                        value={referencecontactinfo3}
                                        // variant="outlined"
                                        margin="dense"
                                        style={{width:"100%",marginTop:0}}
                                        multiline
                                        rows={1}
                                        rowsMax={4}
                                        // className={classes.formTypeSelect}
                                        onChange={(e)=>this.handleChangeFormType(e,"referencecontactinfo3")}
                                    />
                                </td>
                            </tr>
                            <tr className={classes.paperrow} style={{border:"solid 1px gray",borderCollapse: 'collapse',textAlign:"center"}}>
                                <td style={{width:"30%",border:"solid 1px gray",flexDirection:"row",display:"flex"}}>
                                    <span style={{marginLeft: 10}}>4.</span>
                                    <TextField
                                        // label="1."
                                        value={referencename4}
                                        // variant="outlined"
                                        margin="dense"
                                        style={{width:"100%",marginTop:0}}
                                        multiline
                                        rows={1}
                                        rowsMax={4}
                                        // className={classes.formTypeSelect}
                                        onChange={(e)=>this.handleChangeFormType(e,"referencename4")}
                                    />
                                </td>
                                <td style={{width:"20%",border:"solid 1px gray"}}>
                                    <TextField
                                        label=""
                                        value={referencerelation4}
                                        // variant="outlined"
                                        margin="dense"
                                        style={{width:"100%",marginTop:0}}
                                        multiline
                                        rows={1}
                                        rowsMax={4}
                                        // className={classes.formTypeSelect}
                                        onChange={(e)=>this.handleChangeFormType(e,"referencerelation4")}
                                    />
                                </td>
                                <td style={{width:"15%",border:"solid 1px gray"}}>
                                    <TextField
                                        label=""
                                        value={referenceyrsknown4}
                                        // variant="outlined"
                                        margin="dense"
                                        style={{width:"100%",marginTop:0}}
                                        multiline
                                        rows={1}
                                        rowsMax={4}
                                        // className={classes.formTypeSelect}
                                        onChange={(e)=>this.handleChangeFormType(e,"referenceyrsknown4")}
                                    /></td>
                                <td style={{width:"35%",border:"solid 1px gray"}}>
                                    <TextField
                                        label=""
                                        value={referencecontactinfo4}
                                        // variant="outlined"
                                        margin="dense"
                                        style={{width:"100%",marginTop:0}}
                                        multiline
                                        rows={1}
                                        rowsMax={4}
                                        // className={classes.formTypeSelect}
                                        onChange={(e)=>this.handleChangeFormType(e,"referencecontactinfo4")}
                                    />
                                </td>
                            </tr>
                            </tbody>

                        </table>
                    </Paper>
                </div>
                <div className={classes.infopaper}>
                    <Paper className={classes.paperItem}>
                        <div className={classes.paperrow}>
                            <span className={classes.paperTitletxt} style={{width:"90%",marginLeft: 10,textAlign:"left",marginTop:10}}>Emergency Contact Information</span>
                        </div>
                        <div className={classes.paperrow}>
                            <TextField
                                label="Name"
                                value={emergencycontactName}
                                variant="outlined"
                                margin="dense"
                                style={{width:"33%",marginTop:0}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                // className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"emergencycontactName")}
                            />
                            <TextField
                                label="Relationship"
                                value={emergencycontactrelationship}
                                variant="outlined"
                                margin="dense"
                                style={{width:"33%",marginTop:0}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                // className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"emergencycontactrelationship")}
                            />
                            <TextField
                                label="Phone Number"
                                value={emergencycontactphone}
                                variant="outlined"
                                margin="dense"
                                style={{width:"33%",marginTop:0}}
                                multiline
                                rows={1}
                                rowsMax={4}
                                // className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"emergencycontactphone")}
                            />
                        </div>

                    </Paper>
                </div>
                <div className={classes.infopaper}>
                    <Paper className={classes.paperItem}>
                        <div className={classes.paperrow}>
                            <span className={classes.paperTitletxt} style={{width:"90%",marginLeft: 10,textAlign:"left",marginTop:10}}>Acknowledgements</span>
                        </div>
                        <div className={classes.paperrow}>
                            <span style={{fontWeight:600}}>
                                I hereby certify that all information given herein is true and complete to the best of my knowledge. In the event of employment, I
                                understand that false or misleading information given in my application or interview may result in termination.
                            </span>

                        </div>
                        <div className={classes.paperrow}>
                            <div className={classes.papermincell} style={{width:"80%",height:200,border:"solid 1px black",justifyContent:"center",alignSelf:"center",marginLeft:"10%",marginTop:20}}>
                                <SignaturePad canvasProps={{className: classes.sigPad}}
                                              ref={(ref) => { this.sigPad = ref }} />
                            </div>
                        </div>
                        <div className={classes.paperrow} style={{justifyContent:"center",flexDirection:"column"}}>

                        </div>
                        <div className={classes.paperrow} style={{justifyContent:"center",flexDirection:"row"}}>

                            <Button variant="contained" color="primary" onClick={this.userSignTrim} style={{marginTop: 10}}>
                                OK
                            </Button>
                            <Button variant="contained" onClick={this.userSignTrimClear} style={{marginTop: 10,marginLeft:20}}>
                                Clear
                            </Button>
                        </div>
                        <div className={classes.paperrow} style={{justifyContent:"center",flexDirection:"column",marginTop: 10}}>
                            <span style={{fontSize:16,fontWeight:800}}>Applicant Signature</span>
                            {appSign ? (
                                <img className={classes.sigImage}
                                     src={appSign} />
                            ):(<div/>)}
                        </div>
                        <div style={{marginTop: 30,width:1,height:1}}></div>
                    </Paper>
                </div>
                <div className={classes.infopaper}>
                    <Paper className={classes.paperItem}>
                        <div className={classes.paperrow}>
                            <span className={classes.paperTitletxt} style={{width:"90%",marginLeft: 0,textAlign:"center",marginTop:10}}>FOR OFFICE USE ONLY</span>
                        </div>
                        <div className={classes.paperrow}>
                            <span style={{color:"blue",fontWeight: 600}}>
                                Note: Availability, Cert(s), Nights, Overnights, Weekends, Days, ADLs, Drug/Alc, Home Location, Car, DL, Exp, 50lbs/Physically Able, Record/Arrest, Pay
                            </span>
                        </div>
                        <div className={classes.paperrow}>
                            <TextField
                                label=""
                                value={forofficeuseonly}
                                variant="outlined"
                                margin="dense"
                                style={{width:"100%",marginTop:10,marginLeft:0}}
                                multiline
                                rows={4}
                                rowsMax={5}
                                // className={classes.formTypeSelect}
                                onChange={(e)=>this.handleChangeFormType(e,"forofficeuseonly")}
                            />
                        </div>
                        <div className={classes.paperrow}>
                            <div className={classes.papermincell} style={{width:250}}>
                                <span>Interview Conducted by:</span>
                            </div>
                            <div className={classes.papermincell} style={{width:"50%"}}>
                                <TextField
                                    label=""
                                    value={interviewconductedby}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",marginTop:10,marginLeft:0}}
                                    multiline
                                    rows={1}
                                    rowsMax={2}
                                    // className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"interviewconductedby")}
                                />
                            </div>
                            <div className={classes.papermincell} style={{width:50}}>
                                <span>Date</span>
                            </div>
                            <div className={classes.papermincell} style={{width:"30%"}}>
                                <TextField
                                    label=""
                                    value={interviewconducteddate}
                                    // variant="outlined"
                                    margin="dense"
                                    style={{width:"100%",marginTop:10,marginLeft:0}}
                                    multiline
                                    rows={1}
                                    rowsMax={2}
                                    // className={classes.formTypeSelect}
                                    onChange={(e)=>this.handleChangeFormType(e,"interviewconducteddate")}
                                />
                            </div>
                        </div>

                    </Paper>
                </div>
                <Button variant="contained" color="primary" disabled={this.props.loginData.paperstatus ==="" ? false : true} onClick={this.submitMyPaper} style={{marginTop: 30, width:"90%", marginLeft:"5%",}}>
                    Submit
                </Button>
                <div style={{marginTop: 150,height:1,width:"100%"}}></div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        SubmitPaperWork:Actions.SubmitPaperWork,
        GetPaperWork:Actions.GetPaperWork,
    }, dispatch);
}

function mapStateToProps({auth,}) {
    return {
        auth: auth,
        loading: auth.loading,
        paperWorkStatus: auth.paperWorkStatus,
        createuserStatus: auth.createuserStatus,
        loginStatus: auth.loginStatus,
        loginData: auth.loginData,
        getPaperWorkStatus: auth.getPaperWorkStatus,
        getPaperWork: auth.getPaperWork,
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(PreEmploymentApp));