import React, {Component} from 'react';
import {
    Select,
    Grid,
    Checkbox,
    FormControlLabel,
    TextField,
    Button,  CircularProgress,

} from '@material-ui/core';

import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import {withRouter} from 'react-router-dom';
// for store
import connect from "react-redux/es/connect/connect";
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Draggable from 'react-draggable';
import * as Actions from '../../store/actions';
import Images from '../Images';
import Colors from '../Colors';
import Slider from "react-slick";
import HoverImage from "react-hover-image";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import _ from 'lodash';

import SignUpScreen from "./Auth/SignUpScreen";

const utf8 = require('utf8');
const styles = theme => ({

});


class Dashboard extends Component {
    constructor(props){
        super(props);
        this.state={
            loginStatus : 100,
        }
    }

    componentDidMount(){

    }

    componentDidUpdate(prevProps, prevState, snapshot){

    }
    componentWilUnmount() {

    }
    render(){
        const {classes} = this.props;

        return(
            <div tabIndex="0">
                {/*{this.state.loginStatus === 100 ? (<SignUpScreen/>):(<div/>)}*/}
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch);
}

function mapStateToProps({auth,}) {
    return {
        auth: auth,
        loginData: auth.loginData,
    }
}

export default withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(Dashboard));